import React from "react";
import {compose} from "redux";
import {Col, Row} from "react-bootstrap";
import {Field, reduxForm} from "redux-form";
import ReduxLabeledInput from "../../../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {withTranslation} from "react-i18next";
import RequiredText from "../../../../../../../components/basics/required-text/RequiredText";
import ButtonGroup from "../../../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../../../components/basics/button/Button";
import {myID} from "../../../../../../../libs/generateID";

function submit(values, dispatch, props) {
    props.onNextStepData({FarmObject: {...values, FarmID: myID()}});
}

function validate(values, props) {
    const errors = {};
    const {t} = props;
    if (!values.FarmName) {
        errors.FarmName = t("required");
    }
    if (!values.Adr) {
        errors.Adr = t("required");
    }
    return errors;
}

class AddFarmView extends React.Component {

    render() {
        const {t, handleSubmit, onCancel} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <Field
                            id="FarmName"
                            name="FarmName"
                            required
                            component={ReduxLabeledInput}
                            label={t("farmName")}
                            placeholder={t("subscriptions.addFarm.insertFarmName")}
                        />
                        <Field
                            id="Adr"
                            name="Adr"
                            required
                            component={ReduxLabeledInput}
                            label={t("adres")}
                            placeholder={t("subscriptions.addFarm.insertFarmAddress")}
                        />
                        <RequiredText/>
                    </Col>
                </Row>
                <ButtonGroup fixed renderInPortal={false}>
                    <Button buttonColor={"error"} buttonStyle={"round"} icon={<i className="fas fa-times"/>}
                            onClick={onCancel}/>
                    <Button buttonColor={"success"} buttonStyle={"round"} icon={<i className="fas fa-arrow-right"/>}/>
                </ButtonGroup>
            </form>
        );
    }
}

export default compose(
    withTranslation(),
    reduxForm({
        form: "add-farm-view",
        onSubmit: submit,
        validate
    })
)(AddFarmView);
