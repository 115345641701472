import React from "react";
import Card from "../../../../components/basics/card/Card";
import {connect} from "react-redux";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import {medicineTypes} from "../../../../constans/medicine";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import EditMedicineDictionaryComponent from "./medicine-dictionary-grid-components/EditMedicineDictionaryComponent";
import MedicineModal, {ModalName as MedicineModalName} from "../../../../components/modals-new/medicine-modal/MedicineModal";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import RemoveMedicineDictionaryComponent from "./medicine-dictionary-grid-components/RemoveMedicineDictionaryComponent";
import {withTranslation} from "react-i18next";
import DefaultMobileRow from "../../../../components/basics/table-grid/default-mobile-row/DefaultMobileRow";

export class MedicineDictionary extends React.Component {

    doseValueFormatter = value => {
        return `${value.Dose} ${value.Unit}`;
    };

    typeValueFormatter = value => {
        const {t} = this.props;
        switch (value) {
            default:
            case medicineTypes.STIMULATOR:
                return t("newSettings.dictionary.medicines.stimulator");
            case medicineTypes.FORAGE:
                return t("medicalFeed");
            case medicineTypes.VACCINE:
                return t("newSettings.dictionary.medicines.vaccine");
            case medicineTypes.DOSATRON:
                return t("dosatron"); // prawdopodobnie sie zmieni
        }
    };

    onAddClick = () => {
        this.props.show(MedicineModalName, {});
    };

    render() {
        const {medicines, t} = this.props;
        const headers = [
            {
                name: t("designation"),
                field: "WData.Name",
                _mobileHeader: true
            },
            {
                name: t("dosage"),
                field: "WData",
                valueFormatter: this.doseValueFormatter
            },
            {
                name: t("newSettings.dictionary.medicines.gracePeriod"),
                field: "WData.GracePeriod"
            },
            {
                name: t("price"),
                field: "WData.Price"
            },
            {
                name: t("newSettings.dictionary.medicines.type"),
                field: "WData.Type",
                valueFormatter: this.typeValueFormatter
            },
            {
                name: t("newSettings.dictionary.medicines.color"),
                field: "WData.Color",
                component: props => <div style={{background: props.value || "#fff", height: "100%"}}/>,
                _mobileHide: true
            },
            {
                name: "",
                component: <EditMedicineDictionaryComponent/>,
                headerClassName: "index",
                itemClassName: "index",
                _floating: true
            },
            {
                name: "",
                component: <RemoveMedicineDictionaryComponent/>,
                headerClassName: "index",
                itemClassName: "index",
                _floating: true
            }
        ];
        return (
            <Card>
                <TableGrid data={medicines} headers={headers} shouldIndex mobileRow={<DefaultMobileRow/>}/>
                <ButtonGroup fixed>
                    <Button buttonStyle={"round"} buttonColor={"success"} icon={<i className="fas fa-plus"/>}
                            onClick={this.onAddClick}/>
                </ButtonGroup>
                <MedicineModal/>
            </Card>
        );
    }

}

MedicineDictionary = connect(
    state => ({
        medicines: state.dictionary.medicine,
    }),
    dispatch => bindActionCreators({show}, dispatch)
)(MedicineDictionary);

export default withTranslation()(MedicineDictionary);
