import {DevType, FrameTypes} from "../../../../../../constans/devices";
import {updateDeviceDynamoDB} from "../../../../../../actions/devicesActions";
import {FORMAT} from "../../../../../../constans/formatTypes";
import {createDeviceAddressList} from "../../../../../../utils/DevicesUtils";
import devicesDB from "../../../../../../database/devicesDB";
import i18next from "i18next";
import {
    createDevices,
    createDevicesNotificationFailure,
    createDevicesNotificationSuccess
} from "../../../../../../api/devices/createDevices";

export function submit(values, dispatch, props) {
    const {address, interf, name, bridge, edit, device, alias} = values;

    if (!edit) {
        const devices = createDeviceAddressList(address, DevType.CLIMATE).map(adr => ({
            DevType: DevType.CLIMATE,
            Name: name,
            Address: adr,
            ParentID: bridge.DevID,
            Interface: +interf,
            Alias: alias,
            AdditionalData: {
                Number: values.Number || undefined
            }
        }));
        return createDevices(devices).then(res => {
            props.reset();
            createDevicesNotificationSuccess(res);
        }).catch(e => {
            createDevicesNotificationFailure(e);
        });
    } else {
        let deviceClone = device.clone();
        deviceClone.Address = +address;
        deviceClone.Name = name;
        deviceClone.ParentID = bridge.DevID;
        deviceClone.Interface = +interf;
        deviceClone.Alias = alias;
        deviceClone.Protocol = FrameTypes.WST;
        deviceClone.VerHard = "0.1";
        deviceClone.VerSoft = "0.1";
        deviceClone.Number = values.Number || undefined;
        deviceClone.setDtaModTime();
        dispatch(updateDeviceDynamoDB(deviceClone.prepareBeanToSave(), deviceClone.FarmID, props.user.ClientID, props.user.LocalUserID, null, () => {
            props.history.push(`/${props.farm}/settings/devices/manage`)
        }));
    }
}

export function validate(values, props) {
    const errors = {};

    if (!values.name) {
        errors.name = props.t("required");
    }
    if (!(FORMAT.DEVICE_MANY_ADDR_CL.test(`${values.address}`) && (!values.edit || FORMAT.NUMBER_DEC_OR_HEX.test(`${values.address}`)))) {
        errors.address = i18next.t("errors.addressError", {
            amount1: `${1} (0x1)`,
            amount2: `127 (0x${(15).toString(16).toUpperCase()})`,
            amount3: '14'
        });
    } else {
        const {user: {LicPackages = []}, farm} = props;
        let licPackage = LicPackages.find(item => item.FarmID === farm);
        if (licPackage) {
            let addressList = createDeviceAddressList(values.address, DevType.CAGE);
            if (addressList.length > licPackage.DevClimatesLeft) errors.address = i18next.t("errors.maxDevices", {amount: licPackage.DevClimatesLeft});
        }
    }
    if (!values.bridge) {
        errors.bridge = props.t("required");
    } else {
        if (values.address && !errors.address && values.interf) {
            let addressList = createDeviceAddressList(values.address, DevType.CLIMATE);
            let takenAddresses = [];
            for (let address of addressList) {
                let devicesWithAddress = devicesDB.getDevicesWithAddress(props.farm, address);
                devicesWithAddress = devicesWithAddress.filter(item => item.ParentID === values.bridge.DevID && item.Interface === values.interf);
                if (values.edit) {
                    devicesWithAddress = devicesWithAddress.filter(item => values.device.DevID !== item.DevID);
                }
                if (devicesWithAddress.length > 0) takenAddresses.push(address);
            }
            if (takenAddresses.length > 0) {
                errors.address = i18next.t("errors.takenAddress", {type: takenAddresses.join(", ")});
            }
        }
    }
    if (!values.interf) {
        errors.interf = props.t("required");
    }

    if (values.Number && !values.Number.match(new RegExp("^[0-9]{1,2}[A-Z]?$", "g"))) {
        errors.Number = i18next.t("errors.shouldBeInFormat", {type: "1, 11, 11A"});
    }

    return errors;
}
