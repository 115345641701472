import React from "react";
import {connect} from "react-redux";
import _ from "lodash";
import Tooltip from "../../../../../components/basics/tooltip/Tooltip";
import "./_connection-component.scss"
import moment from "moment";
import {DevType} from "../../../../../constans/devices";
import NewIOT from "../../../../../IOT/NewIOT"
import Loading from "../../../../../components/loading/LoadingComponent"
import {withTranslation} from "react-i18next";

export class ConnectionComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(this.state, nextState)) return true;
        if (!_.isEqual(this.props.value, nextProps.value)) return true;
        let oldShadow = this.props.shadows.get(this.props.value.DevID);
        let newShadow = nextProps.shadows.get(nextProps.value.DevID);
        return !_.isEqual(oldShadow, newShadow);
    }

    getTooltip() {
        const {value, shadows, t} = this.props;
        let shadow = shadows.get(value.DevID);
        if (!shadow) return t("newSettings.devices.connection.noData");
        let max = 0;
        for (let key in shadow.metadata) {
            if (shadow.metadata[key] > max)
                max = shadow.metadata[key];
        }
        return <div>{t("newSettings.devices.connection.lastAnswer")}<strong>{moment(max).format("DD.MM.YYYY HH:mm")}</strong>
        </div>;
    }

    getType() {
        const {value, shadows} = this.props;
        let shadow = shadows.get(value.DevID);
        if (!shadow) return "error";
        return "success";
    }

    removeLoading = () => {
        this.setState({
            loading: false
        })
    };

    onClick = e => {
        e.stopPropagation();
        this.setState({
            loading: true
        }, () => {
            NewIOT.startSendingDeviceState(this.props.value, this.removeLoading, this.removeLoading);
        })
    };

    render() {
        const {value, shadows} = this.props;
        if (value.DevType === DevType.GATEWAY || value.DevType === DevType.BRIDGE) return <i className="fas fa-minus"/>;
        let shadow = shadows.get(value.DevID);
        const {loading} = this.state;
        return (
            <div className="connection">
                <Loading isLoading={loading}/>
                <Tooltip tooltipContent={this.getTooltip()} type={this.getType()}>
                    <div onClick={this.onClick}>
                        {
                            shadow && <i className="fas fa-check"/>
                        }
                        {
                            !shadow && <i className="fas fa-times"/>
                        }
                    </div>
                </Tooltip>
            </div>
        );
    }

}

ConnectionComponent = connect(state => ({
    shadows: state.shadows.shadows,
}))(ConnectionComponent);

export default withTranslation()(ConnectionComponent);