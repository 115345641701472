import React from "react";
import {connect} from 'react-redux'
import EditBasics from "./EditBasics";
import EditDays from "./EditDays";
import PreviewAndSave from "./PreviewAndSave";
import NotFound from "../../../../../components/NotFound";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import withRoles from "../../../../../components/withRoles";
import {LicPackageKeys, LicPackageLevel, Roles} from "../../../../../constans/roles";
import Stepper from "../../../../../components/basics/stepper/Stepper";
import Step from "../../../../../components/basics/stepper/Step";
import {getLicenseByName} from "../../../../../selectors/roleSelector";
import {getFeedingCurves} from "../../../../../selectors/feedingSelector";
import {LicenseItemCounts} from "../../../../../constans/licenseCounts";
import {withTranslation} from "react-i18next";

class EditCurve extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            keptValues: {}
        };
    }

    onNextPage = (index, data, kept = {}) => {
        this.setState(state => ({
            data: data,
            active: index,
            keptValues: {...state.keptValues, ...kept}
        }))
    };

    onPrevPage = (index, kept = {}) => {
        this.setState(state => ({active: index, keptValues: {...state.keptValues, ...kept}}))
    };

    render() {
        const {active, data, keptValues} = this.state;
        const {match: {params: {index}}, license, t, curves} = this.props;
        let idx = _.isFinite(+index) ? +index : null;
        const curve = curves.find(c => c.SetData.Index === idx);
        const maxNumber = license === LicPackageLevel.EXTENDED ? LicenseItemCounts.FEEDING_CURVE_EXTENDED : LicenseItemCounts.FEEDING_CURVE_BASIC;
        if ((_.isNil(idx) || idx < 0 || idx >= maxNumber)) {
            return (
                <NotFound/>
            )
        }
        return (
            <React.Fragment>
                <Stepper activeStep={active + 1} onClickStep={number => this.onPrevPage(number - 1)}>
                    <>
                        <Step label={t("general")}/>
                        <Step label={t("newSettings.feeding.curves.editDays.header")}/>
                        <Step label={t("summary")}/>
                    </>
                </Stepper>
                {
                    active === 0 &&
                    <EditBasics index={idx} defaultValue={curve} keptValues={keptValues} onNextPage={this.onNextPage}/>
                }
                {
                    active === 1 &&
                    <EditDays index={idx} data={data} defaultValue={curve} keptValues={keptValues}
                              onNextPage={this.onNextPage} onPrevPage={this.onPrevPage}/>
                }
                {
                    active === 2 &&
                    <PreviewAndSave index={idx} defaultValue={curve} keptValues={keptValues} data={data}
                                    onPrevPage={this.onPrevPage}/>
                }
            </React.Fragment>
        );
    }
}

EditCurve = connect(state => ({
    farm: state.location.farm,
    license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER}),
    curves: getFeedingCurves(state)
}))(EditCurve);
export const _EditCurve = EditCurve;
EditCurve = withTranslation()(EditCurve);
EditCurve = withRouter(EditCurve);
export default withRoles({
    roles: [Roles._DEVICE_CONFIG],
    clientPackage: {[LicPackageKeys.DISPENSER]: LicPackageLevel.BASIC},
    showComponent: true
})(EditCurve);

