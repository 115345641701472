import React from "react";
import FormForSettings from "../../FormForSettings";
import {Field, reduxForm} from "redux-form";
import Card from "../../../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import ReduxLabeledInput from "../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {connect} from "react-redux";
import {submit, validate} from "./ChangeMQTTPasswordSubmit";
import InfoBox from "../../../../components/basics/info-box/InfoBox";
import {withTranslation} from "react-i18next";

export class ChangeMQTTPassword extends React.Component {

    render() {
        const {handleSubmit, t, user: {LocalMqttSecret}, error} = this.props;
        return (
            <FormForSettings handleSubmit={handleSubmit}>
                <Card>
                    <Row className="justify-content-center">
                        <Col md={6}>
                            {
                                error &&
                                <InfoBox boxColor={"error"}>{error}</InfoBox>
                            }
                            {
                                LocalMqttSecret &&
                                <Field
                                    name="passwordOld"
                                    id="passwordOld"
                                    type="password"
                                    component={ReduxLabeledInput}
                                    label={t("login.password")}
                                />
                            }
                            <Field
                                name="password"
                                id="password"
                                type="password"
                                component={ReduxLabeledInput}
                                label={t("newPassword")}
                            />
                            <Field
                                name="passwordConfirmation"
                                type="password"
                                id="passwordConfirmation"
                                component={ReduxLabeledInput}
                                label={t("newSettings.profile.changePasswordView.passwordConfirmation")}
                            />
                        </Col>
                    </Row>
                </Card>
            </FormForSettings>
        );
    }

}

ChangeMQTTPassword = reduxForm({
    form: "changeMQTTPassword",
    onSubmit: submit,
    validate
})(ChangeMQTTPassword);

ChangeMQTTPassword = connect(state => ({
    user: state.user.user,
    farm: state.location.farm
}))(ChangeMQTTPassword);

export default withTranslation()(ChangeMQTTPassword);