import {FORMAT} from "../../../../../../constans/formatTypes";
import {createDeviceAddressList} from "../../../../../../utils/DevicesUtils";
import {DevType, DevType as DevTypes, FrameTypes} from "../../../../../../constans/devices";
import devicesDB from "../../../../../../database/devicesDB";
import {updateDeviceDynamoDB} from "../../../../../../actions/devicesActions";
import i18next from "i18next";
import {
    createDevices,
    createDevicesNotificationFailure,
    createDevicesNotificationSuccess
} from "../../../../../../api/devices/createDevices";

export function submit(values, dispatch, props) {
    console.log("VALUES", arguments);
    console.log("PROPS", props);

    const {address, interf, name, bridge, edit, device, alias} = values;

    if (!edit) {
        const devices = createDeviceAddressList(address, DevTypes.ANTENNA_RFID).map(adr => ({
            DevType: DevType.ANTENNA_RFID,
            Name: name,
            Address: adr,
            ParentID: bridge.DevID,
            Interface: +interf,
            Alias: alias,
        }));
        return createDevices(devices).then(res => {
            props.reset();
            createDevicesNotificationSuccess(res);
        }).catch(e => {
            createDevicesNotificationFailure(e);
        });
    } else {
        let deviceClone = device.clone();
        deviceClone.Address = Number(address);
        deviceClone.Name = name;
        deviceClone.ParentID = bridge.DevID;
        deviceClone.Interface = +interf;
        deviceClone.Alias = alias;
        deviceClone.Protocol = FrameTypes.RFID;
        deviceClone.VerHard = "0.1";
        deviceClone.VerSoft = "0.1";
        dispatch(updateDeviceDynamoDB(deviceClone, deviceClone.FarmID, props.user.ClientID, props.user.LocalUserID, null, () => {
            props.history.push(`/${props.farm}/settings/devices/manage`)
        }));
    }
}

export function validate(values, props) {
    const errors = {};
    if (!values.name) {
        errors.name = i18next.t("required");
    }
    if (!(FORMAT.DEVICE_MANY_ADDR_ANT.test(`${values.address}`) && (!values.edit || FORMAT.NUMBER_DEC_OR_HEX.test(`${values.address}`)))) {
        errors.address = i18next.t("errors.addressError", {
            amount1: '1 (0x1)',
            amount2: `16 (0x${(16).toString(16).toUpperCase()})`,
            amount3: '2'
        });
    }
    if (!values.bridge) {
        errors.bridge = i18next.t("required");
    }
    if (!errors.address && values.address && values.interf && values.bridge) {
        let addressList = createDeviceAddressList(values.address, DevTypes.ANTENNA_RFID);
        let takenAdresses = [];
        for (let address of addressList) {
            let devicesWithAddress = devicesDB.getDevicesWithAddress(props.farm, address);
            devicesWithAddress = devicesWithAddress.filter(item => item.ParentID === values.bridge.DevID && item.Interface === values.interf);
            if (values.edit) {
                devicesWithAddress = devicesWithAddress.filter(item => values.device.DevID !== item.DevID);
            }
            if (devicesWithAddress.length > 0) takenAdresses.push(address);
        }
        if (takenAdresses.length) {
            errors.address = i18next.t("errors.takenAddress", {type: takenAdresses.join(", ")});
        }
    }
    if (!values.interf) {
        errors.interf = i18next.t("required");
    }

    return errors;
}