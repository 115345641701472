import React from "react";
import Card from "../../../../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import {Field, FormSection, reduxForm} from "redux-form";
import ReduxCheckbox from "../../../../../components/basics/checkbox/ReduxCheckbox";
import {connect} from "react-redux";
import {submit} from "./CageNotificationsSubmit";
import FormForNotifications from "../FormForNotifications";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class CageNotifications extends React.Component {

    constructor(props) {
        super(props);

        const {notifications} = this.props;

        if (notifications && notifications.SetData.Cage) {
            this.props.initialize(notifications.SetData.Cage);
        } else {
            this.props.initialize({
                Info: {
                    Manual: true,
                    Training1: true,
                    Training2: true,
                    Training3: true,
                    Training4: true,
                    Separation: true,
                    Expedition: true,
                    Expedition25: true,
                    Expedition50: true,
                    Expedition75: true,
                    Expedition100: true,
                    Separation100: true
                },
                Warning: {
                    MarkerLow: true
                },
                Error: {
                    Entrance: true,
                    Middle: true,
                    Left: true,
                    Right: true,
                    Manual: true,
                    Training1: true,
                    Training2: true,
                    Training3: true,
                    Training4: true,
                    Separation: true,
                    Expedition: true,
                }
            })
        }
    }


    render() {
        const {handleSubmit, t} = this.props;
        return (
            <FormForNotifications handleSubmit={handleSubmit}>
                <Card>
                    <FormSection name="Info">
                        <h4>
                            {t("newSettings.notifications.cage.info")}
                            <i className="fas fa-info-circle"/>
                        </h4>
                        <Row>
                            <Col lg={4}>Zmiana trybu pracy</Col>
                            <Col lg={8} className="notification-settings-right">
                                <Field
                                    name="Manual"
                                    component={ReduxCheckbox}
                                    label={t('newSettings.notifications.cage.manual')}
                                    id="Manual"
                                />
                                <Field
                                    name="Training1"
                                    component={ReduxCheckbox}
                                    label={i18next.t("newSettings.notifications.cage.training", {count: 1})}
                                    id="Training1"
                                />
                                <Field
                                    name="Training2"
                                    component={ReduxCheckbox}
                                    label={i18next.t("newSettings.notifications.cage.training", {count: 2})}
                                    id="Training2"
                                />
                                <Field
                                    name="Training3"
                                    component={ReduxCheckbox}
                                    label={i18next.t("newSettings.notifications.cage.training", {count: 3})}
                                    id="Training3"
                                />
                                <Field
                                    name="Training4"
                                    component={ReduxCheckbox}
                                    label={i18next.t("newSettings.notifications.cage.training", {count: 4})}
                                    id="Training4"
                                />
                                <Field
                                    name="Separation"
                                    component={ReduxCheckbox}
                                    label={t("newSettings.notifications.cage.separation")}
                                    id="Separation"
                                />
                                <Field
                                    name="Expedition"
                                    component={ReduxCheckbox}
                                    label={t("newSettings.notifications.cage.expedition")}
                                    id="Expedition"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.cage.expeditionPercent")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <Field
                                    name="Expedition25"
                                    component={ReduxCheckbox}
                                    label="25%"
                                    id="Expedition25"
                                />
                                <Field
                                    name="Expedition50"
                                    component={ReduxCheckbox}
                                    label="50%"
                                    id="Expedition50"
                                />
                                <Field
                                    name="Expedition75"
                                    component={ReduxCheckbox}
                                    label="75%"
                                    id="Expedition75"
                                />
                                <Field
                                    name="Expedition100"
                                    component={ReduxCheckbox}
                                    label="100%"
                                    id="Expedition100"
                                />
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col lg={4}>Separacja - wyjście na środek</Col>*/}
                        {/*    <Col lg={8} className="notification-settings-right">*/}
                        {/*        <Field*/}
                        {/*            name="Separation100"*/}
                        {/*            component={ReduxCheckbox}*/}
                        {/*            label="100%"*/}
                        {/*            id="Separation100"*/}
                        {/*        />*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </FormSection>
                </Card>
                {/*<Card>*/}
                {/*    <FormSection name="Warning">*/}
                {/*        <h4>*/}
                {/*            Ostrzeżenia*/}
                {/*            <i className="fas fa-exclamation-triangle"/>*/}
                {/*        </h4>*/}
                {/*        <Row>*/}
                {/*            <Col lg={4}>Niski poziom farby w markerze</Col>*/}
                {/*            <Col lg={8} className="notification-settings-right">*/}
                {/*                <Field*/}
                {/*                    name="MarkerLow"*/}
                {/*                    component={ReduxCheckbox}*/}
                {/*                    label=""*/}
                {/*                    id="MarkerLow"*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </FormSection>*/}
                {/*</Card>*/}
                <Card>
                    <FormSection name="Error">
                        <h4>
                            {t("newSettings.notifications.cage.errors")}
                            <i className="fas fa-exclamation-circle"/>
                        </h4>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.cage.doorErrors")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <Field
                                    name="Entrance"
                                    component={ReduxCheckbox}
                                    label={t("newSettings.notifications.cage.entrance")}
                                    id="Entrance"
                                />
                                <Field
                                    name="Middle"
                                    component={ReduxCheckbox}
                                    label={t("newSettings.notifications.cage.middle")}
                                    id="Middle"
                                />
                                <Field
                                    name="Left"
                                    component={ReduxCheckbox}
                                    label={t("left")}
                                    id="Left"
                                />
                                <Field
                                    name="Right"
                                    component={ReduxCheckbox}
                                    label={t("right")}
                                    id="Right"
                                />
                            </Col>
                        </Row><Row>
                        <Col lg={4}>{t("worktype")}</Col>
                        <Col lg={8} className="notification-settings-right">
                            <Field
                                name="Manual"
                                component={ReduxCheckbox}
                                label={t("newSettings.notifications.cage.manual")}
                                id="ManualError"
                            />
                            <Field
                                name="Training1"
                                component={ReduxCheckbox}
                                label={i18next.t("newSettings.notifications.cage.training", {count: 1})}
                                id="Training1Error"
                            />
                            <Field
                                name="Training2"
                                component={ReduxCheckbox}
                                label={i18next.t("newSettings.notifications.cage.training", {count: 2})}
                                id="Training2Error"
                            />
                            <Field
                                name="Training3"
                                component={ReduxCheckbox}
                                label={i18next.t("newSettings.notifications.cage.training", {count: 3})}
                                id="Training3Error"
                            />
                            <Field
                                name="Training4"
                                component={ReduxCheckbox}
                                label={i18next.t("newSettings.notifications.cage.training", {count: 4})}
                                id="Training4Error"
                            />
                            <Field
                                name="Separation"
                                component={ReduxCheckbox}
                                label={t("newSettings.notifications.cage.separation")}
                                id="SeparationError"
                            />
                            <Field
                                name="Expedition"
                                component={ReduxCheckbox}
                                label={t("newSettings.notifications.cage.expedition")}
                                id="ExpeditionError"
                            />
                        </Col>
                    </Row>
                    </FormSection>
                </Card>
            </FormForNotifications>
        );
    }

}

CageNotifications = reduxForm({
    form: "notification-settings",
    onSubmit: submit
})(CageNotifications);

CageNotifications = connect(state => ({
    notifications: state.settings.notifications,
    user: state.user.user,
}))(CageNotifications);

export default withTranslation()(CageNotifications);