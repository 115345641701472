import React from "react";
import FormForNotifications from "../FormForNotifications";
import {Field, FormSection, reduxForm} from "redux-form";
import {connect} from "react-redux";
import Card from "../../../../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import ReduxCheckbox from "../../../../../components/basics/checkbox/ReduxCheckbox";
import {submit} from "./FeedingNotificationsSubmit";
import {withTranslation} from "react-i18next";

export class FeedingNotifications extends React.Component {

    constructor(props) {
        super(props);

        const {notifications} = this.props;

        if (notifications && notifications.SetData.Feeding) {
            this.props.initialize(notifications.SetData.Feeding);
        } else {
            this.props.initialize({
                Info: {
                    AutoAdd: true,
                    Insemination: true
                },
                Warning: {
                    NoFeedingForHours: true,
                    AnimalWrongPlace: true
                },
                Error: {
                    AnimalNoCurve: true,
                    ScheduleNotAssigned: true,
                    ScheduleNotConfigured: true,
                    CurveNotAssigned: true,
                    CurveNotConfigured: true,
                    ForageNotConfigured: true,
                    SubaddressNoCommunication: true,
                    ManualAlarm: true,
                    RTC: true,
                    SingleDose: true,
                    TimeBetweenDoses: true,
                    Efficiency: true,
                    InitDose: true,
                    MotorErrors: true,
                    FeedingErrors: true,
                    ScheduleSetFail: true,
                    CurveSetFail: true
                }
            })
        }
    }


    render() {
        const {handleSubmit, t} = this.props;
        return (
            <FormForNotifications handleSubmit={handleSubmit}>
                <Card>
                    <FormSection name="Info">
                        <h4>
                            {t("newSettings.notifications.feeding.info")}
                            <i className="fas fa-info-circle"/>
                        </h4>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.feeding.autoAdd")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="AutoAdd"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="AutoAdd"
                                    />
                                </div>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col lg={4}>Zgłoszono poród</Col>*/}
                        {/*    <Col lg={8} className="notification-settings-right">*/}
                        {/*        <div className="notification-settings-input">*/}
                        {/*            <Field*/}
                        {/*                name="Insemination"*/}
                        {/*                component={ReduxCheckbox}*/}
                        {/*                label=""*/}
                        {/*                id="Insemination"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </FormSection>
                </Card>
                <Card>
                    <FormSection name="Warning">
                        <h4>
                            {t("newSettings.notifications.feeding.warning")}
                            <i className="fas fa-exclamation-triangle"/>
                        </h4>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.feeding.noFeeding")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="NoFeedingForHours"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="NoFeedingForHours"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.feeding.wrongPlcmnt")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="AnimalWrongPlace"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="AnimalWrongPlace"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </FormSection>
                </Card>
                <Card>
                    <FormSection name="Error">
                        <h4>
                            {t("newSettings.notifications.feeding.errors")}
                            <i className="fas fa-exclamation-circle"/>
                        </h4>
                        {/*<Row>*/}
                        {/*    <Col lg={4}>Nieskonfigurowana krzywa dla zwierzęcia</Col>*/}
                        {/*    <Col lg={8} className="notification-settings-right">*/}
                        {/*        <div className="notification-settings-input">*/}
                        {/*            <Field*/}
                        {/*                name="AnimalNoCurve"*/}
                        {/*                component={ReduxCheckbox}*/}
                        {/*                label=""*/}
                        {/*                id="AnimalNoCurve"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.feeding.scheduleNotAssigned")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="ScheduleNotAssigned"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="ScheduleNotAssigned"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.feeding.scheduleNotConfigured")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="ScheduleNotConfigured"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="ScheduleNotConfigured"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.feeding.curveNotAssigned")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="CurveNotAssigned"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="CurveNotAssigned"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.feeding.curveNotConfigured")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="CurveNotConfigured"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="CurveNotConfigured"
                                    />
                                </div>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col lg={4}>Pasza nie została skonfigurowana</Col>*/}
                        {/*    <Col lg={8} className="notification-settings-right">*/}
                        {/*        <div className="notification-settings-input">*/}
                        {/*            <Field*/}
                        {/*                name="ForageNotConfigured"*/}
                        {/*                component={ReduxCheckbox}*/}
                        {/*                label=""*/}
                        {/*                id="ForageNotConfigured"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    <Col lg={4}>Brak komunikacji z urządzeniem o subadresie X</Col>*/}
                        {/*    <Col lg={8} className="notification-settings-right">*/}
                        {/*        <div className="notification-settings-input">*/}
                        {/*            <Field*/}
                        {/*                name="SubaddressNoCommunication"*/}
                        {/*                component={ReduxCheckbox}*/}
                        {/*                label=""*/}
                        {/*                id="SubaddressNoCommunication"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.feeding.manual")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="ManualAlarm"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="ManualAlarm"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.feeding.configurationErrors")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="RTC"
                                        component={ReduxCheckbox}
                                        label={t("newSettings.notifications.feeding.rtc")}
                                        id="RTC"
                                    />
                                    <Field
                                        name="SingleDose"
                                        component={ReduxCheckbox}
                                        label={t("newSettings.notifications.feeding.singleDose")}
                                        id="SingleDose"
                                    />
                                    <Field
                                        name="TimeBetweenDoses"
                                        component={ReduxCheckbox}
                                        label={t("newSettings.notifications.feeding.timeBetweenDoses")}
                                        id="TimeBetweenDoses"
                                    />
                                    <Field
                                        name="Efficiency"
                                        component={ReduxCheckbox}
                                        label={t("newSettings.notifications.feeding.efficiency")}
                                        id="Efficiency"
                                    />
                                    <Field
                                        name="InitDose"
                                        component={ReduxCheckbox}
                                        label={t("newSettings.notifications.feeding.initDose")}
                                        id="InitDose"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("motorErrors")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="MotorErrors"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="MotorErrors"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("feedingErrors")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="FeedingErrors"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="FeedingErrors"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.feeding.setSchedule")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="ScheduleSetFail"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="ScheduleSetFail"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.feeding.setCurve")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="CurveSetFail"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="CurveSetFail"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </FormSection>
                </Card>
            </FormForNotifications>
        );
    }

}

FeedingNotifications = reduxForm({
    form: "feeding-notifications",
    onSubmit: submit
})(FeedingNotifications);

FeedingNotifications = connect(state => ({
    notifications: state.settings.notifications,
    user: state.user.user,
}))(FeedingNotifications);

export default withTranslation()(FeedingNotifications);