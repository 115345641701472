import React from "react";
import Card from "../../../../../../components/basics/card/Card";
import FormForDevices from "../FormForDevices";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {submit, validate} from "./ClimateWSTSubmit";
import {withRouter} from "react-router-dom";
import withRoles from "../../../../../../components/withRoles";
import {Roles} from "../../../../../../constans/roles";
import ReduxLabeledInput from "../../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {Col, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";

export class ClimateWST extends React.Component {

    render() {
        const {handleSubmit, address, device, t, bridge, edit} = this.props;
        return (
            <Card>
                <FormForDevices handleSubmit={handleSubmit} showInterfacePicker device={device} address={address}
                                showAdditionalButton={device} bridge={bridge} showHelp={!edit}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <Field
                                component={ReduxLabeledInput}
                                name="Number"
                                label={t("newSettings.devices.form.number")}
                                type={"text"}
                                normalize={value => value ? value.slice(0, 3).toUpperCase() : null}
                            />
                        </Col>
                    </Row>
                </FormForDevices>
            </Card>
        );
    }

}

ClimateWST = reduxForm({
    form: "climateWST",
    onSubmit: submit,
    validate
})(ClimateWST);

ClimateWST = withRoles(props => {
    return {roles: [Roles._DEVICE_CONFIG], showComponent: true}
})(ClimateWST);

const selector = formValueSelector("climateWST");
ClimateWST = connect(state => ({
    address: selector(state, "address"),
    device: selector(state, "device"),
    bridge: selector(state, "bridge"),
    edit: selector(state, "edit"),
    user: state.user.user,
    farm: state.location.farm
}))(ClimateWST);

ClimateWST = withTranslation()(ClimateWST);
export default withRouter(ClimateWST);
