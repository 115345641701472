import React from "react";
import FormForNotifications from "../FormForNotifications";
import {Field, FormSection, reduxForm} from "redux-form";
import Card from "../../../../../components/basics/card/Card";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import ReduxCheckbox from "../../../../../components/basics/checkbox/ReduxCheckbox";
import {submit} from "./SiloNotificationsSubmit";
import {withTranslation} from "react-i18next";

export class SiloNotifications extends React.Component {

    constructor(props) {
        super(props);

        const {notifications} = this.props;

        if (notifications && notifications.SetData.Silo) {
            this.props.initialize(notifications.SetData.Silo);
        } else {
            this.props.initialize({
                Info: {
                    LowForage: true
                },
                Warning: {
                    LowForage: true
                },
                Error: {
                    NoForage: true,
                    ExceededMinimum: true
                }
            })
        }
    }


    render() {
        const {handleSubmit, t} = this.props;
        return (
            <FormForNotifications handleSubmit={handleSubmit}>
                {/*<Card>*/}
                {/*    <FormSection name="Info">*/}
                {/*        <h4>*/}
                {/*            Informacje*/}
                {/*            <i className="fas fa-info-circle"/>*/}
                {/*        </h4>*/}
                {/*        <Row>*/}
                {/*            <Col lg={4}>Paszy wystarczy na X dni</Col>*/}
                {/*            <Col lg={8} className="notification-settings-right">*/}
                {/*                <div className="notification-settings-input">*/}
                {/*                    <Field*/}
                {/*                        name="LowForage"*/}
                {/*                        component={ReduxCheckbox}*/}
                {/*                        label=""*/}
                {/*                        id="LowForage"*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </FormSection>*/}
                {/*</Card>*/}
                {/*<Card>*/}
                {/*    <FormSection name="Warning">*/}
                {/*        <h4>*/}
                {/*            Ostrzeżenia*/}
                {/*            <i className="fas fa-exclamation-triangle"/>*/}
                {/*        </h4>*/}
                {/*        <Row>*/}
                {/*            <Col lg={4}>Paszy wystarczy na X dni</Col>*/}
                {/*            <Col lg={8} className="notification-settings-right">*/}
                {/*                <div className="notification-settings-input">*/}
                {/*                    <Field*/}
                {/*                        name="LowForage"*/}
                {/*                        component={ReduxCheckbox}*/}
                {/*                        label=""*/}
                {/*                        id="LowForageWarning"*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </FormSection>*/}
                {/*</Card>*/}
                <Card>
                    <FormSection name="Error">
                        <h4>
                            {t("newSettings.notifications.silo.errors")}
                            <i className="fas fa-exclamation-circle"/>
                        </h4>
                        {/*<Row>*/}
                        {/*    <Col lg={4}>Brak paszy w silosie</Col>*/}
                        {/*    <Col lg={8} className="notification-settings-right">*/}
                        {/*        <Field*/}
                        {/*            name="NoForage"*/}
                        {/*            component={ReduxCheckbox}*/}
                        {/*            label=""*/}
                        {/*            id="NoForage"*/}
                        {/*        />*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.silo.minimum")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <Field
                                    name="ExceededMinimum"
                                    component={ReduxCheckbox}
                                    label=""
                                    id="ExceededMinimum"
                                />
                            </Col>
                        </Row>
                    </FormSection>
                </Card>
            </FormForNotifications>
        );
    }

}

SiloNotifications = reduxForm({
    form: "silo-notifications",
    onSubmit: submit
})(SiloNotifications);

SiloNotifications = connect(state => ({
    notifications: state.settings.notifications,
    user: state.user.user,
}))(SiloNotifications);

export default withTranslation()(SiloNotifications);