import React from "react";
import {Field, reduxForm} from "redux-form";
import Card from "../../../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import ReduxLabeledSelect from "../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import ReduxLabeledInput from "../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import {submit, validate} from "./OpenSSHViewSubmit"
import {withTranslation} from "react-i18next";

export class OpenSSHView extends React.Component {

    render() {
        const {devices, handleSubmit, t} = this.props;
        return (
            <Card>
                <Row className="justify-content-center">
                    <Col lg={6}>
                        <form onSubmit={handleSubmit}>
                            <Field
                                name="gateway"
                                component={ReduxLabeledSelect}
                                label={t("newSettings.devices.openSSH.gateway")}
                                options={devices.filter(item => item.DevType === DevType.GATEWAY).map(gw => ({
                                    name: gw.Name,
                                    value: gw
                                }))}
                            />
                            <Field
                                name="port"
                                component={ReduxLabeledInput}
                                label={t("newSettings.devices.openSSH.port")}
                                type="number"
                                parse={value => value ? +value : null}
                            />
                            <ButtonGroup fixed renderInPortal={false}>
                                <Button buttonStyle="round" buttonColor="success"
                                        icon={<i className="fas fa-paper-plane"/>}/>
                            </ButtonGroup>
                        </form>
                    </Col>
                </Row>
            </Card>
        );
    }

}

OpenSSHView = reduxForm({
    form: "open-ssh",
    onSubmit: submit,
    validate
})(OpenSSHView);

OpenSSHView = connect(state => ({
    devices: state.farmDevices.devices,
}))(OpenSSHView);

export default withTranslation()(OpenSSHView);