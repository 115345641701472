import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import {Field, FormSection, reduxForm} from "redux-form";
import ReduxLabeledInput from "../../../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import Radio from "../../../../../../../components/basics/radio/Radio";
import {get, isNil, merge} from "lodash";
import Button from "../../../../../../../components/basics/button/Button";
import ButtonGroup from "../../../../../../../components/basics/button/button-group/ButtonGroup";
import * as BreedingModules from "validators-schema/Api/constants/breedingModules";
import {validateAddons, validateAddonsFailure} from "../../../../../../../api/subscriptions/ValidateAddons";


function mapStateToProps(state) {
    return {
        selectedPlans: state.braintree.selectedPlans,
        user: state.user.user,
    }
}

function submit(values, dispatch, props) {
    const {data} = props;
    const merged = merge(data, values);
    const {user: {ClientID}, farmExisted} = props;
    const {BreedingModule, DevicesModule, AdministrationModule, FarmObject: {FarmID}} = merged;
    if (farmExisted) {
        return validateAddons({addOns: {BreedingModule, DevicesModule, AdministrationModule}}, {FarmID, ClientID})
            .then(() => props.onNextStepData(values))
            .catch((err) => validateAddonsFailure(err))
    } else {
        props.onNextStepData(values);
    }
}

function validate(values, props) {
    const errors = {
        BreedingModule: {},
        DevicesModule: {
            siloses: {},
            climates: {},
            cages: {},
            dispensers: {},
            dispensers5G: {},
            meters: {},
            smallCages: {},
            chains: {},
            alarms: {}
        },
        AdministrationModule: {
            managersAmount: "",
            operatorsAmount: "",
            techAdviceAmount: "",
            techSupportAmount: ""
        }
    };
    const {DevicesModule, AdministrationModule} = values;
    const {t} = props;
    for (const property in errors.DevicesModule) {
        if (!get(DevicesModule, `[${property}].amount`)) {
            errors.DevicesModule[property].amount = t("required");
        }
    }

    for (const property in errors.AdministrationModule) {
        if (!get(AdministrationModule, `[${property}]`)) {
            errors.AdministrationModule[property] = t("required");
        }
    }
    return errors;
}

class PlanConfigurationStep extends React.Component {

    constructor(props) {
        super(props);
        const {onSowsSizeUpdate, selectedPlans} = this.props;
        const areSowies = (selectedPlans.length >= 1 && !!selectedPlans.find((p) => p.startsWith("sows")));
        if (areSowies) onSowsSizeUpdate("1");
    }


    onRadioChange = (val, props, e) => {
        const value = e.target.value;
        this.props.onSowsSizeUpdate(value);
    };


    getPlanType = (name) => {
        const {selectedPlans, t} = this.props;
        const plan = selectedPlans.find((plan) => plan.startsWith(name));
        const result = plan.endsWith("basic") ? t("subscriptions.plans.basic") : t("subscriptions.plans.extended");
        return result.toLowerCase();
    };

    checkIfPlanExists = (types) => {
        const {selectedPlans} = this.props;
        return selectedPlans.some((plan) => types.includes(plan));
    };

    numberValueParser = (value) => isNil(value) ? value : parseInt(value);

    render() {
        const {onCancel, handleSubmit, data: {BreedingModule: {sows: {size}}}, onBackStep, t} = this.props;
        const devicesFields = [
            {
                title: t("siloses"),
                class: "siloses"
            },
            {
                title: t("climates"),
                class: "climates"
            },
            {
                title: t("cages"),
                class: "cages"
            },
            {
                title: t("dispensers"),
                class: "dispensers"
            },
            {
                title: t("dispensers5G"),
                class: "dispensers5G"
            },
            {
                title: t("meters"),
                class: "meters"
            },
            {
                title: t("weights"),
                class: "smallCages"
            },
            {
                title: t("chains"),
                class: "chains"
            },
            {
                title: t("alarms"),
                class: "alarms"
            }
        ];
        const administrationFields = [
            {
                title: t("managersAcc"),
                class: "managersAmount"
            },
            {
                title: t("operatorsAcc"),
                class: "operatorsAmount"
            },
            {
                title: t("techAdvice"),
                class: "techAdviceAmount"
            },
            {
                title: t("techSupport"),
                class: "techSupportAmount"
            },
        ];
        return (
            <form onSubmit={handleSubmit}>
                <Row className={"plan-picker"}>
                    {
                        this.checkIfPlanExists(["sows-basic", "sows-extended", "porkers-basic", "porkers-extended"]) &&
                        <Col className={"plan-container"} md={4}>
                            <div className={"plan-title"}>
                                <h4>{t("breeding")}</h4>
                            </div>
                            <div className={"plan-addons"}>
                                <FormSection name={"BreedingModule"}>
                                    {
                                        this.checkIfPlanExists(["sows-basic", "sows-extended"]) &&
                                        <div>
                                            <div className={"addon-options"}>
                                                <label>
                                                    <b>{t("animalTypes.0")}</b>
                                                    <p>{this.getPlanType("sows")}</p>
                                                </label>
                                                <span>
                                                    <Radio checked={size === 1} onChange={this.onRadioChange}
                                                           label={t("addons.animalsRanges", {range: `${BreedingModules.AnimalRanges.minSmall}-${BreedingModules.AnimalRanges.maxSmall}`})}
                                                           name={"sows.size"} value={"1"}/>
                                                    <b>100zł</b>
                                                    </span>
                                                <span>
                                                    <Radio checked={size === 2} onChange={this.onRadioChange}
                                                           label={t("addons.animalsRanges", {range: `${BreedingModules.AnimalRanges.minMedium}-${BreedingModules.AnimalRanges.maxMedium}`})}
                                                           name={"sows.size"} value={"2"}/>
                                                    <b>200zł</b>
                                                </span>
                                                <span>
                                                    <Radio checked={size === 3} onChange={this.onRadioChange}
                                                           label={t("addons.animalsRanges", {range: `> ${BreedingModules.AnimalRanges.minLarge - 1}`})}
                                                           name={"sows.size"} value={"3"}/>
                                                    <b>300zł</b>
                                                </span>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.checkIfPlanExists(["porkers-basic", "porkers-extended"]) &&
                                        <div>
                                            <div className={"addon-options last"}>
                                                <label className={"porkers-container"}>
                                                    <div>
                                                        <b>{t("animalTypes.3")}</b>
                                                        <p>{this.getPlanType("porkers")}</p>
                                                    </div>
                                                    <i className={"fas fa-check"}/>
                                                </label>
                                            </div>
                                        </div>
                                    }
                                </FormSection>
                            </div>
                        </Col>
                    }
                    {
                        this.checkIfPlanExists(["siloses-basic", "siloses-extended", "climates-basic", "climates-extended", "cages-basic", "cages-extended",
                            "dispensers-basic", "dispensers-extended", "dispensers5G-extended", "meters-basic", "meters-extended", "smallBasic-basic", "smallBasic-extended",
                            "chains-basic", "chains-extended", "alarm-basic", "alarm-extended"]) &&
                        <Col className={"plan-container"} md={4}>
                            <div className={"plan-title"}>
                                <h4>{t("devices")}</h4>
                            </div>
                            <div className={"plan-addons"}>
                                <FormSection name={"DevicesModule"}>
                                    <div className={"addon-options"}>
                                        {
                                            devicesFields.map((item, index) => {
                                                return (
                                                    this.checkIfPlanExists([`${item.class}-basic`, `${item.class}-extended`]) &&
                                                    <div key={index}>
                                                        <label>
                                                            <b>{item.title.toLowerCase()}</b>
                                                            <p>{this.getPlanType(item.class)}</p>
                                                        </label>
                                                        <Field
                                                            name={`${item.class}.amount`}
                                                            id={`dev.${item.class}`}
                                                            component={ReduxLabeledInput}
                                                            label={"2zł/szt."}
                                                            placeholder={t("amount")}
                                                            type={"number"}
                                                            parse={this.numberValueParser}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </FormSection>
                            </div>
                        </Col>
                    }
                    {
                        this.checkIfPlanExists(["administration-basic"]) &&
                        <Col className={"plan-container"} md={4}>
                            <div className={"plan-title"}>
                                <h4>{t("administration")}</h4>
                            </div>
                            <div className={"plan-addons"}>
                                <FormSection name={"AdministrationModule"}>
                                    <div className={"addon-options"}>
                                        {
                                            administrationFields.map((item, index) => (
                                                <div key={index}>
                                                    <label>
                                                        <b>{item.title.toLowerCase()}</b>
                                                    </label>
                                                    <Field
                                                        name={item.class}
                                                        id={`admin.${item.class}`}
                                                        component={ReduxLabeledInput}
                                                        label={"2zł/szt."}
                                                        placeholder={t("amount")}
                                                        type={"number"}
                                                        parse={this.numberValueParser}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </FormSection>
                            </div>
                        </Col>
                    }
                </Row>
                <ButtonGroup fixed renderInPortal={false}>
                    <Button buttonColor={"error"} buttonStyle={"round"} icon={<i className="fas fa-times"/>}
                            onClick={onCancel}/>
                    <Button buttonColor={"success"} buttonStyle={"round"} onClick={onBackStep}
                            icon={<i className="fas fa-arrow-left"/>}/>
                    <Button buttonColor={"success"} buttonStyle={"round"} icon={<i className="fas fa-arrow-right"/>}/>
                </ButtonGroup>
            </form>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    reduxForm({
        form: "plan-configuration-step",
        onSubmit: submit,
        validate
    }),
)(PlanConfigurationStep);
