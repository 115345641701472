import React, {Component} from 'react';
import {connect} from 'react-redux';
import Card from "../../../../../../components/basics/card/Card";
import LoadingComponent from "../../../../../../components/loading/LoadingComponent";
import {
    assignDeviceToFarm,
    assignDeviceToFarmNotificationFailure,
    assignDeviceToFarmNotificationSuccess
} from "../../../../../../api/devices/assignDeviceToFarm";
import {DevType} from "../../../../../../constans/devices";
import {Col, Row} from "react-bootstrap";
import QrScanner from "../../../../../../components/basics/qr-scanner/QRScanner";
import InfoBox from "../../../../../../components/basics/info-box/InfoBox";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class PigletScaleAdd extends Component {

    state = {
        loading: false
    }

    onQrScan = value => {
        this.setState({
            loading: true
        });
        assignDeviceToFarm(DevType.SMALL_CAGE, value).then(res => {
            assignDeviceToFarmNotificationSuccess(res);
            this.setState({
                loading: false
            });
        }).catch(e => {
            assignDeviceToFarmNotificationFailure(e);
            this.setState({
                loading: false
            });
        })
    }

    render() {
        const {t} = this.props;
        const {loading} = this.state;
        return (
            <Card>
                <LoadingComponent isLoading={loading}/>
                <Row className="justify-content-center">
                    <Col lg={6}>
                        <InfoBox>{t("newSettings.devices.form.qrScannerInfo")}</InfoBox>
                        <QrScanner onFound={this.onQrScan}/>
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(PigletScaleAdd);