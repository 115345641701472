import {subaddressNRF} from "../../../../IOT/device-functions/GatewayFunctions";

export function submit(values, dispatch, props) {
    console.log(values);
    const {isSubAddressing} = props;
    const {device} = values;
    if (isSubAddressing) {
        subaddressNRF(device, false);
    } else {
        subaddressNRF(device, true);
    }
}

export function validate(values, props) {
    const errors = {};

    if (!values.device) {
        errors.device = props.t("required");
    }

    return errors;
}