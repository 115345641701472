import React from "react";
import {DevType, LoadedDataTypes} from "../../../../../constans/devices";
import {DispenserNRFCommandTypes} from "../../../../../constans/mqttMessages";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import {connect} from "react-redux";
import _ from "lodash";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {setNRFPower} from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import  i18next from "i18next";

export class NRFPower extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.getState(props)
    }

    getState(props) {
        const {dispensers} = props;
        let device = dispensers[0];
        return {
            t: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_NRF_POWER}.t`, 0),
            loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[DispenserNRFCommandTypes.SET_NRF_POWER] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
            device
        };
    }

    setT = value => {
        this.setState({
            t: value
        })
    }

    onClick = () => {
        const {dispensers} = this.props;
        const devices = groupDevicesByGatewayID(dispensers);
        if (devices) {
            const {t} = this.state;
            for (let devList of devices.values()) {
                devList[DevType.BROADCAST].length && setNRFPower(devList[DevType.BROADCAST], t);
                devList[DevType.DISPENSER_NRF_MULTI].length && setNRFPower(devList[DevType.DISPENSER_NRF_MULTI], t);
                devList[DevType.DISPENSER_NRF].length && setNRFPower(devList[DevType.DISPENSER_NRF], t);
            }
        }
    };

    render() {
        const {loadedDataFrom, SetTime, device, t} = this.state;
        return (
            <DeviceSettingsCard title={"Moc NRF"} onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[DispenserNRFCommandTypes.SET_NRF_POWER].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date:moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{i18next.t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledSlider value={t} label={"Moc"} min={0} max={3}
                               valueFormatter={(value) => value}
                               onChange={this.setT}
                />
            </DeviceSettingsCard>
        );
    }

}

NRFPower = connect(state => ({

}))(NRFPower);

export default NRFPower;