import React from "react";
import Header from "../../../../../../components/basics/header/Header";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import moment from "moment";
import ViewContainer from "../../../../../../components/basics/view-container/ViewContainer";
import Card from "../../../../../../components/basics/card/Card";
import Stepper from "../../../../../../components/basics/stepper/Stepper";
import Step from "../../../../../../components/basics/stepper/Step";
import AddFarmView from "./pages/AddFarmView";
import ButtonGroup from "../../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../../components/basics/button/Button";
import SecondStepView from "./pages/SecondStepView";
import InvoiceStepView from "./pages/IvoiceStepView";
import ThirdStepView from "./pages/ThirdStepView";
import SummaryStepView from "./pages/SummaryStepView";
import FifthStepView from "./pages/FifthStepView";
import {connect} from "react-redux";
import PickPlanView from "./pages/PickPlanView";
import PlanConfigurationStep from "./pages/PlanConfigurationStep";
import {get, merge} from "lodash";
import "./pages/_plan-picker.scss";
import {gatewayCustomerFind, updateSelectedPlans,} from "../../../../../../actions/braintreeActions";
import {
    createSubscription,
    createSubscriptionNotificationFailure,
    createSubscriptionNotificationSuccess
} from "../../../../../../api/subscriptions/CreateSubscription";
import {registerIOT} from "../../../../../../actions/mqttActions";

function mapStateToProps(state) {
    return {
        lang: state.language.lang.lang,
        selectedPlans: state.braintree.selectedPlans,
        agreementRead: state.braintree.agreementRead,
        userData: state.user.attributes,
        customer: state.braintree.customer,
        subscriptionList: state.braintree.subscriptionList,
        dropinInstance: state.braintree.dropinInstance,
        farms: state.user.user.FarmData
    }
}

const initialModules = {
    BreedingModule: {
        sows: {
            extended: false,
            size: 0
        },
        porkers: {
            extended: false,
            size: 0
        }
    },
    DevicesModule: {
        siloses: {
            extended: false,
            amount: 0,
        },
        climates: {
            extended: false,
            amount: 0,
        },
        cages: {
            extended: false,
            amount: 0,
        },
        dispensers: {
            extended: false,
            amount: 0,
        },
        dispensers5G: {
            amount: 0,
        },
        meters: {
            extended: false,
            amount: 0,
        },
        smallCages: {
            extended: false,
            amount: 0,
        },
        alarms: {
            extended: false,
            amount: 0,
        },
        chains: {
            extended: false,
            amount: 0,
        }
    },
    AdministrationModule: {
        managersAmount: 0,
        operatorsAmount: 0,
        techAdviceAmount: 0,
        techSupportAmount: 0,
    },
    FarmObject: {},
};

class SubProcess extends React.Component {

    constructor(props) {
        super(props);
        const {dispatch, customer, userData: {email, name, phone, sub}, match: {params: {FarmID}}, farms} = this.props;
        let farm = {};
        if (FarmID) {
            farm = farms.find((f) => f.FarmID === FarmID);
        }
        this.state = {
            activeStep: 1,
            data: FarmID ? {
                ...initialModules,
                FarmObject: {FarmID: farm.FarmID, FarmName: farm.FarmName, Adr: farm.Adr}
            } : initialModules
        };
        if (!customer) {
            dispatch(gatewayCustomerFind(email, name, phone, sub));
        }
    }

    onCancelSubscription = () => {
        const {dispatch, history} = this.props;
        dispatch({type: "CHANGE_FARM"});
        history.push("/farmSettings/billing");
    };

    onNextStep = () => {
        this.setState((state) => ({
            activeStep: state.activeStep + 1
        }))
    };

    onBackStep = () => {
        const {activeStep} = this.state;
        const {dispatch, match: {params: {FarmID}}} = this.props;
        if ((!FarmID && activeStep === 3) || (FarmID && activeStep === 2)) {
            dispatch({type: "BT_CLEAR_SELECTED_PLANS"});
            this.setState((state) => ({
                activeStep: state.activeStep - 1,
                data: {...state.data, ...initialModules}
            }))
        } else {
            this.setState((state) => ({
                activeStep: state.activeStep - 1
            }));
        }
    };

    clearSelectedPlans = () => {
        const {dispatch} = this.props;
        dispatch({type: "BT_CLEAR_SELECTED_PLANS"});
        this.setState((state) => ({
            data: {...state.data, ...initialModules}
        }));
    };

    onNextStepData = (data) => {
        this.setState((state) => ({
            data: merge(state.data, data),
            activeStep: state.activeStep + 1
        }));
    };

    onUpdatePlan = (e) => {
        const {dispatch} = this.props;
        const planType = e.currentTarget.className.split("-");
        if (planType.length > 1) {
            if (planType[0] === "sows" || planType[0] === "porkers") {
                const updatedModule = planType[0] === "sows" ? {
                    ...this.state.data.BreedingModule[planType[0]],
                    extended: planType[1] === "extended"
                } : {
                    ...this.state.data.BreedingModule[planType[0]],
                    size: +!planType[1].includes("active"),
                    extended: planType[1] === "extended"
                };
                this.setState((state) => ({
                    data: {
                        ...state.data,
                        BreedingModule: {
                            ...state.data.BreedingModule,
                            [planType[0]]: updatedModule
                        }
                    }
                }))
            } else if (planType[0] !== "administration") {
                const updatedModule = planType[0] === "dispensers5G" ? {...this.state.data.DevicesModule[planType[0]]} : {
                    ...this.state.data.DevicesModule[planType[0]],
                    extended: planType[1] === "extended"
                };
                this.setState((state) => ({
                    data: {
                        ...state.data,
                        DevicesModule: {
                            ...state.data.DevicesModule,
                            [planType[0]]: updatedModule
                        }
                    }
                }))
            }
        }
        dispatch(updateSelectedPlans(e.currentTarget.className));
    };

    onSowsSizeUpdate = (value) => {
        this.setState((state) => ({
            data: {
                ...state.data,
                BreedingModule: {
                    ...state.data.BreedingModule,
                    sows: {
                        ...state.data.BreedingModule.sows,
                        size: +value
                    }
                }
            }
        }))
    };

    onSubscribe = async () => {
        const {match: {params: {FarmID}}, subscriptionList, dropinInstance, userData, dispatch} = this.props;
        const {data: {FarmObject, BreedingModule, DevicesModule, AdministrationModule}} = this.state;
        const subs = FarmID ? subscriptionList.filter((sub) => sub.id.startsWith(FarmID)) : [];
        const hasAnySubscriptionForFarm = subs.length > 0;
        const trialInfo = {
            trialDurationUnit: "day",
            trialDuration: moment().add(1, "month").endOf("month").diff(moment(), "days") + 1,
            trialPeriod: !hasAnySubscriptionForFarm,
        };
        const sorted = subs.sort((a, b) => moment(b.createdAt).toDate().getTime() - moment(a.createdAt).toDate().getTime());
        const balance = sorted.length > 0 ? get(sorted[0], "balance", "0.00") : "0.00";
        await createSubscription("freePlanYearlyPLN", dropinInstance, "peeleny",
            FarmObject, userData.sub, trialInfo, {BreedingModule, DevicesModule, AdministrationModule},
            balance)
            .then((res) => {
                createSubscriptionNotificationSuccess(res);
                this.onNextStep();
                dispatch(registerIOT());
            })
            .catch((e) => createSubscriptionNotificationFailure(e));
        dispatch({type: "BT_CLEAR_SELECTED_PLANS"});
    };

    render() {
        const {match: {params: {FarmID}}, t} = this.props;
        const {activeStep, data: {FarmObject}, data} = this.state;
        return (
            <div>
                <Header text={FarmID ? t("subProcess.renew", {name: FarmObject.FarmName}) : t("subProcess.new")}/>
                <ViewContainer addPaddingForButtons>
                    <Card>
                        <Stepper activeStep={activeStep}>
                            <>
                                {!FarmID && <Step label={t("subscriptions.steps.addFarm")}/>}
                                <Step label={t("subscriptions.steps.planSelection")}/>
                                <Step label={t("subscriptions.steps.planConfiguration")}/>
                                <Step label={t("subscriptions.steps.agreementConditions")}/>
                                <Step label={t("subscriptions.steps.dataToInvoice")}/>
                                <Step label={t("subscriptions.steps.payment")}/>
                                <Step label={t("subscriptions.steps.confirmation")}/>
                                <Step label={t("summary")}/>
                            </>
                        </Stepper>
                        {
                            (activeStep === 1 && !FarmID) &&
                            <AddFarmView
                                onCancel={this.onCancelSubscription}
                                onNextStepData={this.onNextStepData}
                            />
                        }
                        {
                            (activeStep === 1 && FarmID || (!FarmID && activeStep === 2)) &&
                            <PickPlanView
                                onCancel={this.onCancelSubscription}
                                isFirstStep={FarmID}
                                onNextStep={this.onNextStep}
                                onBackStep={this.onBackStep}
                                clearPlans={this.clearSelectedPlans}
                                onUpdatePlan={this.onUpdatePlan}
                            />
                        }
                        {
                            (activeStep === 2 && FarmID || (!FarmID && activeStep === 3)) &&
                            <PlanConfigurationStep
                                data={data}
                                farmExisted={activeStep === 2}
                                onSowsSizeUpdate={this.onSowsSizeUpdate}
                                onBackStep={this.onBackStep}
                                onNextStepData={this.onNextStepData}
                                onCancel={this.onCancelSubscription}
                            />
                        }
                        {
                            (activeStep === 3 && FarmID || (!FarmID && activeStep === 4)) &&
                            <SecondStepView
                                isFirstStep={false}
                                onBackStep={this.onBackStep}
                                onNextStep={this.onNextStep}
                                onCancel={this.onCancelSubscription}
                            />
                        }
                        {
                            (activeStep === 4 && FarmID || (!FarmID && activeStep === 5)) &&
                            <InvoiceStepView
                                onBackStep={this.onBackStep}
                                onNextStepData={this.onNextStepData}
                                onCancel={this.onCancelSubscription}
                            />
                        }
                        {
                            (activeStep === 5 && FarmID || (!FarmID && activeStep === 6)) &&
                            <ThirdStepView
                                onBackStep={this.onBackStep}
                                onNextStep={this.onNextStep}
                                onCancel={this.onCancelSubscription}
                            />
                        }
                        {
                            (activeStep === 6 && FarmID || (!FarmID && activeStep === 7)) &&
                            <SummaryStepView
                                onBackStep={this.onBackStep}
                                onCancel={this.onCancelSubscription}
                                onSubscribe={this.onSubscribe}
                            />
                        }
                        {
                            (activeStep === 7 && FarmID || (!FarmID && activeStep === 8)) &&
                            <>
                                <FifthStepView logout={false}/>
                                <ButtonGroup fixed>
                                    <Button buttonColor={"error"} buttonStyle={"round"}
                                            icon={<i className="fas fa-times"/>} onClick={this.onCancelSubscription}/>
                                </ButtonGroup>
                            </>
                        }
                    </Card>
                </ViewContainer>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
)(SubProcess);
