import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, initialize, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import ReduxLabeledSelect from "../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import FormForSettings from "../../FormForSettings";
import {withCookies} from 'react-cookie';
import Card from "../../../../components/basics/card/Card";
import {setBackgroundColorInMeta} from "../../../../utils/ColorUtils";
import {withTranslation} from "react-i18next";

const FormName = 'settings-profile-customize';

export class CustomizeProfileView extends React.Component {

    constructor(props) {
        super(props);
        const {t} = this.props;
        this.state = {
            themeOptions: [
                {name: t("newSettings.profile.customizeProfileView.light"), value: "normal"},
                {name: t("newSettings.profile.customizeProfileView.dark"), value: "dark"},
                // {name: content.contrast, value: "contrast"},
            ]
        }
    }

    componentDidMount() {
        const {dispatch, cookies} = this.props;
        dispatch(initialize(FormName, {
            themeStyle: cookies.get("theme") || "normal",
            showDeadAnimalsInSearch: false
        }))
    }

    onThemeChange = (event, newValue) => {
        const {cookies} = this.props;
        cookies.set("theme", newValue);
        setBackgroundColorInMeta();
    };

    render() {
        const {handleSubmit, t} = this.props;
        const {themeOptions} = this.state;
        return (
            <Card className={FormName}>
                <FormForSettings handleSubmit={handleSubmit} showButtons={false}>
                    <Row className="justify-content-center">
                        <Col md={6}>
                            <Field
                                id="themeStyle"
                                name="themeStyle"
                                component={ReduxLabeledSelect}
                                label={t("newSettings.profile.customizeProfileView.themeStyle")}
                                options={themeOptions}
                                onChange={this.onThemeChange}
                                clearButton={false}
                            />
                        </Col>
                    </Row>
                </FormForSettings>
            </Card>
        );
    }

}

CustomizeProfileView = reduxForm({
    form: FormName
})(CustomizeProfileView);

CustomizeProfileView = connect(state => ({
    farm: state.location.farm,
}))(CustomizeProfileView);
CustomizeProfileView = withCookies(CustomizeProfileView);
export default withTranslation()(CustomizeProfileView);

