import React from "react";
import Loading from "../../../../components/loading/LoadingComponent";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import NewIOT from "../../../../IOT/NewIOT";
import WorkType from "./settings/WorkType";
import MotorSetup from "./settings/MotorSetup";
import PunishmentSetup from "./settings/PunishmentSetup";
import {DevType} from "../../../../constans/devices";
import NRFTemperature from "./settings/NRFTemperature";
import {checkIfUserIsService} from "../../../../utils/NewRolesUtils";
import {CANNotifications} from "./settings/CANNotifications";
import ClearFRAM from "./settings/ClearFRAM";
import NRFPower from "./settings/NRFPower";
import ClearHistory from "./settings/ClearHistory";
import NRFWater from "./settings/NRFWater";
import ForageEfficiency from "./settings/ForageEfficiency";

export class ManageDispenserNRFConfiguration extends React.Component {

    state = {
        selectedDispensers: this.props.location.state ? this.props.location.state.selectedDispensers : [],
        loading: true
    };

    constructor(props) {
        super(props);

        if (this.state.selectedDispensers.length === 0) {
            this.props.history.push(`/${this.props.farm}/settings/feeding/f3f5/manage`)
        }
        NewIOT.startSendingDeviceState(this.state.selectedDispensers, this.setLoading, this.setLoading);
    }

    setLoading = () => {
        this.setState({
            loading: false
        })
    };

    render() {
        const {selectedDispensers, loading} = this.state;
        const dispensersNRF = selectedDispensers.filter(item => item.DevType === DevType.DISPENSER_NRF);
        const hasWater = !!dispensersNRF.find(o => o.hasWater());
        const hasTemperature = !!dispensersNRF.find(o => o.hasTemperatureSensor());
        if (selectedDispensers.length === 0) return null;
        return (
            <div style={{position: "relative"}}>
                <Loading isLoading={loading}/>
                <Row>
                    <Col xl={4}>
                        <WorkType dispensers={selectedDispensers}/>
                    </Col>
                    <Col xl={4}>
                        <MotorSetup dispensers={selectedDispensers}/>
                    </Col>
                    <Col xl={4}>
                        <ForageEfficiency dispensers={selectedDispensers}/>
                    </Col>
                    {
                        dispensersNRF.length > 0 &&
                        <>
                            {
                                hasWater &&
                                <Col xl={8}>
                                    <NRFWater dispensers={selectedDispensers}/>
                                </Col>
                            }
                            {
                                hasTemperature &&
                                <Col xl={4}>
                                    <NRFTemperature dispensers={selectedDispensers}/>
                                </Col>
                            }
                        </>
                    }
                    {
                        checkIfUserIsService() &&
                        <>
                            <Col xl={4}>
                                <CANNotifications dispensers={selectedDispensers}/>
                            </Col>
                            <Col xl={4}>
                                <ClearFRAM dispensers={selectedDispensers}/>
                            </Col>
                            <Col xl={4}>
                                <NRFPower dispensers={selectedDispensers}/>
                            </Col>
                            <Col xl={4}>
                                <ClearHistory dispensers={selectedDispensers}/>
                            </Col>
                            <Col xl={4}>
                                <PunishmentSetup dispensers={selectedDispensers}/>
                            </Col>
                        </>
                    }
                </Row>
            </div>
        );
    }

}

ManageDispenserNRFConfiguration = connect(state => ({
    farm: state.location.farm
}))(ManageDispenserNRFConfiguration);

export default ManageDispenserNRFConfiguration;
