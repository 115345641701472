import React from "react";
import Card from "../../../../components/basics/card/Card";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {Col, Row} from "react-bootstrap";
import ReduxLabeledSelect from "../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import {submit, validate} from "./SubAddressViewSubmit";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import {withTranslation} from "react-i18next";
import NewIOT from "../../../../IOT/NewIOT"
import {isEqual, isFunction} from "lodash";
import InfoBox from "../../../../components/basics/info-box/InfoBox";

export class SubAddressView extends React.Component {

    state = {
        waitingForState: false
    }

    lastMessage = null;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.currentDevice, prevProps.currentDevice)) {
            const {user: {ClientID, LocalUserID}} = this.props;
            if (prevProps.currentDevice) {
                NewIOT.stopSendingDeviceState(ClientID, LocalUserID, prevProps.currentDevice.GatewayID, [prevProps.currentDevice.DevID]);
            }
            this.lastMessage = NewIOT.startSendingDeviceState(this.props.currentDevice, this.onGetShadowSuccess, this.onGetShadowFailure);
            this.setState({
                waitingForState: true
            })
        }
    }

    componentWillUnmount() {
        if (this.props.startedAddressing) {
            this.props.handleSubmit()
        }
    }

    onGetShadowSuccess = msg => {
        if (msg.MsgId === this.lastMessage) {
            this.setState({
                waitingForState: false,
                error: false
            })
        }
    }

    onGetShadowFailure = (error, msg) => {
        if (msg.MsgId === this.lastMessage) {
            this.setState({
                waitingForState: false,
                error: true
            })
        }
    }

    render() {
        const {waitingForState, error} = this.state;
        const {handleSubmit, devices, isSubAddressing, t} = this.props;
        let devs = devices.filter(item => item.DevType === DevType.DISPENSER_NRF_MULTI).map(dev => ({
            name: isFunction(dev.getSelectName) ? dev.getSelectName() : dev.Name,
            value: dev
        }));
        return (
            <Card>
                <form onSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            {
                                error &&
                                <InfoBox>{t("newSettings.devices.form.error")}</InfoBox>
                            }
                            <Field
                                name="device"
                                component={ReduxLabeledSelect}
                                label={t("device")}
                                options={devs}
                            />
                        </Col>
                    </Row>
                    <ButtonGroup fixed renderInPortal={false}>
                        <Button buttonStyle={"round"} buttonColor={isSubAddressing ? "error" : "success"}
                                icon={isSubAddressing ? <i className="fas fa-stop"/> :
                                    <i className="fas fa-paper-plane"/>} disabled={waitingForState || error}/>
                    </ButtonGroup>
                </form>
            </Card>
        );
    }

}

SubAddressView = reduxForm({
    form: "subaddressing",
    onSubmit: submit,
    validate
})(SubAddressView);

const selector = formValueSelector("subaddressing");
SubAddressView = connect(state => {
    let currentDevice = selector(state, "device");
    let isSubAddressing = false;
    if (currentDevice) {
        let shadow = state.shadows.shadows.get(currentDevice.DevID);
        if (shadow) {
            isSubAddressing = shadow.subaddressingState || false;
        }
    }
    return {
        devices: state.farmDevices.devices,
        user: state.user.user,
        currentDevice,
        isSubAddressing
    }
})(SubAddressView);

export default withTranslation()(SubAddressView);