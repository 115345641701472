import sha from "crypto-js/sha256";
import {changeMQTTPassword} from "../../../../actions/userActions";
import {SubmissionError} from "redux-form";
import i18n from "../../../../i18n";


const t = (val) => i18n.t(val);
export function submit(values, dispatch, props) {
    const {user, farm} = props;
    const {passwordOld, password, passwordConfirmation} = values;

    let hash = sha(password.trim()).toString();

    if (passwordOld) {
        let hashOldPass = sha(passwordOld.trim()).toString();
        if (hashOldPass !== user.LocalMqttSecret) {
            throw new SubmissionError({
                _error: t("newSettings.profile.changePasswordView.wrongOldPasswod")
            })
        }
    }

    if (password !== passwordConfirmation) {
        throw new SubmissionError({
            _error: t("newSettings.profile.changePasswordView.diffrentPasswords")
        })
    }
    dispatch(changeMQTTPassword(hash, user.ClientID, user.LocalUserID, farm));
}

export function validate(values, props) {
    const errors = {};
    const {password, passwordConfirmation, passwordOld} = values;
    const {t} = props;
    if (!passwordOld) {
        errors.passwordOld = t("required");
    }
    if (!passwordConfirmation) {
        errors.passwordConfirmation = t("required");

    }
    if (!password) {
        errors.password = t("required");
    }
    return errors;
}