import React from "react";
import {connect} from "react-redux";
import {updateDictionaryDynamoDB} from "../../../../../actions/dictionaryActions";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../../../../components/modals-new/confirm-modal/ConfirmModal";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

class RemoveMedicineDictionaryComponent extends React.Component {

    onRemoveClick = () => {
        const {value, user, t} = this.props;
        this.props.show(ConfirmModalName, {
            title: i18next.t("newSettings.dictionary.medicines.removeTitle", {type: value.WData.Name}),
            text: i18next.t("newSettings.dictionary.medicines.removeText", {type: value.WData.Name}),
            confirmText: t("yes"),
            onConfirmed: props => {
                let medicine = value.prepareBeanToSave();
                medicine.remove();
                this.props.dispatch(updateDictionaryDynamoDB(medicine, user.ClientID, user.LocalUserID));
                props.handleHide();
            }
        });
    };

    render() {
        return (
            <div onClick={this.onRemoveClick}>
                <i className="fas fa-trash pointer"/>
            </div>
        );
    }

}

RemoveMedicineDictionaryComponent = connect(
    state => ({
        user: state.user.user,
    }),
    dispatch => ({dispatch, ...bindActionCreators({show}, dispatch)})
)(RemoveMedicineDictionaryComponent);

export default withTranslation()(RemoveMedicineDictionaryComponent);