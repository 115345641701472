import React from "react";
import {Col, Row} from "react-bootstrap";
import {change, Field, formValueSelector, initialize, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import Card from "../../../../../components/basics/card/Card";
import FormForSettings from "../../../FormForSettings";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import _ from "lodash";
import ReduxLabeledSelect from "../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {CurveDayShowingType, CurveType} from "../../../../../constans/feedingTypes";
import {getCurveDayShowingType, isValidCurveForDevType} from "../../../../../utils/FeedingUtils";
import feeding_before from "../../../../../resources/images/pictures/curve_before.svg"
import {ReactSVG} from "react-svg";
import {DevType} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import {hasDeviceType} from "../../../../../utils/DevicesUtils";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

const FormName = 'settings-feeding-curves-edit-basic';

const DevTypeToName = {
    [DevType.DISPENSER]: "F1",
    [DevType.DISPENSER_NRF]: "F3, F5"
};

export class EditBasics extends React.Component {

    constructor(props) {
        super(props);
        const {dispatch, defaultValue, keptValues} = this.props;
        const curveType = keptValues.curveType || Object.values(CurveType).includes(_.get(defaultValue, "SetData.Type", "")) ? _.get(defaultValue, "SetData.Type", "") : CurveType.INDIVIDUAL;
        dispatch(initialize(FormName, {
            curveName: keptValues.curveName || _.get(defaultValue, "SetData.Name", ""),
            curveType: curveType,
            repetitionType: _.isNil(keptValues.repetitionType) ? getCurveDayShowingType(defaultValue) : keptValues.repetitionType
        }))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {curveType, dispatch, initialized} = this.props;
        if (initialized && prevProps.initialized) {
            if (curveType !== prevProps.curveType) {
                dispatch(change(FormName, "repetitionType", getCurveDayShowingType({SetData: {Type: curveType}})));
            }
        }
    }

    repetitionTypeChange = (newValue) => {
        const {dispatch} = this.props;
        dispatch(change(FormName, "repetitionType", newValue));
    };

    curveDescriptions = ({t} = this.props) => ({
        [CurveType.PARTURITION]: [
            {
                header: t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.before.name`),
                type: CurveDayShowingType.BEFORE,
                img: feeding_before,
                info: [
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.before.desc1`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.before.legend`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.before.compatibility`)
                ]
            },
            {
                header: t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.both.name`),
                type: CurveDayShowingType.BOTH,
                img: feeding_before,
                info: [
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.both.desc1`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.both.legend`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.both.compatibility`)
                ]
            },
            {
                header: t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.after.name`),
                type: CurveDayShowingType.AFTER,
                img: feeding_before,
                info: [
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.after.desc1`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.after.legend`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.PARTURITION}.after.compatibility`)
                ]
            }
        ],
        [CurveType.MATING]: [
            {
                header: t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.before.name`),
                type: CurveDayShowingType.BEFORE,
                img: feeding_before,
                info: [
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.before.desc1`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.before.legend`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.before.compatibility`)
                ]
            },
            {
                header: t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.both.name`),
                type: CurveDayShowingType.BOTH,
                img: feeding_before,
                info: [
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.both.desc1`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.both.legend`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.both.compatibility`)
                ]
            },
            {
                header: t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.after.name`),
                type: CurveDayShowingType.AFTER,
                img: feeding_before,
                info: [
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.after.desc1`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.after.legend`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.MATING}.after.compatibility`)
                ]
            }
        ],
        [CurveType.INDIVIDUAL]: [
            {
                header: t(`newSettings.feeding.curves.curveDescriptions.${CurveType.INDIVIDUAL}.normal.name`),
                type: CurveDayShowingType.NORMAL,
                img: feeding_before,
                info: [
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.INDIVIDUAL}.normal.desc1`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.INDIVIDUAL}.normal.legend`),
                    t(`newSettings.feeding.curves.curveDescriptions.${CurveType.INDIVIDUAL}.normal.compatibility`)
                ]
            }
        ]
    });

    renderCard = ({type, header, img, info = []}) => {
        const {index, repetitionType, devices} = this.props;
        const compatibilityIssueWST = index > 4 && (hasDeviceType(devices, DevType.DISPENSER));
        const notValidNames = Object.keys(DevTypeToName).map(devType => {
            console.log("DEVTYPE", devType);
            return (compatibilityIssueWST && devType === DevType.DISPENSER) || !hasDeviceType(devices, devType) || isValidCurveForDevType({
                devType: devType,
                index: index,
                showingType: type
            }) ? null : DevTypeToName[devType]
        }).filter(o => !!o).join(", ");
        return (
            <Card
                onClick={() => this.repetitionTypeChange(type)}
                selected={repetitionType === type}>

                <Row>
                    <Col xs={12}>
                        {
                            !!notValidNames && <InfoBox
                                boxColor={"warning"}>{i18next.t("newSettings.feeding.curves.editBasics.compatibilityIssueWithX", {type: notValidNames})}</InfoBox>
                        }
                    </Col>
                    <Col md={4}>
                        <ReactSVG src={img}/>
                    </Col>
                    <Col md={8}>
                        <h5>{header}</h5>
                        <small>
                            {
                                info.map((o, i) => (
                                    <p className={`opacity-${100 - (i * 25)}`}>{o}</p>
                                ))
                            }
                        </small>
                    </Col>
                </Row>
            </Card>
        )
    };

    render() {
        const {handleSubmit, t, curveType, devices, index} = this.props;
        const compatibilityIssue = index > 4 && (hasDeviceType(devices, DevType.DISPENSER));
        const curveTypeOptions = Object.values(CurveType).map(opt => {
            return {
                name: t(`curves.${opt}`),
                value: opt
            }
        });
        const curveDescriptions = this.curveDescriptions();
        return (
            <Card className={FormName}>
                <FormForSettings {...this.props} handleSubmit={handleSubmit} icon={<i className="fas fa-arrow-right"/>}>
                    <Row className="justify-content-center">
                        <Col md={6}>
                            {
                                compatibilityIssue && <InfoBox
                                    boxColor={"warning"}>{i18next.t("newSettings.feeding.curves.editBasics.compatibilityIssueWithCurveIndex", {
                                    dispenser: DevTypeToName[DevType.DISPENSER],
                                    type1: 1,
                                    type2: 5
                                })}</InfoBox>
                            }
                            <Field
                                name="curveName"
                                id="curveName"
                                type="text"
                                required
                                component={ReduxLabeledInput}
                                label={t("newSettings.feeding.curves.editBasics.curveName")}
                            />
                            <Field
                                name="curveType"
                                id="curveType"
                                component={ReduxLabeledSelect}
                                required
                                label={t("newSettings.feeding.curves.editBasics.curveType")}
                                options={curveTypeOptions}
                            />
                        </Col>

                    </Row>
                    <Row className="justify-content-center">
                        <Col md={6}>
                            {
                                !!curveType &&
                                <>
                                    {(curveDescriptions[curveType] || []).map(curveDesc => this.renderCard(curveDesc))}
                                </>
                            }
                        </Col>
                    </Row>
                </FormForSettings>
            </Card>
        );
    }
}

function submit(values, dispatch, props) {
    const {data, onNextPage} = props;
    const {curveName, curveType, repetitionType = CurveDayShowingType.NORMAL} = values;
    let tmp = {
        ...(data || {}),
        curveName,
        curveType,
        repetitionType
    };
    onNextPage(1, tmp, {curveName, curveType, repetitionType});
}

function validate(values, props) {
    const {t} = props;
    const errors = {};
    if (!values.curveName) {
        errors.curveName = t("required");
    }
    if (!values.curveType) {
        errors.curveType = t("required");
    }
    return errors
}

EditBasics = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(EditBasics);

const selector = formValueSelector(FormName);

EditBasics = connect(state => ({
    farm: state.location.farm,
    curveType: selector(state, "curveType"),
    repetitionType: selector(state, "repetitionType"),
    devices: state.farmDevices.devices
}))(EditBasics);
export default withTranslation()(EditBasics);

