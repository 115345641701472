import React from "react";
import Card from "../../../../../../components/basics/card/Card";
import FormForDevices from "../FormForDevices";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {submit, validate} from "./ClimateSK3Submit";
import {withRouter} from "react-router-dom";
import withRoles from "../../../../../../components/withRoles";
import {Roles} from "../../../../../../constans/roles";
import ReduxLabeledInput from "../../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {Col, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import ReduxLabeledSlider from "../../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";

export class ClimateSK3 extends React.Component {

    render() {
        const {handleSubmit, address, device, t, bridge, edit} = this.props;
        return (
            <Card>
                <FormForDevices handleSubmit={handleSubmit} showInterfacePicker device={device} address={address}
                                showAdditionalButton={device} bridge={bridge} showHelp={!edit}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <Field
                                component={ReduxLabeledInput}
                                name="Number"
                                label={t("newSettings.devices.form.number")}
                                type={"text"}
                                normalize={value => value ? value.slice(0, 3).toUpperCase() : null}
                            />
                            <Field
                                name={"matsNumber"}
                                id={"matsNumber"}
                                component={ReduxLabeledSlider}
                                min={0}
                                max={8}
                                step={1}
                                label={t("settings.matsNumber")}
                            />
                        </Col>
                    </Row>
                </FormForDevices>
            </Card>
        );
    }

}

ClimateSK3 = reduxForm({
    form: "climateSK3",
    onSubmit: submit,
    validate,
    initialValues: {
        matsNumber: 0
    }
})(ClimateSK3);

ClimateSK3 = withRoles(props => {
    return {roles: [Roles._DEVICE_CONFIG], showComponent: true}
})(ClimateSK3);

const selector = formValueSelector("climateSK3");
ClimateSK3 = connect(state => ({
    address: selector(state, "address"),
    device: selector(state, "device"),
    bridge: selector(state, "bridge"),
    edit: selector(state, "edit"),
    user: state.user.user,
    farm: state.location.farm
}))(ClimateSK3);

ClimateSK3 = withTranslation()(ClimateSK3);
export default withRouter(ClimateSK3);
