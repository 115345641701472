import React from "react";
import {Col, Row} from "react-bootstrap";
import {change, formValueSelector, initialize, reduxForm} from "redux-form";
import {connect} from "react-redux"
import Card from "../../../../../components/basics/card/Card";
import _ from "lodash";
import PropTypes from "prop-types";
import {SettingTypes} from "../../../../../constans/settingTypes";
import {setNutritionCurve} from "../../../../../IOT/device-functions/GatewayFunctions";
import {DevType} from "../../../../../constans/devices";
import {withRouter} from "react-router-dom";
import {convertWeightUnitTo, getUnit} from "../../../../../utils/UnitUtils";
import {getFeedingUnit} from "../../../../../utils/SettingsUtils";
import {
    CurveDayShowingType,
    CurveTypesWithInsemination,
    CurveTypesWithParturition
} from "../../../../../constans/feedingTypes";
import Button from "../../../../../components/basics/button/Button";
import {formatCurveDay} from "../../../../../utils/FeedingUtils";
import FormForSettings from "../../../FormForSettings";
import Setting from "../../../../../beans/Setting"
import TableGrid from "../../../../../components/basics/table-grid/TableGrid";
import Chart from "../../../../../components/basics/chart/Chart";
import {UnitTypes} from "../../../../../constans/unitTypes";
import Checkbox from "../../../../../components/basics/checkbox/Checkbox";
import {withTranslation} from "react-i18next";

const FormName = "settings-feeding-curves-edit-special-days";

export class PreviewAndSave extends React.Component {

    constructor(props) {
        super(props);
        const {dispatch, data: {days, curveType, repetitionType, repetitionDay}} = this.props;
        const repetition = repetitionType === CurveDayShowingType.BOTH ? repetitionDay : (repetitionType === CurveDayShowingType.AFTER ? 255 : 0);
        const curvePreview = _.cloneDeep(days).map((day, index) => {
            return {
                day: formatCurveDay(index + 1, {
                    SetData: {
                        Days: days,
                        InseminationJumpTo: repetition,
                        Type: curveType
                    }
                }),
                amount: day.amount,
                notify: day.notify,
                repetition: repetition === (index + 1)
            }
        });
        dispatch(initialize(FormName, {
            curvePreview: curvePreview
        }))
    }

    toggleConditionDay = (index) => {
        const {dispatch, curvePreview} = this.props;
        const newCurvePreview = _.cloneDeep(curvePreview);
        newCurvePreview[index].notify = !newCurvePreview[index].notify;
        dispatch(change(FormName, "curvePreview", newCurvePreview))
    };

    render() {
        const {handleSubmit, curvePreview, data: {curveType}, updating, adding, t, onPrevPage} = this.props;
        let unit = getFeedingUnit();
        const headers = [
            {
                name: t("day"),
                field: "day"
            },
            {
                name: t("forageAmount"),
                field: "amount",
                valueFormatter: (value) => {
                    return convertWeightUnitTo(value, {showUnit: true, unit: unit, fixed: unit ? 2 : 0})
                },
            },
            {
                name: t("newSettings.feeding.curves.editSpecialDays.conditionCheckDay"),
                field: "notify",
                component: ({index, value}) => <Checkbox onChange={() => this.toggleConditionDay(index)}
                                                         checked={value}/>
            }
        ].filter(o => o);
        const chartDef = [
            {
                color: "green",
                dataKey: "amount",
                name: t("forageAmount"),
                unit: getUnit("weight", UnitTypes.MEDIUM),
                valueConverter: (value) => {
                    return convertWeightUnitTo(value, {showUnit: false, unit: unit, fixed: unit ? 2 : 0})
                },
            }
        ];
        return (
            <Card className={FormName}>
                <FormForSettings {...this.props} optionalButtons={<Button buttonStyle={"round"} type={"button"}
                                                                          onClick={() => onPrevPage(1)}
                                                                          icon={<i className="fas fa-arrow-left"/>}
                                                                          buttonColor={"secondary"}/>}
                                 handleSubmit={handleSubmit} submitting={updating || adding}>
                    <Row className="justify-content-center">
                        <Col md={12}>
                            <div style={{height: "20rem"}}>
                                <Chart dataDef={chartDef} data={(curvePreview || [])}
                                       tooltipLabelFormatter={val => `${t("curveDay")} ${val}`}
                                       Yaxis={{
                                           name: t("dosage")
                                       }} Xaxis={{
                                    name: t("curveDay"),
                                    dataKey: "day"
                                }} referenceLines={[
                                    CurveTypesWithParturition.includes(curveType) ? {
                                        x: '0',
                                        color: "blue",
                                        name: t("parturitionDate")
                                    } : null,
                                    CurveTypesWithInsemination.includes(curveType) ? {
                                        x: '0',
                                        color: "blue",
                                        name: t("inseminationDay")
                                    } : null
                                ].filter(o => o)}
                                       saveAsExcell={"curve"}/>
                            </div>
                            <TableGrid data={curvePreview || []} headers={headers}/>
                        </Col>
                    </Row>
                </FormForSettings>
            </Card>
        );
    }
}


const submit = (values, dispatch, props) => {
    const {curvePreview, data: {days, stages, curveType, curveName, repetitionDay, repetitionType}, defaultValue, index, gateways} = _.cloneDeep(props);
    let curveDays = days.map((day, idx) => {
        return {
            ForageAmount: day.amount,
            DailyPlan: day.plan,
            ForageType: day.forage,
            Notify: !!_.get(curvePreview, `[${idx}].notify`)//deprecated //jednak nie xd
        }
    });
    let curveStages = stages.map((stage, idx) => {
        return {
            StartDay: stage.start,
            Name: stage.name
        }
    });
    const InseminationJumpTo = repetitionType === CurveDayShowingType.BOTH ? repetitionDay : (repetitionType === CurveDayShowingType.AFTER ? 255 : 0);
    const ParturitionJumpTo = 0;
    let setting = Setting.createSetting(SettingTypes.FEEDING_CURVE, {
        InseminationJumpTo,
        ParturitionJumpTo,
        Days: curveDays,
        Index: index,
        Stages: curveStages || [],
        Type: curveType,
        Name: curveName
    });
    if (defaultValue) {
        setting.SetID = defaultValue.SetID;
        setting.DtaModTime = +new Date();
    }
    for (let i = 0; i < gateways.length; i++) {
        setNutritionCurve(gateways[i], setting, i === 0);
    }
    props.history.push(`/${props.farm}/settings/feeding/curves`);
};

const validate = (values, props) => {
    const errors = {};
    return errors
};


PreviewAndSave.propTypes = {
    data: PropTypes.shape({
        curveName: PropTypes.string.isRequired,
        curveType: PropTypes.string.isRequired,
        daysNumber: PropTypes.number.isRequired,
        days: PropTypes.array.isRequired

    }),
    defaultValue: PropTypes.object
};


PreviewAndSave = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(PreviewAndSave);
const selector = formValueSelector(FormName);
PreviewAndSave = connect(state => ({
    updating: state.settings.updating,
    adding: state.settings.adding,
    curvePreview: selector(state, "curvePreview"),
    stages: selector(state, "stages"),
    gateways: state.farmDevices.devices.filter(dev => dev.DevType === DevType.GATEWAY),
    farm: state.location.farm
}))(PreviewAndSave);
PreviewAndSave = withTranslation()(PreviewAndSave);
export const _PreviewAndSave = PreviewAndSave;
export default withRouter(PreviewAndSave);
