import React, {Component} from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import _ from "lodash";
import List from "../../../../../components/basics/list/List";
import Card from "../../../../../components/basics/card/Card";
import withRoles from "../../../../../components/withRoles";
import {LicPackageKeys, LicPackageLevel, Roles} from "../../../../../constans/roles";
import FeedingForageItem from "./FeedingForageItem";
import {getFeedingDataGroupedByChamberIDs} from "../../../../../utils/FeedingUtils";
import {Col, Collapse, Row} from "react-bootstrap";
import ForageReactivate from "../../../../../components/modals-new/forage-reactivate/ForageReactivate";
import ListItemFullWidthRow from "../../../../../components/list-components/ListItemFullWidthRow";
import {getFeedingForages} from "../../../../../selectors/feedingSelector";
import {getLicenseByName} from "../../../../../selectors/roleSelector";
import {LicenseItemCounts} from "../../../../../constans/licenseCounts";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

@connect((state) => {
    return {
        feedingForages: getFeedingForages(state),
        feedingForagesHistory: state.settings.forageHistory,
        buildings: state.farms.buildings,
        license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER})
    };
})
class FeedingPlansView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            chambers: this.getChambers(this.props),
            showHistory: false
        }
    }

    onShowHistoryClick = () => {
        this.setState((state) => ({showHistory: !state.showHistory}))
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(nextProps.buildings, this.props.buildings)) {
            this.setState({
                chambers: this.getChambers(nextProps)
            })
        }
    }

    getChambers(props) {
        const {buildings} = props;
        return getFeedingDataGroupedByChamberIDs(buildings).map(o => o.chamber);
    }

    render() {
        const {feedingForages, feedingForagesHistory = [], t, license} = this.props;
        const {chambers, showHistory} = this.state;
        let foragesWithEmptySpaces = [];
        for (let i = 0; i < 8; i++) {
            foragesWithEmptySpaces[i] = {forage: feedingForages.find(forage => forage.SetData.Index === i), index: i};
        }
        feedingForagesHistory.sort((o1, o2) => o2.DtaDelTime - o1.DtaDelTime);
        return (
            <>
                <Card className={"feeding-view-container"}>
                    <Row>
                        <Col xs={12}>
                            <h5>{t("newSettings.feeding.forages.feedingForageItem.activeForages")}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <List useFlexLayout={false} striped>
                            <ListItemFullWidthRow text={i18next.t("newSettings.feeding.forages.feedingForageItem.compatibility", {type: "F1, F3, F5"})}
                                                  key={"comp_WST"}/>
                            {
                                foragesWithEmptySpaces.slice(0, LicenseItemCounts.FEEDING_FORAGE_BASIC).map((forage, index) => (
                                    <FeedingForageItem key={`forage_${forage.index}`}{...forage} chambers={chambers}/>
                                ))
                            }
                            {
                                (license === LicPackageLevel.EXTENDED) &&
                                <>
                                    <ListItemFullWidthRow text={i18next.t("newSettings.feeding.forages.feedingForageItem.compatibility", {type: "F3, F5"})}
                                                          key={"comp_NRF"}/>
                                    {
                                        foragesWithEmptySpaces.slice(LicenseItemCounts.FEEDING_FORAGE_BASIC, LicenseItemCounts.FEEDING_FORAGE_EXTENDED).map((forage, index) => (
                                            <FeedingForageItem key={`forage_${forage.index}`}{...forage}
                                                               chambers={chambers}/>
                                        ))
                                    }
                                </>
                            }

                        </List>
                    </Row>
                </Card>
                {
                    !!feedingForagesHistory.length &&
                    <Card className={"feeding-view-container"}>
                        <Row onClick={this.onShowHistoryClick}>
                            <Col xs={12}>
                                <h5 className="justify-content-between align-items-center">
                                    {t("newSettings.feeding.forages.feedingForageItem.historicalForages")}
                                    <i className={showHistory ? "fas fa-arrow-up" : "fas fa-arrow-down"}/>
                                </h5>
                            </Col>
                        </Row>
                        <Collapse in={showHistory}>
                            <Row>
                                <List useFlexLayout={false} striped>
                                    {
                                        feedingForagesHistory.map((forage, index) => (
                                            <FeedingForageItem key={forage.SetID} forage={forage} index={index}
                                                               isHistorical={true} chambers={chambers}/>
                                        ))
                                    }
                                </List>
                            </Row>
                        </Collapse>
                    </Card>
                }

                <ForageReactivate foragesWithEmptySpaces={foragesWithEmptySpaces}/>
            </>
        )
    }
}

export const _FeedingPlansView = FeedingPlansView;

FeedingPlansView = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(FeedingPlansView);

FeedingPlansView = withTranslation()(FeedingPlansView);

export default withRoles({roles: [Roles._DEVICE_CONFIG], showComponent: true})(FeedingPlansView);
