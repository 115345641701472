import {DevType, FrameTypes} from "../../../../../../constans/devices";
import {updateDeviceDynamoDB} from "../../../../../../actions/devicesActions";
import _ from "lodash";
import {FORMAT} from "../../../../../../constans/formatTypes";
import {createDeviceAddressList} from "../../../../../../utils/DevicesUtils";
import devicesDB from "../../../../../../database/devicesDB";
import i18next from "i18next";
import {
    createDevices,
    createDevicesNotificationFailure,
    createDevicesNotificationSuccess
} from "../../../../../../api/devices/createDevices";

export function submit(values, dispatch, props) {
    const {address, interf, name, bridge, edit, device, marker1, marker2, thermoeye, alias} = values;
    if (!edit) {
        let settings = {Marker: []};
        if (thermoeye) {
            settings.ThermoeyeID = thermoeye
        }
        if (marker1) settings.Marker[0] = {Color: "#FFFFFF", Amount: 0};
        if (marker2) settings.Marker[1] = {Color: "#FFFFFF", Amount: 0};
        const devices = createDeviceAddressList(address, DevType.CAGE).map(adr => ({
            DevType: DevType.CAGE,
            Name: name,
            Address: adr,
            ParentID: bridge.DevID,
            Interface: +interf,
            Alias: alias,
            AdditionalData: {
                Settings: settings
            }
        }));
        return createDevices(devices).then(res => {
            props.reset();
            createDevicesNotificationSuccess(res);
        }).catch(e => {
            createDevicesNotificationFailure(e);
        });
    } else {
        let deviceClone = device.clone();
        if (!deviceClone.Settings) deviceClone.Settings = [];
        const setMarker = (active, index) => {
            if (!deviceClone.Settings.Marker || !_.isArray(deviceClone.Settings.Marker)) deviceClone.Settings.Marker = [];
            if (active) {
                if (!deviceClone.Settings.Marker[index]) {
                    deviceClone.Settings.Marker[index] = {Color: "#FFFFFF", Amount: 0};
                }
            } else {
                delete deviceClone.Settings.Marker[index];
            }
        };
        setMarker(marker1, 0);
        setMarker(marker2, 1);
        if (thermoeye) {
            deviceClone.Settings.ThermoeyeID = thermoeye;
        } else {
            delete deviceClone.Settings.ThermoeyeID;
        }
        deviceClone.Address = Number(address);
        deviceClone.Name = name;
        deviceClone.ParentID = bridge.DevID;
        deviceClone.Interface = +interf;
        deviceClone.Alias = alias;
        deviceClone.Protocol = FrameTypes.WST;
        deviceClone.VerHard = "0.1";
        deviceClone.VerSoft = "0.1";
        dispatch(updateDeviceDynamoDB(deviceClone, deviceClone.FarmID, props.user.ClientID, props.user.LocalUserID, null, () => {
            props.history.push(`/${props.farm}/settings/devices/manage`)
        }));
    }
}

export function validate(values, props) {
    const errors = {};

    if (!values.name) {
        errors.name = props.t("required");
    }
    if (!(FORMAT.DEVICE_MANY_ADDR_CA.test(`${values.address}`) && (!values.edit || FORMAT.NUMBER_DEC_OR_HEX.test(`${values.address}`)))) {
        errors.address = i18next.t("errors.addressError", {
            amount1: `${1} (0x1)`,
            amount2: `127 (0x${(127).toString(16).toUpperCase()})`,
            amount3: '125'
        });
    } else {
        const {user: {LicPackages = []}, farm} = props;
        let licPackage = LicPackages.find(item => item.FarmID === farm);
        if (licPackage) {
            let addressList = createDeviceAddressList(values.address, DevType.CAGE);
            if (addressList.length > licPackage.DevCagesLeft) errors.address = i18next.t("errors.maxDevices", {amount: licPackage.DevCagesLeft});
        }
    }
    if (!values.bridge) {
        errors.bridge = props.t("required");
    } else {
        if (values.address && !errors.address && values.interf) {
            let addressList = createDeviceAddressList(values.address, DevType.CAGE);
            let takenAddresses = [];
            for (let address of addressList) {
                let devicesWithAddress = devicesDB.getDevicesWithAddress(props.farm, address);
                devicesWithAddress = devicesWithAddress.filter(item => item.ParentID === values.bridge.DevID && item.Interface === values.interf);
                if (values.edit) {
                    devicesWithAddress = devicesWithAddress.filter(item => values.device.DevID !== item.DevID);
                }
                if (devicesWithAddress.length > 0) takenAddresses.push(address);
            }
            if (takenAddresses.length > 0) {
                errors.address = i18next.t("errors.takenAddress", {type: takenAddresses.join(", ")});
            }
        }
    }
    if (!values.interf) {
        errors.interf = props.t("required");
    }

    return errors;
}