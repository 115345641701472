import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import PropTypes from "prop-types";
import {setConfiguration} from "../../../../../IOT/device-functions/DispenserDriverFunctions";
import _ from "lodash";
import {connect} from "react-redux";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import {convertWeightUnitTo} from "../../../../../utils/UnitUtils";
import Switch from "../../../../../components/basics/switch/Switch";
import DeviceClock from "../../../../../components/devices/clock/DeviceClock";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import {getFeedingUnit} from "../../../../../utils/SettingsUtils";
import {DispenserDriverCommandTypes} from "../../../../../constans/mqttMessages";
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {checkIfUserIsService} from "../../../../../utils/NewRolesUtils";
import {removeTrash} from "../../../../../utils/IOTUtils";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class Feeding extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getFeedingValues();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState(this.getFeedingValues(nextProps))
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    /*
    takie cos zwracane jest w shadowie
     configuration:
        autoFinish: false
        globalLock: false
        maxCurrentFluctuation: 0
        maxOverfluctuationTime: 0
        maxSingularDose: 500
        overloadDetection:
     */
    getFeedingValues = (props = this.props) => {
        const {dispensers, shadows} = props;
        let device = dispensers[0];
        try {
            let shadow = shadows.get(device.DevID);
            return {
                maxSingularDose: shadow.configuration.maxSingularDose || 50,
                timeBetweenDoses: shadow.configuration.timeBetweenDoses || 1000,
                autoFinish: shadow.configuration.autoFinish || false,
                globalLock: shadow.configuration.globalLock || false,
                maxCurrentFluctuation: shadow.configuration.maxCurrentFluctuation || 0,
                maxOverfluctuationTime: shadow.configuration.maxCurrentFluctuation || 0,
                overloadDetection: shadow.configuration.overloadDetection || false,
                loadedDataFrom: LoadedDataTypes.SHADOW,
                SetTime: shadow.metadata.configuration,
                device
            }
        } catch (e) {
            return {
                maxSingularDose: _.get(device, `Settings.Configuration.${DispenserDriverCommandTypes.SET_CONFIGURATION}.maxSingularDose`, 50),
                timeBetweenDoses: _.get(device, `Settings.Configuration.${DispenserDriverCommandTypes.SET_CONFIGURATION}.timeBetweenDoses`, 1000),
                autoFinish: _.get(device, `Settings.Configuration.${DispenserDriverCommandTypes.SET_CONFIGURATION}.autoFinish`, false),
                globalLock: _.get(device, `Settings.Configuration.${DispenserDriverCommandTypes.SET_CONFIGURATION}.globalLock`, false),
                maxCurrentFluctuation: _.get(device, `Settings.Configuration.${DispenserDriverCommandTypes.SET_CONFIGURATION}.maxCurrentFluctuation`, 0),
                maxOverfluctuationTime: _.get(device, `Settings.Configuration.${DispenserDriverCommandTypes.SET_CONFIGURATION}.maxOverfluctuationTime`, 0),
                overloadDetection: _.get(device, `Settings.Configuration.${DispenserDriverCommandTypes.SET_CONFIGURATION}.overloadDetection`, false),
                SetTime: _.get(device, `Settings.Configuration.${DispenserDriverCommandTypes.SET_CONFIGURATION}.SetTime`, 0),
                loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[DispenserDriverCommandTypes.SET_CONFIGURATION] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
                device
            };
        }
    };


    onClick = () => {
        const {dispensers} = this.props;
        const data = removeTrash(this.state);
        //todo: dorobic wysylanie na wiele urzadzen jak backend bedzie to umial robic
        dispensers.forEach(device => {
            setConfiguration(device, data)
        })
    };

    setValue = (key, value) => {
        this.setState({
            [key]: value,
            changed: true
        })
    };

    render() {
        const {t, dispensers} = this.props;
        const {
            maxSingularDose, timeBetweenDoses, globalLock, maxCurrentFluctuation, maxOverfluctuationTime,
            overloadDetection, loadedDataFrom, SetTime, device
        } = this.state;
        let unit = getFeedingUnit();
        return (
            <DeviceSettingsCard title={t("newSettings.dispenserWST.feeding.title")} onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[DispenserDriverCommandTypes.SET_CONFIGURATION].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <DeviceClock deviceId={dispensers[0].DevID} variablePath={"rtc.dateTime"} metaPath={"metadata.rtc"}
                             renderComponent={time => (
                                 <LabeledInput label={t("newSettings.dispenserWST.feeding.time")} type={"text"}
                                               value={time ? time.format("HH:mm:ss") : "--:--:--"} disabled/>
                             )}/>
                <LabeledSlider value={maxSingularDose} label={t("newSettings.dispenserWST.feeding.maxSingleDose")}
                               min={50} max={1000} step={50}
                               valueFormatter={(value) => convertWeightUnitTo(value, {
                                   showUnit: true,
                                   unit: unit,
                                   fixed: unit ? 2 : 0
                               })}
                               onChange={(value) => this.setValue("maxSingularDose", value)}
                />
                <LabeledSlider value={timeBetweenDoses} label={t("newSettings.dispenserWST.feeding.timeBetweenDoses")}
                               min={1} max={255} step={1}
                               valueFormatter={(value) => `${value}s`}
                               onChange={(value) => this.setValue("timeBetweenDoses", value)}
                />
                <Switch id={"globalLock"} value={globalLock} label={t("newSettings.dispenserWST.feeding.globalLock")}
                        onChange={(value) => this.setValue("globalLock", value)}
                />
                {
                    checkIfUserIsService() &&
                    <>
                        <Switch id={"overloadDetection"} value={overloadDetection}
                                label={t("newSettings.dispenserWST.feeding.overloadDetection")}
                                onChange={(value) => this.setValue("overloadDetection", value)}
                        />
                        <LabeledSlider disabled={!overloadDetection} value={maxCurrentFluctuation}
                                       label={t("newSettings.dispenserWST.feeding.maxCurrentFluctuation")} min={0}
                                       max={2550}
                                       step={10}
                                       valueFormatter={(value) => `${value}mA`}
                                       onChange={(value) => this.setValue("maxCurrentFluctuation", value)}
                        />
                        <LabeledSlider disabled={!overloadDetection} value={maxOverfluctuationTime}
                                       label={t("newSettings.dispenserWST.feeding.maxOverfluctuationTime")} min={0}
                                       max={2550}
                                       step={10}
                                       valueFormatter={(value) => `${value}ms`}
                                       onChange={(value) => this.setValue("maxOverfluctuationTime", value)}
                        />
                    </>
                }
            </DeviceSettingsCard>
        );
    }

}

Feeding.propTypes = {
    dispensers: PropTypes.array.isRequired
};

Feeding = connect(state => ({
    shadows: state.shadows.shadows,
}))(Feeding);

export default withTranslation()(Feeding);