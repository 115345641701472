import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import PropTypes from "prop-types";
import _ from "lodash";
import {connect} from "react-redux";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType, LoadedDataTypes} from "../../../../../constans/devices";
import {setPunishmentSetup} from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import {DispenserNRFCommandTypes} from "../../../../../constans/mqttMessages";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {removeTrash} from "../../../../../utils/IOTUtils";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class PunishmentSetup extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getPunishmentSetup();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState(this.getPunishmentSetup(nextProps))
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    getPunishmentSetup = (props = this.props) => {
        const {dispensers, shadows} = props;
        let device = dispensers[0];
        let values = {};
        let tmp = [];
        try {
            tmp = shadows.get(device.DevID).punishment;
            values.loadedDataFrom = tmp ? LoadedDataTypes.SHADOW : LoadedDataTypes.NO_DATA;
            values.SetTime = _.get(tmp, 'shadow.metadata.punishment', 0);
        } catch (e) {
            tmp = _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_MINUS_CONFIG}`);
            values.loadedDataFrom = tmp ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA;
            values.SetTime = _.get(tmp, 'SetTime', 0);
        }
        values.punishment = [];
        values.device = device;
        for (let i = 0; i < 3; i++) {
            values.punishment[i] = {
                percentage: _.get(tmp, `[${i}].percentage`, 10),
                time: _.get(tmp, `[${i}].time`, 5),
                trigger: _.get(tmp, `[${i}].trigger`, 1),
            }
        }
        return values;
    };


    onClick = () => {
        const {dispensers} = this.props;
        const devices = groupDevicesByGatewayID(dispensers);
        if (devices) {
            const data = removeTrash(this.state.punishment);
            for (let devList of devices.values()) {
                devList[DevType.BROADCAST].length && setPunishmentSetup(devList[DevType.BROADCAST], data);
                devList[DevType.DISPENSER_NRF_MULTI].length && setPunishmentSetup(devList[DevType.DISPENSER_NRF_MULTI], data);
                devList[DevType.DISPENSER_NRF].length && setPunishmentSetup(devList[DevType.DISPENSER_NRF], data);
            }
        }
    }
    ;

    setValue = (key, value) => {
        let tmp = _.cloneDeep(this.state);
        _.set(tmp, key, value);
        this.setState({
            ...tmp,
            changed: true
        })
    };

    renderStage = (index) => (
        <>
            <label>
                {i18next.t("punishment", {type: index + 1})}
            </label>
            <LabeledSlider
                value={this.state.punishment[index].time}
                label={this.props.t("newSettings.dispenserNRF.punishmentSetup.time")}
                min={0}
                max={15}
                step={1}
                valueFormatter={(value) => `${value}m`}
                onChange={(value) => this.setValue(`punishment[${index}].time`, value)}
            />
            <LabeledSlider
                value={this.state.punishment[index].percentage}
                label={this.props.t("newSettings.dispenserNRF.punishmentSetup.percentage")}
                min={10}
                max={100}
                step={5}
                valueFormatter={(value) => `${value}%`}
                onChange={(value) => this.setValue(`punishment[${index}].percentage`, value)}
            />
            <LabeledSlider
                value={this.state.punishment[index].trigger}
                label={this.props.t("newSettings.dispenserNRF.punishmentSetup.trigger")}
                min={1}
                max={15}
                step={1}
                valueFormatter={(value) => `${value}`}
                onChange={(value) => this.setValue(`punishment[${index}].trigger`, value)}
            />
            {/*<hr/>*/}
        </>
    );

    render() {
        const {t} = this.props;
        const {loadedDataFrom, SetTime, device} = this.state;
        return (
            <DeviceSettingsCard title={t("punishmentSetup")}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[DispenserNRFCommandTypes.SET_MOTOR_SETUP].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                {this.renderStage(0)}
                {this.renderStage(1)}
                {this.renderStage(2)}
            </DeviceSettingsCard>
        );
    }

}

PunishmentSetup.propTypes = {
    dispensers: PropTypes.array.isRequired
};

PunishmentSetup = connect(state => ({
    shadows: state.shadows.shadows,
}))(PunishmentSetup);

export default withTranslation()(PunishmentSetup);