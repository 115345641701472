import devicesDB from "../../../../../database/devicesDB";
import {DevType} from "../../../../../constans/devices";
import {updateDeviceDynamoDB} from "../../../../../actions/devicesActions";
import settingsDB from "../../../../../database/settingsDB";
import _ from "lodash";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import Setting from "../../../../../beans/Setting";
import {SettingTypes} from "../../../../../constans/settingTypes";
import {setPunishmentSetup} from "../../../../../IOT/device-functions/DispenserNRFFunctions";

export function submit(values, dispatch, props) {
    //ustawianie na gejtweju
    const {ParturitionFeedingBreakTime} = values;
    const {farm, user} = props;
    let gateways = devicesDB.getDevices(farm).filter(item => item.DevType === DevType.GATEWAY);
    let gws = gateways.map(gw => {
        let device = gw.clone();
        device.Settings.ParturitionFeedingBreakTime = ParturitionFeedingBreakTime;
        return device.prepareBeanToSave();
    });
    const dataPunishment = values.Punishment.map(p => ({
        time: p.Time,
        percentage: p.Percentage,
        trigger: p.Trigger
    }))
    if (gws.length > 0) {
        dispatch(updateDeviceDynamoDB(gws, farm, user.ClientID, user.LocalUserID));
        for (let gateway of gws) {
            setPunishmentSetup(gateway.DevID, dataPunishment);
        }
    }

    //ustawianie na settingsach na dynamo
    const {ClientID} = user;
    let general = settingsDB.getSettingByID(ClientID);
    let data = {
        WST: values.WST,
        NRF: values.NRF,
        Punishment: values.Punishment,
        DefaultCurves: values.DefaultCurves
    };
    if (general) {
        let clone = _.cloneDeep(general);
        if (!clone.SetData) {
            clone.SetData = {Settings: {}};
        } else {
            if (!clone.SetData.Settings) {
                clone.SetData = {...clone.SetData, Settings: {}}
            }
        }
        clone.SetData.Settings.Feeding = data;
        clone.DtaModTime = +new Date();
        dispatch(updateSettingDynamoDB(clone));
    } else {
        let setting = Setting.createSetting(SettingTypes.GENERAL, {Settings: {Feeding: data}});
        dispatch(createSettingDynamoDB(setting));
    }
}
