import React from "react";
import {connect} from 'react-redux'
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import {FilterTypes} from "../../../../constans/filter";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import _ from "lodash";
import Card from "../../../../components/basics/card/Card";
import {getDevices, updateDeviceDynamoDB} from "../../../../actions/devicesActions";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {getLocationName} from "../../../../utils/BuildingUtils";
import EditComponent from "./manage/EditComponent";
import {DevType} from "../../../../constans/devices";
import devicesDB from "../../../../database/devicesDB";
import {initialize} from "redux-form";
import ConnectionComponent from "./manage/ConnectionComponent";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import {Roles} from "../../../../constans/roles";
import withRoles from "../../../../components/withRoles";
import {getScaleUnit} from "../../../../utils/SettingsUtils";
import {getInterfName} from "../../../../utils/DevicesUtils";
import {withTranslation} from "react-i18next";
import Menu from "../../../../components/basics/menu/Menu";
import MenuItem from "../../../../components/basics/menu/MenuItem";
import {
    addNecessaryDevices,
    addNecessaryDevicesNotificationFailure,
    addNecessaryDevicesNotificationSuccess
} from "../../../../api/devices/addNecessaryDevices";
import DefaultMobileRow from "../../../../components/basics/table-grid/default-mobile-row/DefaultMobileRow";

export class ManageDevicesView extends React.Component {

    constructor(props) {
        super(props);
        this.onSelectedRow = this.onSelectedRow.bind(this);
        this.addressFormatter = this.addressFormatter.bind(this);
        this.state = {
            selectedDevices: [],
            menuEvent: null
        };
    }

    onSelectedRow(selectedRows) {
        this.setState({
            selectedDevices: selectedRows
        })
    }

    componentDidMount() {
        this.props.dispatch(getDevices(this.props.farm));
    }

    plcmntIDValueFormatter = (value) => {
        let locationObj = value.getLocation();
        return locationObj.map(loc => loc ? loc.BoxesName || loc.CName || loc.SName || loc.BName : []).join(", ")
    };

    addressFormatter = (value) => {
        return !_.isNil(value) ? `${value} / 0x${value.toString(16).toUpperCase()}` : ''
    };

    onAddButtonClick = () => {
        this.props.history.push(`/${this.props.farm}/settings/devices/add`);
    };

    getChildrenDevices(devices) {
        let devs = [];
        for (let dev of devices) {
            if (dev) {
                let childrenDevices = devicesDB.getDevicesWithParentID(dev.DevID);
                devs = [...devs, ...childrenDevices]; //...this.getChildrenDevices(childrenDevices)
            }
        }
        return devs;
    }

    onRemoveClick = () => {
        let childrenDevices = this.getChildrenDevices(this.state.selectedDevices);
        for (let dev of this.state.selectedDevices) {
            childrenDevices = childrenDevices.filter(item => dev.DevID !== item.DevID);
        }
        let children = (
            <div>
                <ul>
                    {
                        this.state.selectedDevices.map((dev, index) => {
                            let loc = dev.getLocation();
                            let locTexts = loc.map(l => {
                                return getLocationName(l);
                            }).filter(item => item).join(", ");
                            return (<li key={index}>{dev.Name} {locTexts ? `(${locTexts})` : ""}</li>);
                        })
                    }
                </ul>
                {
                    childrenDevices.length > 0 &&
                    <>
                        {this.props.t("newSettings.devices.manage.removeDevicesAdditional")}
                        <ul>
                            {
                                childrenDevices.map((dev, index) => {
                                    let loc = dev.getLocation();
                                    let locTexts = loc.map(l => {
                                        return getLocationName(l);
                                    }).filter(item => item).join(", ");
                                    return (<li key={index}>{dev.Name} {locTexts ? `(${locTexts})` : ""}</li>);
                                })
                            }
                        </ul>
                    </>
                }
            </div>
        );
        this.props.show("confirm-modal", {
            title: this.props.t("newSettings.devices.manage.removeDevices"),
            text: this.props.t("newSettings.devices.manage.removeDevicesText"),
            confirmText: this.props.t("yes"),
            children: children,
            onConfirmed: (props) => {
                let array = _.cloneDeep([...this.state.selectedDevices, ...childrenDevices]);
                for (let dev of array) {
                    dev.removeDevice();
                    dev.prepareBeanToSave();
                }
                this.props.dispatch(updateDeviceDynamoDB(array, this.props.farm, this.props.user.ClientID, this.props.user.LocalUserID));
                this.setState({
                    selectedDevices: []
                })
                props.handleHide();
            }
        })
    };

    onEditClick = device => {
        let path = `/${this.props.farm}/settings/devices/add/`;
        let devType = "";
        switch (device.DevType) {
            case DevType.BRIDGE:
                devType = "bridge";
                break;
            case DevType.CAGE:
                devType = "cage";
                break;
            case DevType.DISPENSER:
                devType = "dispenserWST";
                break;
            case DevType.DISPENSER_NRF:
                devType = "dispenserNRF";
                break;
            case DevType.SCALE:
                devType = "weightWST";
                break;
            case DevType.CLIMATE:
                devType = "climateWST";
                break;
            case DevType.CLIMATE_SK3:
                devType = "climateSK3";
                break;
            case DevType.THERMOEYE:
                devType = "thermoEye";
                break;
            case DevType.BRIDGE_WORK:
                devType = "bridgeWork";
                break;
            case DevType.BRIDGE_CONF:
                devType = "bridgeConf";
                break;
            case DevType.BROADCAST:
                devType = "broadcast";
                break;
            case DevType.DISPENSER_NRF_MULTI:
                devType = "dispenserNRFMulti";
                break;
            case DevType.ANTENNA_RFID:
                devType = "antennaRFID";
                break;
            case DevType.WATER_FLOW_METER:
                devType = "waterMeter";
                break;
            case DevType.ELECTRICITY_FLOW_METER:
                devType = "electricityMeter";
                break;
            case DevType.CHAIN_FEEDING:
                devType = "chainFeeding";
                break;
            case DevType.SMALL_CAGE:
                devType = "pigletScaleEdit";
                break;
            default:
        }
        let obj = {
            name: device.Name,
            address: device.Address,
            edit: true,
            alias: device.Alias,
            Number: device.Number,
            device
        };
        let unitScale = getScaleUnit();

        if (device.DevType === DevType.BRIDGE) {
            let gateway = devicesDB.getDeviceByID(device.ParentID) || null;
            obj = {
                ...obj,
                gateway,
                Rs0Protocol: device.Interfaces[3],
                Rs1Protocol: device.Interfaces[4],
                BridgePeriphery: device.Settings.BridgePeriphery || "None"
            }
        } else {
            let bridge = devicesDB.getDeviceByID(device.ParentID) || null;
            obj = {
                ...obj,
                bridge,
                interf: device.Interface
            };
            if (device.DevType === DevType.DISPENSER) {
                obj = {
                    ...obj,
                    dispensers: device.Dispensers
                }
            } else if (device.DevType === DevType.SCALE) {
                obj = {
                    ...obj,
                    siloses: (device.Siloses || new Array(8).fill(1)).map((o, index) => ({
                        Adr: index,
                        Active: !!o.Active,
                        Capacity: !!o.Capacity ? convertWeightUnitTo(o.Capacity, {
                            fixed: unitScale ? 2 : 0,
                            unit: unitScale,
                            rawValue: true
                        }) : 0,
                        Alias: o.Alias || null,
                        Forage: o.Forage || null,
                        Number: o.Number || null
                    }))
                }
            } else if (device.DevType === DevType.CAGE) {
                obj = {
                    ...obj,
                    thermoeye: _.get(device, 'Settings.ThermoeyeID'),
                    marker1: !!_.get(device, 'Settings.Marker[0]'),
                    marker2: !!_.get(device, 'Settings.Marker[1]'),
                }
            } else if (device.DevType === DevType.CLIMATE_SK3) {
                obj = {
                    ...obj,
                    matsNumber: device.getMatsSize()
                }
            } else if (device.DevType === DevType.DISPENSER_NRF || device.DevType === DevType.DISPENSER_NRF_MULTI) {
                obj = {
                    ...obj,
                    address: `0x${(+device.Address || 0).toString(16).toLowerCase()}`,
                    temperature: device.DevType === DevType.DISPENSER_NRF ? device.hasTemperatureSensor() : false,
                    water: device.DevType === DevType.DISPENSER_NRF ? device.hasWater() : false
                }
                if (obj.water) {
                    obj.flowMeter = device.hasFlowMeter()
                }
            } else if ([DevType.ELECTRICITY_FLOW_METER, DevType.WATER_FLOW_METER].includes(device.DevType)) {
                obj = {
                    ...obj,
                    number: _.get(device, 'Number'),
                    serial: _.get(device, 'Serial'),
                };
                if (DevType.ELECTRICITY_FLOW_METER) {
                    obj.phases = _.get(device, "Settings.Phases", 1);
                }
            } else if (DevType.CHAIN_FEEDING === device.DevType) {
                const aliases = [];
                for (let i = 0; i < 4; i++) {
                    aliases.push({
                        name: _.get(device, `Settings.Aliases[${i}]`, "")
                    })
                }
                obj = {
                    ...obj,
                    aliases: aliases,
                    useVoltage: !!_.get(device, "Settings.UseVoltage"),
                    address: `0x${(+device.Address || 0).toString(16).toLowerCase()}`
                }
            }
        }
        this.props.dispatch(initialize(devType, obj));
        this.props.history.push(path + devType);
    };

    interfaceValueFormatter = value => {
        let name = getInterfName(value);
        if (name) return `${name} (${value})`;
    };

    onMoreClick = e => {
        this.setState({
            menuEvent: e
        })
    }

    onFixClick = () => {
        const {t} = this.props;
        this.props.show("confirm-modal", {
            title: t("apiNotifications.addNecessaryDevicesTitle"),
            text: t("fixDevicesText"),
            confirmText: t("yes"),
            onConfirmed: (props) => {
                addNecessaryDevices().then(res => {
                    addNecessaryDevicesNotificationSuccess(res);
                }).catch(e => {
                    addNecessaryDevicesNotificationFailure(e);
                });
                props.handleHide();
            }
        });
    }

    render() {
        const {devices, t} = this.props;
        const headers = [
            {
                name: t("newSettings.devices.manage.type"),
                field: "DevType",
                filterType: FilterTypes.STRING
            },
            {
                name: t("newSettings.devices.manage.name"),
                field: "Name",
                filterType: FilterTypes.STRING,
                _mobileHeader: true
            },
            {
                name: t("adres"),
                field: "Address",
                valueFormatter: this.addressFormatter,
                filterType: FilterTypes.DEV_ADDRESS
            },
            {
                name: t("newSettings.devices.manage.interface"),
                field: "Interface",
                filterType: FilterTypes.NUMBER,
                valueFormatter: this.interfaceValueFormatter
            },
            {
                name: t("location"),
                valueFormatter: this.plcmntIDValueFormatter,
                filterColumn: "location"
            },
            {
                name: t("newSettings.devices.manage.response"),
                component: <ConnectionComponent/>,
                _mobileHide: true
            },
            {
                name: "",
                component: <EditComponent onClick={this.onEditClick}/>,
                itemClassName: "checkbox",
                headerClassName: "selected-header",
                _floating: true
            }
        ];
        const {selectedDevices, menuEvent} = this.state;
        return (
            <Card className={"settings-profile-general"}>
                <TableGrid headers={headers}
                           swipePagination={true}
                           data={devices.sort((a, b) => a.DevType > b.DevType ? 1 : a.DevType === b.DevType ? 0 : -1 || b.Address - a.Address)}
                           selectableRow={true}
                           mobileRow={<DefaultMobileRow/>}
                           onSelectedRowsChanged={this.onSelectedRow} showFilter isSortable showPagination
                           paginationItems={20} name={"manageDevices"}/>
                <ButtonGroup fixed>
                    <Button buttonColor={"info"} buttonStyle={"round"} icon={<i className="fas fa-ellipsis-v"/>}
                            onClick={this.onMoreClick}/>
                    <Button disabled={!selectedDevices.length} buttonColor={"error"} buttonStyle={"round"}
                            icon={<i className="fas fa-trash"/>} onClick={this.onRemoveClick}/>
                    <Button buttonColor={"success"} buttonStyle={"round"}
                            icon={<i className="fas fa-plus"/>} onClick={this.onAddButtonClick}/>
                </ButtonGroup>
                <Menu event={menuEvent}>
                    <MenuItem icon={<i className="fas fa-wrench"/>} onClick={this.onFixClick}>{t("addMissingDevices")}</MenuItem>
                </Menu>
            </Card>
        );
    }

}

export let _ManageDevicesView = connect(state => ({
    devices: state.farmDevices.devices,
    farm: state.location.farm,
}))(ManageDevicesView);

ManageDevicesView = withRouter(_ManageDevicesView);

ManageDevicesView = connect(
    state => ({
        devices: state.farmDevices.devices,
        farm: state.location.farm,
        user: state.user.user
    }),
    dispatch => {
        return {dispatch, ...bindActionCreators({show}, dispatch)}
    }
)(ManageDevicesView);

ManageDevicesView = withRoles({roles: [Roles._DEVICE_CONFIG], showComponent: true})(ManageDevicesView);

export default withTranslation()(ManageDevicesView);
