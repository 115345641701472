import React from "react";
import Card from "../../../../components/basics/card/Card";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {Col, Collapse, Row} from "react-bootstrap";
import ReduxLabeledSelect from "../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import ReduxLabeledInput from "../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {connect} from "react-redux";
import {DevType, FrameTypes} from "../../../../constans/devices";
import {submit, validate} from "./AddressDevicesViewSubmit"
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import InfoBox from "../../../../components/basics/info-box/InfoBox";
import _ from "lodash"
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class AddressDevicesView extends React.Component {

    constructor(props) {
        super(props);

        this.props.initialize({
            amount: 1
        })

        this.state = {
            startedAddressing: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.startedAddressing !== nextProps.startedAddressing) {
            if (nextProps.startedAddressing) {
                this.setState({
                    startedAddressing: true
                })
            }
        }

        if (!_.isEqual(this.props.addressing, nextProps.addressing)) {
            if (nextProps.addressing.howManyAdressed === nextProps.addressing.toAddress) {
                setTimeout(() => {
                    this.props.dispatch({
                        type: "CHANGE_ADDRESSING",
                        payload: {
                            state: false
                        }
                    });

                    this.setState({
                        startedAddressing: false
                    })
                }, 5000)
            }
        }
    }

    componentWillUnmount() {
        if (this.props.startedAddressing) {
            this.props.handleSubmit()
        }
    }

    render() {
        const {devices, handleSubmit, t, protocol, addressing} = this.props;
        const {startedAddressing} = this.state;
        return (
            <Card>
                <Collapse in={startedAddressing}>
                    <div>
                        <InfoBox boxColor={addressing.howManyAdressed === addressing.toAddress ? "success" : "info"}>
                            {
                                addressing.howManyAdressed !== addressing.toAddress &&
                                <>
                                    {
                                        protocol === FrameTypes.WST &&
                                        <div>
                                            {t("newSettings.devices.addressing.dispenser")}:
                                            <ol>
                                                <li>{t("newSettings.devices.addressing.f1_1")}</li>
                                                <li>{t("newSettings.devices.addressing.f1_2")}</li>
                                                <li>{t("newSettings.devices.addressing.f1_3")}</li>
                                            </ol>
                                            {t("newSettings.devices.addressing.scale")}:
                                            <ol>
                                                <li>{t("newSettings.devices.addressing.scale_1")}</li>
                                                <li>{t("newSettings.devices.addressing.scale_2")}</li>
                                                <li>{t("newSettings.devices.addressing.scale_3")}</li>
                                            </ol>
                                        </div>
                                    }
                                    {
                                        protocol === FrameTypes.NRF &&
                                        <ol>
                                            <li>{t("newSettings.devices.addressing.f3_1")}</li>
                                            <li>{t("newSettings.devices.addressing.f3_2")}</li>
                                            <li>{t("newSettings.devices.addressing.f3_3")}</li>
                                            <li>{t("newSettings.devices.addressing.f3_4")}</li>
                                        </ol>
                                    }
                                </>
                            }
                            <div>{i18next.t("newSettings.devices.addressing.addressed", {address: addressing.howManyAdressed})}</div>
                            <div>{i18next.t("newSettings.devices.addressing.toAddress", {address: addressing.toAddress - addressing.howManyAdressed})}</div>
                            {
                                !!addressing.howManyAdressed && addressing.howManyAdressed < addressing.toAddress &&
                                <div>{i18next.t("newSettings.devices.addressing.nextAddress", {address: addressing.address})}</div>
                            }
                        </InfoBox>
                    </div>
                </Collapse>
                <Row className="justify-content-center">
                    <Col lg={6}>
                        <form onSubmit={handleSubmit}>
                            <Field
                                component={ReduxLabeledSelect}
                                name="gateway"
                                label={t("newSettings.devices.addressing.gateway")}
                                options={devices.filter(item => item.DevType === DevType.GATEWAY).map(gw => ({
                                    name: gw.Name,
                                    value: gw
                                }))}
                                disabled={startedAddressing}
                            />
                            <Field
                                component={ReduxLabeledSelect}
                                name="protocol"
                                label={t("newSettings.devices.addressing.protocol")}
                                options={[
                                    {
                                        name: "WST",
                                        value: FrameTypes.WST
                                    },
                                    {
                                        name: "NRF",
                                        value: FrameTypes.NRF
                                    }
                                ]}
                                disabled={startedAddressing}
                            />
                            <Field
                                component={ReduxLabeledInput}
                                name="address"
                                label={t("adres")}
                                type="number"
                                parse={value => value ? +value : null}
                                disabled={startedAddressing}
                            />
                            <Field
                                component={ReduxLabeledInput}
                                name="amount"
                                label={t("amount")}
                                type="number"
                                parse={value => value ? +value : null}
                            />
                            <Field
                                component={ReduxLabeledSelect}
                                name="interf"
                                label={t("newSettings.devices.addressing.interf")}
                                options={protocol === FrameTypes.NRF ? [
                                    {
                                        name: t("newSettings.devices.addressing.left"),
                                        value: 0
                                    },
                                    {
                                        name: t("newSettings.devices.addressing.middle"),
                                        value: 1
                                    },
                                    {
                                        name: t("newSettings.devices.addressing.right"),
                                        value: 2
                                    }
                                ] : [
                                    {
                                        name: t("newSettings.devices.addressing.left"),
                                        value: 0
                                    },
                                    {
                                        name: t("newSettings.devices.addressing.right"),
                                        value: 1
                                    }
                                ]}
                                disabled={startedAddressing}
                            />
                            <Field
                                component={ReduxLabeledSelect}
                                name="mode"
                                label={t("newSettings.devices.addressing.mode")}
                                options={[
                                    {
                                        name: t("newSettings.devices.addressing.modeIgnore"),
                                        value: 0
                                    },
                                    {
                                        name: t("newSettings.devices.addressing.modeNoIgnore"),
                                        value: 1
                                    }
                                ]}
                                disabled={startedAddressing}
                            />
                            <ButtonGroup fixed renderInPortal={false}>
                                <Button buttonStyle="round" buttonColor="success"
                                        icon={<i className="fas fa-paper-plane"/>}/>
                            </ButtonGroup>
                        </form>
                    </Col>
                </Row>
            </Card>
        );
    }

}

AddressDevicesView = reduxForm({
    form: "address-devices",
    onSubmit: submit,
    validate
})(AddressDevicesView);

const selector = formValueSelector("address-devices");

AddressDevicesView = connect(state => ({
    devices: state.farmDevices.devices,
    startedAddressing: state.farmDevices.startedAddressing,
    addressing: state.farmDevices.addressing,
    protocol: selector(state, "protocol")
}))(AddressDevicesView);

export default withTranslation()(AddressDevicesView);