import React from "react";
import {connect} from 'react-redux'
import NotFound from "../../../../../components/NotFound";
import {withRouter} from "react-router-dom";
import {isFinite, isNil} from "lodash";
import EditDoses from "./EditDoses";
import withRoles from "../../../../../components/withRoles";
import {LicPackageKeys, LicPackageLevel, Roles} from "../../../../../constans/roles";
import {LicenseItemCounts} from "../../../../../constans/licenseCounts";
import {getLicenseByName} from "../../../../../selectors/roleSelector";
import {getFeedingSchedules} from "../../../../../selectors/feedingSelector";

@connect(state => ({
    farm: state.location.farm,
    license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER}),
    schedules: getFeedingSchedules(state)
}))
class EditPlans extends React.Component {

    render() {
        const {match: {params: {index}}, license, schedules} = this.props;
        let idx = isFinite(+index) ? +index : null;
        const plan = schedules.find(s => s.SetData.Index === idx);
        const maxNumber = license === LicPackageLevel.EXTENDED ? LicenseItemCounts.FEEDING_SCHEDULE_EXTENDED : LicenseItemCounts.FEEDING_SCHEDULE_BASIC;
        if ((isNil(idx) || idx < 0 || idx >= maxNumber)) {
            return (
                <NotFound/>
            )
        }
        return (
            <React.Fragment>
                <EditDoses defaultValue={plan} index={idx}/>
            </React.Fragment>
        );
    }
}

export const _EditPlans = EditPlans;

EditPlans = withRouter(EditPlans);
export default withRoles({roles: [Roles._DEVICE_CONFIG], showComponent: true})(EditPlans);
