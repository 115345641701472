import React from "react";
import Card from "../../../../../../components/basics/card/Card";
import FormForDevices from "../FormForDevices";
import {formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {submit, validate} from "./ThermoEyeSubmit";
import {withRouter} from "react-router-dom";
import withRoles from "../../../../../../components/withRoles";
import {Roles} from "../../../../../../constans/roles";

class ThermoEye extends React.Component {

    render() {
        const {handleSubmit, address, device, bridge, edit} = this.props;
        return (
            <Card>
                <FormForDevices handleSubmit={handleSubmit} showInterfacePicker device={device} address={address}
                                showAdditionalButton={device} bridge={bridge} showHelp={!edit}/>
            </Card>
        );
    }

}

ThermoEye = reduxForm({
    form: "thermoEye",
    onSubmit: submit,
    validate
})(ThermoEye);

ThermoEye = withRoles(props => {
    return {roles: [Roles._DEVICE_CONFIG], showComponent: true}
})(ThermoEye);

const selector = formValueSelector("thermoEye");
ThermoEye = connect(state => ({
    address: selector(state, "address"),
    device: selector(state, "device"),
    bridge: selector(state, "bridge"),
    edit: selector(state, "edit"),
    user: state.user.user,
    farm: state.location.farm
}))(ThermoEye);

export default withRouter(ThermoEye);

