import React from "react";
import FormForSettings from "../../../FormForSettings";
import {Field, FormSection, formValueSelector, reduxForm} from "redux-form";
import Card from "../../../../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import moment from "moment";
import {submit} from "./GeneralFeedingSettingsSubmit";
import {connect} from "react-redux";
import devicesDB from "../../../../../database/devicesDB";
import {DevType} from "../../../../../constans/devices";
import {
    getDefaultCurvesForSectors,
    getFeedingDefaultInseminationCurveDay,
    getFeedingDefaultInseminationCurveNumber,
    getFeedingDefaultParturitionCurveDay,
    getFeedingDefaultParturitionCurveNumber,
    getFeedingPunishmentSettings
} from "../../../../../utils/SettingsUtils";
import {get, memoize} from "lodash"
import {getCurveDayShowingType} from "../../../../../utils/FeedingUtils";
import {CurveDayShowingType, CurveType} from "../../../../../constans/feedingTypes";
import ReduxLabeledSelect from "../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {getFeedingCurves} from "../../../../../selectors/feedingSelector";

const MaxParturitionFeedingBreakTime = 24 * 60 * 60 * 1000;

export class GeneralFeedingSettings extends React.Component {

    getMaxDay = memoize((curves = [], number = 1) => {
        const curve = curves.find(c => c.SetData.Index === (number - 1));
        return get(curve, getCurveDayShowingType(curve) === CurveDayShowingType.BOTH ? "SetData.InseminationJumpTo" : "SetData.Days.length", 1);
    }, (...args) => JSON.stringify(args));

    componentDidMount() {
        const {farm} = this.props;
        let gateway = devicesDB.getDevices(farm).find(item => item.DevType === DevType.GATEWAY);
        const punishment = getFeedingPunishmentSettings();
        this.props.initialize({
            WST: {
                DefaultInseminationCurveDay: getFeedingDefaultInseminationCurveDay("WST"),
                DefaultInseminationCurveNumber: getFeedingDefaultInseminationCurveNumber("WST"),
                DefaultParturitionCurveDay: getFeedingDefaultParturitionCurveDay("WST"),
                DefaultParturitionCurveNumber: getFeedingDefaultParturitionCurveNumber("WST"),
            },
            NRF: {
                DefaultInseminationCurveDay: getFeedingDefaultInseminationCurveDay("NRF"),
                DefaultInseminationCurveNumber: getFeedingDefaultInseminationCurveNumber("NRF"),
                DefaultParturitionCurveDay: getFeedingDefaultParturitionCurveDay("NRF"),
                DefaultParturitionCurveNumber: getFeedingDefaultParturitionCurveNumber("NRF"),
            },
            Punishment: punishment,
            ParturitionFeedingBreakTime: get(gateway, "Settings.ParturitionFeedingBreakTime", 0),
            DefaultCurves: getDefaultCurvesForSectors()
        });
    }

    getOptions = (type = "WST") => {
        const {feedingCurves, t} = this.props;
        const getObj = (curve) => ({
            value: curve.SetData.Index + 1,
            name: i18next.t("newSettings.feeding.general.changeCurve", {type: curve.SetData.Name})
        });
        const options = feedingCurves.filter(c => (type !== "WST") || ((c.SetData.Index < 5) && (getCurveDayShowingType(c) !== CurveDayShowingType.BOTH)));
        const optionsInsemination = options.filter(c => [CurveType.INDIVIDUAL, CurveType.MATING].includes(c.SetData.Type)).map(getObj);
        const optionsParturition = options.filter(c => [CurveType.INDIVIDUAL, CurveType.PARTURITION].includes(c.SetData.Type)).map(getObj);
        optionsParturition.unshift({
            value: 0,
            name: t("newSettings.feeding.general.doNothing")
        });
        optionsInsemination.unshift({
            value: 0,
            name: t("newSettings.feeding.general.doNothing")
        });
        return {
            optionsParturition,
            optionsInsemination
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {change} = this.props;
        ["WST", "NRF"].forEach(key => {
            const {[key]: {defaultInseminationCurveNumber, defaultParturitionCurveNumber} = {}} = this.props;
            const {[key]: {defaultInseminationCurveNumber: prevDefaultInseminationCurveNumber, defaultParturitionCurveNumber: prevDefaultParturitionCurveNumber} = {}} = prevProps;
            if (defaultInseminationCurveNumber !== prevDefaultInseminationCurveNumber) {
                const {optionsInsemination} = this.getOptions(key);
                if (!optionsInsemination.find(o => o.value === defaultInseminationCurveNumber)) {
                    change(`${key}.DefaultInseminationCurveNumber`, 0)
                }

            }
            if (defaultParturitionCurveNumber !== prevDefaultParturitionCurveNumber) {
                const {optionsParturition} = this.getOptions(key);
                if (!optionsParturition.find(o => o.value === defaultParturitionCurveNumber)) {
                    change(`${key}.DefaultParturitionCurveNumber`, 0)
                }
            }
        })

    }

    renderPunishment = (index) => {
        const {t} = this.props;
        const prefix = `Punishment[${index}]`;
        return (
            <FormSection name={prefix}>
                <h5>{t("punishment", {type: index + 1})}</h5>
                <Field
                    name={"Time"}
                    id={`${prefix}_time`}
                    label={t("newSettings.feeding.general.time")}
                    component={ReduxLabeledSlider}
                    min={0}
                    max={15}
                    step={1}
                    valueFormatter={(value) => t("newSettings.feeding.general.timeFormatter", {value: value})}
                />
                <Field
                    name={"Percentage"}
                    id={`${prefix}_percentage`}
                    label={t("newSettings.feeding.general.percentage")}
                    component={ReduxLabeledSlider}
                    min={10}
                    max={100}
                    step={5}
                    valueFormatter={(value) => `${value}%`}

                />
                <Field
                    name={"Trigger"}
                    id={`${prefix}_trigger`}
                    label={t("newSettings.feeding.general.trigger")}
                    component={ReduxLabeledSlider}
                    min={1}
                    max={15}
                    step={1}
                />
            </FormSection>
        )
    }

    renderSection = (prefix, name) => {
        const {optionsInsemination, optionsParturition} = this.getOptions(prefix);
        const {feedingCurves, t, [prefix]: {defaultInseminationCurveNumber, defaultParturitionCurveNumber}} = this.props;
        const showingType = (index) => getCurveDayShowingType(feedingCurves.find(c => c.SetData.Index === (index)));
        const insemination = {
            max: this.getMaxDay(feedingCurves, defaultInseminationCurveNumber),
            show: !!defaultInseminationCurveNumber && (showingType(defaultInseminationCurveNumber - 1) !== CurveDayShowingType.BOTH)
        };
        const parturition = {
            max: this.getMaxDay(feedingCurves, defaultParturitionCurveNumber),
            show: !!defaultParturitionCurveNumber && (showingType(defaultParturitionCurveNumber - 1) !== CurveDayShowingType.BOTH)
        };
        return (
            <FormSection name={prefix}>
                <Field
                    component={ReduxLabeledSelect}
                    name={"DefaultInseminationCurveNumber"}
                    label={i18next.t("newSettings.feeding.general.defaultInseminationCurveNumber", {type: name})}
                    options={optionsInsemination}
                />
                {
                    insemination.show &&
                    <Field
                        component={ReduxLabeledSlider}
                        name={"DefaultInseminationCurveDay"}
                        label={t("newSettings.feeding.general.setCurveDayTo")}
                        min={1}
                        step={1}
                        max={insemination.max}
                    />
                }
                {
                    !insemination.show && !!defaultInseminationCurveNumber && i18next.t("newSettings.feeding.general.usingInseminationDayDefinedInCurve", {day: insemination.max})
                }

                <Field
                    component={ReduxLabeledSelect}
                    name={"DefaultParturitionCurveNumber"}
                    label={i18next.t("newSettings.feeding.general.defaultParturitionCurveNumber", {type: name})}
                    options={optionsParturition}
                />
                {
                    parturition.show &&
                    <Field
                        component={ReduxLabeledSlider}
                        name={"DefaultParturitionCurveDay"}
                        label={t("newSettings.feeding.general.setCurveDayTo")}
                        min={1}
                        step={1}
                        max={parturition.max}
                    />
                }
                {
                    !parturition.show && !!defaultParturitionCurveNumber && i18next.t("newSettings.feeding.general.usingParturitionDayDefinedInCurve", {day: parturition.max})
                }
            </FormSection>
        )
    };

    renderSector(name, label, filter) {
        return (
            <Field
                component={ReduxLabeledSelect}
                options={this.props.feedingCurves.filter(filter).map(curve => ({
                    name: curve.SetData.Name,
                    value: curve.SetID
                }))}
                label={label}
                name={name}
            />
        )
    }

    acceptIndividualCurves = curve => curve.SetData.Type === CurveType.INDIVIDUAL;

    acceptMatingCurve = curve => curve.SetData.Type === CurveType.MATING;

    acceptParturitionCurve = curve => curve.SetData.Type === CurveType.PARTURITION;

    acceptBeforeCurve = curve => getCurveDayShowingType(curve) === CurveDayShowingType.BEFORE;

    acceptAfterCurve = curve => getCurveDayShowingType(curve) === CurveDayShowingType.AFTER;

    acceptBothCurve = curve => getCurveDayShowingType(curve) === CurveDayShowingType.BOTH;

    filterMatingCurves = curve => (this.acceptMatingCurve(curve) && this.acceptBothCurve(curve)) || this.acceptIndividualCurves(curve);

    filterSowsCurves = curve => this.acceptMatingCurve(curve) && (this.acceptAfterCurve(curve) || this.acceptBothCurve(curve));

    filterDeliveryCurves = curve => this.acceptIndividualCurves(curve) || this.acceptParturitionCurve(curve);

    render() {
        const {handleSubmit, t, initialized} = this.props;
        return (
            <FormForSettings handleSubmit={handleSubmit}>
                <Row>
                    <Col xl={6}>
                        <Card>
                            <h5>{t("general")}</h5>
                            <Field
                                component={ReduxLabeledSlider}
                                name={"ParturitionFeedingBreakTime"}
                                label={t("newSettings.feeding.general.parturitionFeedingBreakTime")}
                                min={0}
                                max={MaxParturitionFeedingBreakTime}
                                step={15 * 60 * 1000}
                                valueFormatter={value => value === MaxParturitionFeedingBreakTime ? "24:00" : moment.utc(value).format("HH:mm")}
                            />
                            {
                                !!initialized &&
                                <>
                                    {this.renderSection("WST", "F1")}
                                    {this.renderSection("NRF", "F3/F5")}
                                </>
                            }
                        </Card>
                    </Col>
                    <Col xl={6}>
                        <Card>
                            {
                                !!initialized &&
                                <>
                                    {this.renderPunishment(0)}
                                    {this.renderPunishment(1)}
                                    {this.renderPunishment(2)}
                                </>
                            }
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <Card>
                            <h5>{t("newSettings.feeding.general.defaultCurvesHeader")}</h5>
                            <FormSection name={"DefaultCurves"}>
                                {this.renderSector("Mating", t("SType.0"), this.filterMatingCurves)}
                                {this.renderSector("Sows", t("SType.1"), this.filterSowsCurves)}
                                {this.renderSector("Delivery", t("SType.3"), this.filterDeliveryCurves)}
                                {this.renderSector("Other", t("modals.feedingConditionModal.other"), this.acceptIndividualCurves)}
                            </FormSection>
                        </Card>
                    </Col>
                </Row>
            </FormForSettings>
        );
    }

}

GeneralFeedingSettings = reduxForm({
    form: "generalFeeding",
    onSubmit: submit
})(GeneralFeedingSettings);

const selector = formValueSelector("generalFeeding");
GeneralFeedingSettings = connect(state => ({
    farm: state.location.farm,
    user: state.user.user,
    feedingCurves: getFeedingCurves(state),
    WST: {
        defaultInseminationCurveNumber: selector(state, "WST.DefaultInseminationCurveNumber"),
        defaultParturitionCurveNumber: selector(state, "WST.DefaultParturitionCurveNumber"),
    },
    NRF: {
        defaultInseminationCurveNumber: selector(state, "NRF.DefaultInseminationCurveNumber"),
        defaultParturitionCurveNumber: selector(state, "NRF.DefaultParturitionCurveNumber"),
    }
}))(GeneralFeedingSettings);

export default withTranslation()(GeneralFeedingSettings);
