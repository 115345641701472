import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {submit, validate} from "./ChangePasswordSubmit"
import FormForSettings from "../../FormForSettings";
import ReduxLabeledInput from "../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import InfoBox from "../../../../components/basics/info-box/InfoBox";
import Card from "../../../../components/basics/card/Card";
import {AWSLoginMessageMap} from "../../../../utils/TranslationUtils";
import {withTranslation} from "react-i18next";
import {hide, show} from "redux-modal";
import {logout} from "../../../../utils/UserUtils";
import DelayedActionModal from "../../../../components/modals-new/delayed-action/DelayedActionModal";
import {bindActionCreators, compose} from "redux";


const FormName = 'settings-profile-change-password';
const selector = formValueSelector(FormName);

function mapStateToProps(state) {
    return {
        error: selector(state, "error"),
        farm: state.location.farm,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

class ChangePasswordView extends React.Component {

    messageMap = (message) => {
        return AWSLoginMessageMap(message)
    };

    render() {
        const {handleSubmit, t, error} = this.props;
        return (
            <Card className={FormName}>
                <FormForSettings {...this.props} handleSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col md={6}>
                            {
                                !!error &&
                                <InfoBox boxColor={"error"}>{this.messageMap(error)}</InfoBox>
                            }
                            <Field
                                name="passwordOld"
                                id="passwordOld"
                                type="password"
                                component={ReduxLabeledInput}
                                label={t("login.password")}
                            />
                            <Field
                                name="password"
                                id="password"
                                type="password"
                                component={ReduxLabeledInput}
                                label={t("newPassword")}
                            />
                            <Field
                                name="passwordConfirmation"
                                type="password"
                                id="passwordConfirmation"
                                component={ReduxLabeledInput}
                                label={t("newSettings.profile.changePasswordView.passwordConfirmation")}
                            />
                        </Col>
                    </Row>
                </FormForSettings>
                <DelayedActionModal handleHide={hide} counter={5} action={logout} title={t("modals.logoutModal.title")}
                                    text={"modals.logoutModal.text"} confirmButtonText={t("logout")}/>
            </Card>
        );
    }

}

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: FormName,
        onSubmit: submit,
        validate
    })
)(ChangePasswordView);

