import React, {Component} from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import FeedingCurveItem from "./FeedingCurveItem";
import _ from "lodash";
import List from "../../../../../components/basics/list/List";
import Card from "../../../../../components/basics/card/Card";
import withRoles from "../../../../../components/withRoles";
import {LicPackageKeys, LicPackageLevel, Roles} from "../../../../../constans/roles";
import ListItemFullWidthRow from "../../../../../components/list-components/ListItemFullWidthRow";
import {getLicenseByName} from "../../../../../selectors/roleSelector";
import {getFeedingCurves} from "../../../../../selectors/feedingSelector";
import {LicenseItemCounts} from "../../../../../constans/licenseCounts";
import {withTranslation} from "react-i18next";

@connect((state) => {
    return {
        feedingCurves: getFeedingCurves(state),
        license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER})
    };
})
class FeedingCurvesView extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.props, nextProps);
    }

    render() {
        const {feedingCurves, license, t} = this.props;
        let curvesWithEmptySpaces = [];
        for (let i = 0; i < LicenseItemCounts.FEEDING_CURVE_EXTENDED; i++) {
            curvesWithEmptySpaces[i] = {curve: feedingCurves.find(cur => cur.SetData.Index === i), index: i};
        }
        return (
            <Card type={"container"} className={"feeding-view-container"}>
                <List striped useFlexLayout={false} showNumber>

                    <ListItemFullWidthRow text={t("newSettings.feeding.curves.feedingCurveItem.compatibility", {type: "F1, F3, F5"})}
                                          key={"comp_WST"}/>
                    {
                        curvesWithEmptySpaces.slice(0, LicenseItemCounts.FEEDING_CURVE_BASIC).map((curve, key) => (
                            <FeedingCurveItem key={`curve_${curve.index}`}{...curve}/>
                        ))
                    }
                    {
                        (license === LicPackageLevel.EXTENDED) &&
                        <>
                            <ListItemFullWidthRow text={t("newSettings.feeding.curves.feedingCurveItem.compatibility", {type: "F3, F5"})}
                                                  key={"comp_NRF"}/>
                            {
                                curvesWithEmptySpaces.slice(LicenseItemCounts.FEEDING_CURVE_BASIC, LicenseItemCounts.FEEDING_CURVE_EXTENDED).map((curve, key) => (
                                    <FeedingCurveItem key={`curve_${curve.index}`}{...curve}/>
                                ))
                            }
                        </>
                    }
                </List>
            </Card>
        )
    }
}

FeedingCurvesView = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(FeedingCurvesView);
FeedingCurvesView = withTranslation()(FeedingCurvesView);
export const _FeedingCurvesView = FeedingCurvesView;
export default withRoles({
    roles: [Roles._DEVICE_CONFIG],
    clientPackage: {[LicPackageKeys.DISPENSER]: LicPackageLevel.BASIC},
    showComponent: true
})(FeedingCurvesView);
