import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, FieldArray, formValueSelector, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import Card from "../../../../../components/basics/card/Card";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {DevType} from "../../../../../constans/devices";
import ColorPicker from "../../../../../components/redux-color-picker/ColorPicker";
import FormForSettings from "../../../FormForSettings";
import Button from "../../../../../components/basics/button/Button";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import TableGrid from "../../../../../components/basics/table-grid/TableGrid";
import Setting from "../../../../../beans/Setting";
import {SettingTypes} from "../../../../../constans/settingTypes";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import {setFeedEFCTime} from "../../../../../IOT/device-functions/GatewayFunctions";
import {getFeedingDataGroupedByChamberIDs} from "../../../../../utils/FeedingUtils";
import Select from "../../../../../components/basics/select/Select";
import {addForageIDonChambers, swapForageIDonChambers} from "../../../../../utils/BuildingUtils";
import ReduxTableGrid from "../../../../../components/basics/table-grid/redux-table-grid/ReduxTableGrid";
import ReduxInput from "../../../../../components/basics/input/ReduxInput";
import ForageIngredient from "../../../../../beans/ForageIngredient";
import {convertWeightUnitTo} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";
import {updateLocation} from "../../../../../actions/farmsActions";
import {createDictionaryDynamoDB} from "../../../../../actions/dictionaryActions";
import {withTranslation} from "react-i18next";

const FormName = 'settings-feeding-forages-edit-efficiency';

export class EditEfficiency extends React.Component {

    constructor(props) {
        super(props);
        const {defaultValue, initialize} = this.props;
        const chamberData = this.getChambersData(this.props);
        this.state = {
            selectedChamber: null
        };
        const chambers = defaultValue ? chamberData.filter(chamber => chamber.forageID === defaultValue.SetID).map(o => ({
            value: o.CID,
            name: o.CName
        })) : [];
        initialize({
            efficiency: _.get(defaultValue, "SetData.Efficiency", 1000),
            forageName: _.get(defaultValue, "SetData.Name", ""),
            color: _.get(defaultValue, "SetData.Color", "#FFFFFF"),
            recipe: _.get(defaultValue, "SetData.Recipe", []).map(o => ({
                name: o.Name,
                percentage: `${o.Percentage}`,
                cost: `${o.Cost}`
            })),
            chambersSelect: chamberData.map(o => ({name: o.CName, value: o.CID})),
            chambers: chambers,
            orgChambersIDs: chambers.map(o => o.value)
        });
    }

    getChambersData = (props) => {
        const {buildings} = props;
        return getFeedingDataGroupedByChamberIDs(buildings).map(o => o.chamber);
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {defaultValue, change} = nextProps;
        if (defaultValue) {
            if (!_.isEqual(nextProps.buildings, this.props.buildings)) {
                const chamberData = this.getChambersData(nextProps);
                const newChambers = chamberData.filter(chamber => chamber.forageID === defaultValue.SetID).map(o => ({
                    value: o.CID,
                    name: o.CName
                }));
                change("chambersSelect", chamberData);
                change("chambers", newChambers);
            }
        }

    }

    onDeleteClick(row) {
        const {chambers, change} = this.props;
        const newChambers = _.cloneDeep(chambers).filter(ch => ch.value !== row.value.value);
        change("chambers", newChambers);
    }

    onAddClick() {
        const {chambers, change} = this.props;
        const {selectedChamber} = this.state;
        if (selectedChamber) {
            const newChambers = _.cloneDeep(chambers);
            newChambers.push(selectedChamber);
            change("chambers", newChambers);
            this.setState({
                selectedChamber: null
            })
        }
    }

    sumRenderer = (data = []) => {
        const {t} = this.props;
        let totalCost = 0;
        if (!data.length) return [];
        data.forEach((name, i) => {
            const field = data.get(i);
            let localCost;
            try {
                localCost = (+field.percentage * +field.cost) / 100 || 0;
            } catch (e) {
                localCost = 0;
            }
            totalCost += localCost;
        });
        return [<div className={"text-right"}>{t("newSettings.feeding.forages.editEfficiency.cost")}<strong
            className={"ml-1"}>{totalCost.toFixed(2)}{`PLN/${convertWeightUnitTo(1000 * 1000, {
            showUnit: true,
            unit: UnitTypes.BIG,
            fixed: 1
        })}`}</strong></div>]
    };

    render() {
        const {handleSubmit, t, chambers = [], chambersSelect = []} = this.props;
        const options = chambersSelect.filter(o => !chambers.find(ch => ch.value === o.value)).map(o => ({
            name: o.name,
            value: o
        }));
        const chamberHeaders = [
            {
                name: t("newSettings.feeding.forages.editEfficiency.chamberName"),
                field: "name"
            },
            {
                name: "",
                component: props => <i className="fas fa-trash icon-component"
                                       onClick={() => this.onDeleteClick(props)}/>,
                itemClassName: "index",
                headerClassName: "index"
            }
        ];
        const recipeHeaders = [
            {
                name: t("newSettings.feeding.forages.editEfficiency.ingredient"),
                field: "name",
                component: ReduxInput,
                type: "text",
            },
            {
                name: t("newSettings.feeding.forages.editEfficiency.content"),
                field: "percentage",
                component: ReduxInput,
                type: "number",
            },
            {
                name: t("newSettings.feeding.forages.editEfficiency.cost"),
                field: "cost",
                component: ReduxInput,
                type: "number",
                unit: `PLN/${convertWeightUnitTo(1000 * 1000, {showUnit: true, unit: UnitTypes.BIG, fixed: 1})}`
            }
        ];
        return (
            <div className="overflow-hidden">
                <FormForSettings {...this.props} handleSubmit={handleSubmit} icon={<i className={"fas fa-save"}/>}>
                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <Card>
                                <h5>
                                    {t("general")}
                                </h5>
                                <Field
                                    name={"forageName"}
                                    id={"forageName"}
                                    label={t("newSettings.feeding.forages.editEfficiency.forageName")}
                                    type={"text"}
                                    component={ReduxLabeledInput}
                                />
                                <label>{t("newSettings.feeding.forages.editEfficiency.color")}</label>
                                <Field
                                    name={"color"}
                                    id={"color"}
                                    component={ColorPicker}
                                />

                            </Card>
                        </Col>
                        <Col xs={12} xl={6}>
                            <Card>
                                <h5>
                                    {t("newSettings.feeding.forages.editEfficiency.recipe")}
                                </h5>
                                <FieldArray component={ReduxTableGrid} minLength={0} name="recipe" addRemoveButton
                                            addAddButton
                                            bottomPinnedRows={this.sumRenderer}
                                            headers={recipeHeaders}/>
                            </Card>

                        </Col>
                        <Col xs={12} xl={6}>
                            <Card>
                                <h5>
                                    {t("chambers")}
                                </h5>
                                <TableGrid className={"redux"} data={chambers || []} headers={chamberHeaders}
                                           shouldIndex/>
                                {
                                    !!options.length &&
                                    <Row>
                                        <Col xs={8}>
                                            <Select value={this.state.selectedChamber}
                                                    onChange={(value) => this.setState({selectedChamber: value})}
                                                    options={options}/>
                                        </Col>
                                        <Col xs={4}>
                                            <Button type={"button"}
                                                    className={"w-100"}
                                                    onClick={() => this.onAddClick()}>{t("addChamber")}</Button>
                                        </Col>

                                    </Row>
                                }

                            </Card>
                        </Col>

                    </Row>
                </FormForSettings>
            </div>
        );
    }
}


function submit(values, dispatch, props) {
    const {defaultValue, index, gateways, farm, buildings, forageIngredients} = props;
    const {efficiency, forageName, color, recipe = [], chambers = [], orgChambersIDs = []} = values;
    const dictionariesToCreate = [];
    let forage = Setting.createSetting(SettingTypes.FEEDING_FORAGE, {
        Name: forageName,
        Efficiency: efficiency,
        Index: index,
        Color: color,
        Recipe: [],
        Cost: undefined
    });
    recipe.forEach(o => {
        const Name = o.name.trim();
        let dictItem = forageIngredients.find(x => x.WData.Name.toUpperCase() === Name.toUpperCase());
        if (!dictItem) {
            dictItem = ForageIngredient.createIngredient(Name);
            dictionariesToCreate.push(dictItem);
        }
        forage.addIngredient(dictItem, +o.percentage, +o.cost);
    });
    forage.SetData.Cost = forage.getCost();
    const chamberIDs = chambers.map(o => o.value);
    for (let dictionary of dictionariesToCreate) {
        dispatch(createDictionaryDynamoDB(dictionary.prepareBeanToSave()));
    }
    //flaga czy forage sie w ogole zmienil
    const ChangeType = {
        FRESH_CREATE: 0,
        UPDATE: 1,
        DO_NOTHING: 3
    };
    let watDoink = ChangeType.FRESH_CREATE;
    if (defaultValue) {
        const orgForage = {...defaultValue.prepareBeanToSave(), DtaModTime: 0};
        const minorKeys = ["SetID", "DtaModTime", "SetData.PrimeForageID"];
        const newForage = {
            ...forage.prepareBeanToSave(),
            DtaModTime: 0,
            SetID: defaultValue.SetID
        };
        for (let key of minorKeys) {
            _.set(newForage, key, null);
            _.set(orgForage, key, null);
        }
        if (_.isEqual(orgForage, newForage)) {
            watDoink = ChangeType.DO_NOTHING;
        } else {
            if ((defaultValue.Name === forage.Name) && (defaultValue.getCost() === forage.getCost()) && _.isEqual(defaultValue.getRecipe(), forage.getRecipe())) {
                watDoink = ChangeType.UPDATE;
            } else {
                watDoink = ChangeType.FRESH_CREATE;
            }
        }
    }
    switch (watDoink) {
        case ChangeType.FRESH_CREATE: {
            if (defaultValue) {
                let copy = defaultValue.clone();
                copy.deleteSetting();
                dispatch(updateSettingDynamoDB(copy.prepareBeanToSave()));
                forage.setOriginalForageID(defaultValue.getOriginalForageID());
            }
            dispatch(createSettingDynamoDB(forage.prepareBeanToSave(), () => {
                    gateways.forEach((gateway) => {
                        setFeedEFCTime(gateway, forage);
                    })
                }
            ));
            break;
        }
        case ChangeType.UPDATE: {
            forage.SetID = defaultValue.SetID;
            let copy = forage.clone();
            copy.setDtaModTime();
            copy.setOriginalForageID(defaultValue.getOriginalForageID());
            dispatch(updateSettingDynamoDB(copy.prepareBeanToSave()));
            break;
        }
        case ChangeType.DO_NOTHING:
        default:
            forage.SetID = defaultValue.SetID;
            break;
    }
    let buildingsToUpdate = buildings;
    //budynki updatujemy jesli id foraga sie zmienilo lub jesli chamber id sie pozmienialy
    if ((watDoink === ChangeType.FRESH_CREATE) || !_.isEqual(orgChambersIDs, chamberIDs)) {
        buildingsToUpdate = swapForageIDonChambers(buildingsToUpdate, null, defaultValue ? defaultValue.SetID : forage.SetID);
        buildingsToUpdate = buildings.map(o => {
            return buildingsToUpdate.find(b => b.BgID === o.BgID) || o;
        });
        buildingsToUpdate = addForageIDonChambers(buildingsToUpdate, forage.SetID, chamberIDs);
        for (let building of buildingsToUpdate) {
            dispatch(updateLocation(building));
        }
    }
    props.history.push(`/${farm}/settings/feeding/forages`);


}

function validate(values, props) {
    const errors = {};
    const {forageName, recipe = []} = values;
    const {t} = props;
    if (!forageName) {
        errors.forageName = t("required");
    }
    errors.recipe = [];
    const names = [];
    let totalPercentage = 0;
    recipe.forEach((ingredient, index) => {
        const ingredientErrors = {};
        if (!ingredient.name || !ingredient.name.trim()) {
            ingredientErrors.name = t("required");
        } else {
            if (names.includes(ingredient.name.trim().toUpperCase())) {
                ingredientErrors.name = t("errors.duplicate");
            } else {
                names.push(ingredient.name.trim().toUpperCase());
            }
        }
        if (!+ingredient.cost || +ingredient.cost < 0) {
            ingredientErrors.cost = t("ired");
        }
        if (!+ingredient.percentage || +ingredient.percentage < 0) {
            ingredientErrors.percentage = t("required");
        } else {
            totalPercentage += +ingredient.percentage;
            if (totalPercentage > 100) {
                ingredientErrors.percentage = t("errors.mustBeBelowOrEqual") + `${100 - (totalPercentage - +ingredient.percentage)}%`
            }
        }
        errors.recipe[index] = ingredientErrors;
    });
    return errors
}


EditEfficiency = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(EditEfficiency);
const selector = formValueSelector(FormName);
EditEfficiency = connect(
    state => ({
        stages: selector(state, "stages"),
        gateways: state.farmDevices.devices.filter(dev => dev.DevType === DevType.GATEWAY),
        dispensers: state.farmDevices.devices.filter(dev => [DevType.DISPENSER_NRF, DevType.DISPENSER].includes(dev.DevType) && dev.GatewayID),
        dispenser: selector(state, "dispenser"),
        chambers: selector(state, "chambers"),
        chambersSelect: selector(state, "chambersSelect"),
        recipe: selector(state, "recipe"),
        farm: state.location.farm,
        forageIngredients: state.dictionary.forageIngredients,
        buildings: state.farms.buildings
    }),
    dispatch => bindActionCreators({show}, dispatch)
)(EditEfficiency);

EditEfficiency = withTranslation()(EditEfficiency);

export default withRouter(EditEfficiency);

