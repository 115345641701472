import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import PropTypes from "prop-types";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import _ from "lodash"
import {DevType} from "../../../../../constans/devices";
import {connect} from "react-redux";
import {updateDeviceDynamoDB} from "../../../../../actions/devicesActions";
import {withTranslation} from "react-i18next";

export class NRFTemperature extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            minimumTemperatureAlarm: _.get(props.dispensers[0], "Settings.Temperature.MinimumAlarm", 0),
            maximumTemperatureAlarm: _.get(props.dispensers[0], "Settings.Temperature.MaximumAlarm", 25)
        }
    }

    temperatureValueFormatter = value => {
        return `${value}°C`
    };

    onMinAlarmChange = value => {
        this.setState({
            minimumTemperatureAlarm: value
        })
    };

    onMaxAlarmChange = value => {
        this.setState({
            maximumTemperatureAlarm: value
        })
    };

    onClick = () => {
        const {farm, user} = this.props;
        const {minimumTemperatureAlarm, maximumTemperatureAlarm} = this.state;
        let dispensers = [];
        for (let dispenser of this.props.dispensers) {
            if (dispenser.DevType === DevType.DISPENSER_NRF) {
                // jezeli dozownik to przypisz dane
                let copy = dispenser.clone();
                copy.Settings.Temperature = {
                    Sensor: copy.hasTemperatureSensor(),
                    MinimumAlarm: minimumTemperatureAlarm,
                    MaximumAlarm: maximumTemperatureAlarm
                }
                dispensers.push(copy.prepareBeanToSave());
            }
        }
        if (dispensers.length) {
            this.props.dispatch(updateDeviceDynamoDB(dispensers, farm, user.ClientID, user.LocalUserID));
        }
    };

    render() {
        const {t} = this.props;
        const {minimumTemperatureAlarm, maximumTemperatureAlarm} = this.state;
        return (
            <DeviceSettingsCard title={t("newSettings.dispenserNRF.temperature.title")} onButtonClick={this.onClick}>
                <LabeledSlider label={t("minTempAlarm")}
                               value={minimumTemperatureAlarm}
                               valueFormatter={this.temperatureValueFormatter} min={0} max={25} step={0.2}
                               onChange={this.onMinAlarmChange}/>
                <LabeledSlider label={t("maxTempAlarm")}
                               value={maximumTemperatureAlarm}
                               valueFormatter={this.temperatureValueFormatter} min={25} max={50} step={0.2}
                               onChange={this.onMaxAlarmChange}/>
            </DeviceSettingsCard>
        );
    }

}

NRFTemperature.propTypes = {
    dispensers: PropTypes.array.isRequired
};

NRFTemperature = connect(state => ({
    farm: state.location.farm,
    user: state.user.user,
}))(NRFTemperature);

export default withTranslation()(NRFTemperature);
