import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import BreedingPlan from "./plans/BreedingPlan";
import DevicesPlan from "./plans/DevicesPlan";
import AdministrationPlan from "./plans/AdministrationPlan";
import {Row} from "react-bootstrap";
import {connect} from "react-redux";
import ButtonGroup from "../../../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../../../components/basics/button/Button";
import {isEmpty, isEqual} from "lodash";
import Tooltip from "../../../../../../../components/basics/tooltip/Tooltip";

function mapStateToProps(state) {
    return {
        selectedPlans: state.braintree.selectedPlans,
    };
}

class PickPlanView extends React.Component {

    state = {
        submitFail: false
    };

    onNext = () => {
        const {selectedPlans, onNextStep} = this.props;
        if (!isEmpty(selectedPlans)) {
            onNextStep();
        } else {
            this.setState({
                submitFail: true
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedPlans} = this.props;
        if (!isEqual(prevProps.selectedPlans, selectedPlans) && !isEmpty(selectedPlans)) {
            this.setState({
                submitFail: false,
            })
        }
    }

    render() {
        const {onCancel, onUpdatePlan, selectedPlans, onBackStep, clearPlans, isFirstStep, t} = this.props;
        const {submitFail} = this.state;
        return (
            <Row className="plan-picker">
                <BreedingPlan onSelect={onUpdatePlan}/>
                <DevicesPlan onSelect={onUpdatePlan}/>
                <AdministrationPlan onSelect={onUpdatePlan}/>
                <ButtonGroup fixed renderInPortal={false}>
                    {
                        submitFail &&
                        <Tooltip
                            tooltipContent={t("subscriptions.plans.failMessagePick")}
                            type={"error"}>
                            <i className="fas fa-exclamation-circle error"/>
                        </Tooltip>
                    }
                    <Button buttonColor={"error"} buttonStyle={"round"} icon={<i className="fas fa-times"/>}
                            onClick={onCancel}/>
                    <Button buttonColor={"warning"} buttonStyle={"round"} icon={<i className="fad fa-eraser"/>}
                            onClick={clearPlans} disabled={selectedPlans.length === 0}/>
                    {
                        !isFirstStep && <Button buttonColor={"success"} buttonStyle={"round"} onClick={onBackStep}
                                                icon={<i className="fas fa-arrow-left"/>}/>
                    }
                    <Button buttonColor={"success"} buttonStyle={"round"} onClick={this.onNext}
                            icon={<i className="fas fa-arrow-right"/>}/>
                </ButtonGroup>
            </Row>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(PickPlanView)
