import React from "react";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import Card from "../../../../components/basics/card/Card";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";
import {checkIfUserCanManage} from "../../../../utils/NewRolesUtils";
import {manageDeviceHeaders} from "../../../../utils/DevicesUtils";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import DefaultMobileRow from "../../../../components/basics/table-grid/default-mobile-row/DefaultMobileRow";

export class ManageDispenserWST extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedDispensers: []
        }
    }

    onSelectionChange = selected => {
        this.setState({
            selectedDispensers: selected
        })
    };

    render() {
        const {devices, t, farm} = this.props;
        const headers = manageDeviceHeaders(t);
        const {selectedDispensers} = this.state;
        const userCanManage = checkIfUserCanManage();
        if (userCanManage) {
            return (
                <Card>
                    <TableGrid
                        data={devices.filter(item => (item.DevType === DevType.DISPENSER))}
                        headers={headers}
                        shouldIndex
                        mobileRow={<DefaultMobileRow/>}
                        selectableRow onSelectedRowsChanged={this.onSelectionChange} name={"manageDispensersF1"}/>
                    <ButtonGroup fixed renderInPortal={false}>
                        <Link to={{
                            pathname: `/${farm}/settings/feeding/f1/manage/configuration`,
                            state: {
                                selectedDispensers: selectedDispensers
                            }
                        }} disabled={selectedDispensers.length === 0}>
                            <Button buttonColor={"success"} buttonStyle={"round"}
                                    icon={<i className="fas fa-arrow-right"/>}
                                    disabled={selectedDispensers.length === 0}/>
                        </Link>
                    </ButtonGroup>
                </Card>
            );
        } else {
            return null;
        }
    }

}

export let _ManageDispenserWST = connect(state => ({
    devices: state.farmDevices.devices,
    farm: state.location.farm
}))(ManageDispenserWST);

_ManageDispenserWST = withRoles({roles: [Roles._DEVICE_CONFIG], showComponent: true})(_ManageDispenserWST);
export default withTranslation()(_ManageDispenserWST);
