import {DevType, FrameTypes} from "../../../../../../constans/devices";
import {updateDeviceDynamoDB} from "../../../../../../actions/devicesActions";
import _ from "lodash";
import {FORMAT} from "../../../../../../constans/formatTypes";
import {createDeviceAddressList} from "../../../../../../utils/DevicesUtils";
import {stringBuilder} from "../../../../../../utils/TextUtils";
import devicesDB from "../../../../../../database/devicesDB";
import {
    createDevices,
    createDevicesNotificationFailure,
    createDevicesNotificationSuccess
} from "../../../../../../api/devices/createDevices";

export function submit(values, dispatch, props) {
    const {address, interf, name, bridge, edit, device, aliases = [], alias, useVoltage} = values;
    if (!edit) {
        let settings = {Aliases: [], UseVoltage: !!useVoltage};
        for (let i = 0; i < 4; i++) {
            settings.Aliases.push(!!_.get(aliases[i], "name") ? _.get(aliases[i], "name") : undefined)
        }
        const devices = createDeviceAddressList(address, DevType.CHAIN_FEEDING).map(adr => ({
            DevType: DevType.CHAIN_FEEDING,
            Name: name,
            Address: adr,
            ParentID: bridge.DevID,
            Interface: +interf,
            Alias: alias,
            AdditionalData: {
                Settings: settings
            }
        }));
        return createDevices(devices).then(res => {
            props.reset();
            createDevicesNotificationSuccess(res);
        }).catch(e => {
            createDevicesNotificationFailure(e);
        });
    } else {
        let deviceClone = device.clone();
        if (!deviceClone.Settings) deviceClone.Settings = {};
        deviceClone.Settings.UseVoltage = !!useVoltage;
        if (!Array.isArray(deviceClone.Settings.Aliases)) deviceClone.Settings.Aliases = [];
        for (let i = 0; i < 4; i++) {
            deviceClone.Settings.Aliases.push(!!_.get(aliases[i], "name") ? _.get(aliases[i], "name") : undefined)
        }
        deviceClone.Address = Number(address);
        deviceClone.Name = name;
        deviceClone.ParentID = bridge.DevID;
        deviceClone.Interface = +interf;
        deviceClone.Alias = alias;
        deviceClone.Protocol = FrameTypes.NRF;
        deviceClone.VerHard = "0.1";
        deviceClone.VerSoft = "0.1";
        dispatch(updateDeviceDynamoDB(deviceClone, deviceClone.FarmID, props.user.ClientID, props.user.LocalUserID, null, () => {
            props.history.push(`/${props.farm}/settings/devices/manage`)
        }));
    }
}

export function validate(values, props) {
    const errors = {};

    if (!values.name) {
        errors.name = props.t("required");
    }
    if (!(FORMAT.DEVICE_MANY_ADDR_SP.test(`${values.address}`) && (!values.edit || FORMAT.NUMBER_DEC_OR_HEX.test(`${values.address}`)))) {
        errors.address = stringBuilder(props.t("errors.addressError"), `0x1300`, `0xFF00`, '0x1500');
    }
    if (!values.bridge) {
        errors.bridge = props.t("required");
    } else {
        if (values.address && !errors.address && values.interf) {
            let addressList = createDeviceAddressList(values.address, DevType.CHAIN_FEEDING);
            let takenAddresses = [];
            for (let address of addressList) {
                let devicesWithAddress = devicesDB.getDevicesWithAddress(props.farm, address);
                devicesWithAddress = devicesWithAddress.filter(item => item.ParentID === values.bridge.DevID && item.Interface === values.interf);
                if (values.edit) {
                    devicesWithAddress = devicesWithAddress.filter(item => values.device.DevID !== item.DevID);
                }
                if (devicesWithAddress.length > 0) takenAddresses.push(address);
            }
            if (takenAddresses.length > 0) {
                errors.address = stringBuilder(props.t("errors.takenAddress"), takenAddresses.join(", "));
            }
        }
    }
    if (!values.interf) {
        errors.interf = props.t("required");
    }

    return errors;
}