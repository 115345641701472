import React, {Component} from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import _ from "lodash";
import {updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import PropTypes from "prop-types";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import {Link} from "react-router-dom";
import {DevType} from "../../../../../constans/devices";
import {setNutritionCurve} from "../../../../../IOT/device-functions/GatewayFunctions";
import Curve from "../../../../../beans/settings/Curve";
import ListItem from "../../../../../components/basics/list/list-item/ListItem";
import Chart from "../../../../../components/basics/chart/Chart";
import {convertWeightUnitTo, getUnit} from "../../../../../utils/UnitUtils";
import {getFeedingUnit, getSettingClass} from "../../../../../utils/SettingsUtils";
import {formatCurveDay, getCurveDayShowingType} from "../../../../../utils/FeedingUtils";
import {Col, Collapse, Row} from "react-bootstrap";
import {
    CurveDayShowingType,
    CurveTypesWithInsemination,
    CurveTypesWithParturition
} from "../../../../../constans/feedingTypes";
import {withTranslation} from "react-i18next";

class FeedingCurveItem extends Component {

    constructor(props) {
        super(props);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onUpdateOnDevicesClick = this.onUpdateOnDevicesClick.bind(this);
        this.state = {
            deleting: false,
            showCurve: false,
            ...this.getChartData()
        }
    }

    /**
     * Funkcja do przetwarzania danych z krzywej na taka forme jaka chca charty
     * Dodaje do dnia pole 'day' ze sfromatowanym dniem krzywej (przed porodem -X...-1, po porodzie 0...X, mieszana -X...0...X)
     * aby potem w definicji charta moc sie do niego odwolac przez sciezke a nie przez valueFormatter
     * Dodatkowo tworzy sie tutaj definicja danych wykresu (jakie pole ma wyswietlic z danych)
     * @param props
     * @returns {{days: *, chartDef: *}}
     */
    getChartData = (props = this.props) => {
        const {curve, t} = props;
        let days = _.cloneDeep(_.get(curve, "SetData.Days", []));
        days = days.map((o, i) => ({
            ...o, day: formatCurveDay(i + 1, curve)
        }));
        let unit = getFeedingUnit();
        const chartDef = [
            {
                color: "green",
                dataKey: "ForageAmount",
                name: t("dosage"),
                unit: getUnit("weight", unit),
                valueConverter: (value) => convertWeightUnitTo(value, {unit: unit, showUnit: false, fixed: 2})
            }
        ];
        return {
            days,
            chartDef
        }
    };

    onUpdateOnDevicesClick(e) {
        e.stopPropagation();
        const {gateways, curve} = this.props;
        if (curve instanceof Curve) {
            gateways.forEach((gateway) => {
                setNutritionCurve(gateway, curve, false)
            })
        }
    }

    onRemoveClick(e) {
        e.stopPropagation();
        const {dispatch, curve, show, t} = this.props;
        let curveCopy = _.cloneDeep(curve);
        // let curveCopy = curve.clone();
        curveCopy = curveCopy instanceof Curve ? curveCopy : getSettingClass(curveCopy);
        console.log(curveCopy);
        if (curveCopy instanceof Curve) {
            curveCopy.deleteSetting();
            show("confirm-modal", {
                title: t("newSettings.feeding.curves.feedingCurveItem.confirmDelete"),
                text: t("newSettings.feeding.curves.feedingCurveItem.confirmDeleteLong"),
                confirmText: t("yes"),
                onConfirmed: (props) => {
                    this.setState({
                        deleting: true
                    }, () => {
                        dispatch(updateSettingDynamoDB(curveCopy, () => {
                            props.handleHide();
                            this.setState({
                                deleting: false
                            })
                        }, () => {
                            this.setState({
                                deleting: false
                            })
                        }));
                    })
                }
            })

        }
    }

    getRelatedItemByKey(key) {
        if (!key) throw Error('Key must be present');
        const {curve} = this.props;
        const days = _.get(curve, "SetData.Days", []);
        let items = [];
        days.forEach(day => {
            if (!items.includes(1 + +day[key])) {
                items.push(1 + +day[key])
            }
        });
        return items.join(", ");
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)) {
            return true;
        }
        return false;
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props, nextProps)) {
            this.setState({
                ...this.getChartData(nextProps)
            })
        }
    }

    render() {
        const {curve, index, t} = this.props;
        const {deleting, showCurve, chartDef, days: chartDays} = this.state;
        const curveType = _.get(curve, "SetData.Type");
        let daysLength = _.cloneDeep(_.get(curve, "SetData.Days.length", 0));
        let name = _.get(curve, "SetData.Name", `Krzywa ${index + 1}`);

        let curveTypeName = t(`curves.${curveType}`);
        if (curveTypeName) {
            const showingType = getCurveDayShowingType(curve);
            let additionalName = "";
            switch (showingType) {
                case CurveDayShowingType.NORMAL:
                    additionalName = t(`newSettings.feeding.curves.curveDescriptions.${curveType}.normal.name`);
                    break;
                case CurveDayShowingType.BEFORE:
                    additionalName = t(`newSettings.feeding.curves.curveDescriptions.${curveType}.before.name`);
                    break;
                case CurveDayShowingType.AFTER:
                    additionalName = t(`newSettings.feeding.curves.curveDescriptions.${curveType}.after.name`);
                    break;
                case CurveDayShowingType.BOTH:
                    additionalName = t(`newSettings.feeding.curves.curveDescriptions.${curveType}.both.name`);
                    break;
                default:
                    break;
            }
            if (additionalName && (additionalName !== curveTypeName)) {
                curveTypeName += `: ${additionalName}`;
            }
        }

        let relatedSchedules = this.getRelatedItemByKey("DailyPlan");
        let relatedForages = this.getRelatedItemByKey("ForageType");
        return (
            <>
                <ListItem showNumber lp={index + 1} index={index} key={1}
                          className={curve ? "mh-5rem align-items-center pointer pt-3 pb-3 d-flex overflow-hidden position-relative" : "mh-5rem d-flex align-items-center mh-5rem overflow-hidden position-relative pt-3 pb-3"}
                          onClick={() => this.setState((state) => ({showCurve: !state.showCurve}))}>
                    <Col xs={12}>
                        <Row>
                            {
                                !!curve &&
                                <>
                                    <Col xs={12} sm={5} xl={4}>
                                        <div className={"font-weight-bold"}>{name}</div>
                                        <div className={"opacity-75"}>{curveTypeName || "?"}</div>
                                    </Col>
                                    <Col className={"d-none d-sm-block"} sm={3} xl={2}>
                                        <div className={"font-weight-bold"}>{daysLength}</div>
                                        <div
                                            className={"opacity-75"}>{t("numberOfDays")}</div>
                                    </Col>
                                    <Col className={"d-none d-xl-block"} xl={2}>
                                        <div className={"font-weight-bold"}>{relatedSchedules}</div>
                                        <div
                                            className={"opacity-75"}>{t("newSettings.feeding.curves.feedingCurveItem.relatedPlans")}</div>
                                    </Col>
                                    <Col className={"d-none d-xl-block"} xl={2}>
                                        <div className={"font-weight-bold"}>{relatedForages}</div>
                                        <div
                                            className={"opacity-75"}>{t("newSettings.feeding.curves.feedingCurveItem.relatedForages")}</div>
                                    </Col>
                                </>
                            }
                            {
                                !curve &&
                                <Col xs={12} sm={8} xl={10} className="opacity-50">
                                    <h5 className="justify-content-center">{t("newSettings.feeding.curves.feedingCurveItem.curveNotConfigured")}</h5>
                                </Col>
                            }
                            <Col xs={12} sm={4} xl={2} className={"justify-content-end align-items-center d-flex"}>
                                <ButtonGroup className={"m-0"}>
                                    <Link to={`/${this.props.farm}/settings/feeding/curves/edit/${index}`}>
                                        <Button type={"button"} icon={<i className="fas fa-pen"/>}
                                                buttonStyle={"round"}/>
                                    </Link>
                                    <Button type={"button"} disabled={!curve} icon={<i className="fas fa-share"/>}
                                            buttonStyle={"round"}
                                            onClick={this.onUpdateOnDevicesClick}/>
                                    <Button type={"button"} disabled={!curve} isLoading={deleting}
                                            icon={<i className="fas fa-trash"/>}
                                            buttonStyle={"round"}
                                            buttonColor={curve ? "error" : null} onClick={this.onRemoveClick}/>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Col>
                </ListItem>
                <Collapse in={showCurve && curve} mountOnEnter unmountOnExit>
                    <div className="d-flex w-100">
                        <ListItem className={"w-100 enable-overflow"} style={{height: "20rem"}} disableHover={true}
                                  index={index}>
                            <Col xs={12} className={"h-100"}>
                                <Chart dataDef={chartDef} data={chartDays}
                                       tooltipLabelFormatter={val => `${t("curveDay")} ${val}`}
                                       Yaxis={{
                                           name: t("dosage")
                                       }} Xaxis={{
                                    name: t("curveDay"),
                                    dataKey: "day"
                                }} referenceLines={[
                                    CurveTypesWithParturition.includes(curveType) ? {
                                        x: '0',
                                        color: "blue",
                                        name: t("parturitionDate")
                                    } : null,
                                    CurveTypesWithInsemination.includes(curveType) ? {
                                        x: '0',
                                        color: "blue",
                                        name: t("inseminationDay")
                                    } : null
                                ].filter(o => o)}
                                       saveAsExcell={"curve"}/>
                            </Col>


                        </ListItem>
                    </div>

                </Collapse>
            </>
        )
    }
}

FeedingCurveItem.propTypes = {
    curve: PropTypes.object.isRequired
};
FeedingCurveItem = connect(state => ({
    user: state.user.user,
    gateways: state.farmDevices.devices.filter(dev => dev.DevType === DevType.GATEWAY),
    farm: state.location.farm
}))(FeedingCurveItem);
FeedingCurveItem = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(FeedingCurveItem);

export default withTranslation()(FeedingCurveItem);
