import {SettingTypes} from "../../../../../constans/settingTypes";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import Setting from "../../../../../beans/Setting";

export function submit(values, dispatch, props) {
    const {notifications, user: {LocalUserID}} = props;

    if (!notifications) {
        let notifi = Setting.createSetting(SettingTypes.NOTIFICATIONS, {Cage: values}, LocalUserID);
        delete notifi.FarmID;
        dispatch(createSettingDynamoDB(notifi));
    } else {
        let notifi = notifications.clone();
        notifi.SetData.Cage = values;
        notifi.setDtaModTime();
        dispatch(updateSettingDynamoDB(notifi));
    }
}