import {DevType, FrameTypes} from "../../../../../../constans/devices";
import _ from "lodash";
import {updateDeviceDynamoDB} from "../../../../../../actions/devicesActions";
import {FORMAT} from "../../../../../../constans/formatTypes";
import {createDeviceAddressList} from "../../../../../../utils/DevicesUtils";
import devicesDB from "../../../../../../database/devicesDB";
import i18next from "i18next";
import {
    createDevices,
    createDevicesNotificationFailure,
    createDevicesNotificationSuccess
} from "../../../../../../api/devices/createDevices";

export function submit(values, dispatch, props) {

    const {address, interf, name, bridge, edit, device, dispensers, alias} = values;

    if (!edit) {
        let Dispensers = [];
        for (let i = 0; i < 20; i++) {
            Dispensers[i] = {
                Adr: i,
                Connected: !!_.get(dispensers, `[${i}].Connected`, false),
                Alias: _.get(dispensers, `[${i}].Alias`, null)
            };
        }
        const devices = createDeviceAddressList(address, DevType.DISPENSER).map(adr => ({
            DevType: DevType.DISPENSER,
            Name: name,
            Address: adr,
            ParentID: bridge.DevID,
            Interface: +interf,
            Alias: alias,
            AdditionalData: {
                Dispensers
            }
        }));
        return createDevices(devices).then(res => {
            props.reset();
            createDevicesNotificationSuccess(res);
        }).catch(e => {
            createDevicesNotificationFailure(e);
        });
    } else {
        let deviceClone = device.clone();
        deviceClone.Address = Number(address);
        deviceClone.Name = name;
        deviceClone.ParentID = bridge.DevID;
        deviceClone.Interface = +interf;
        deviceClone.Alias = alias;
        deviceClone.Dispensers = _.cloneDeep(dispensers || new Array(20).fill(1)).map((dispenser, i) => ({
            Adr: i,
            Connected: !!dispenser.Connected,
            Alias: dispenser.Alias
        }));
        deviceClone.Protocol = FrameTypes.WST;
        deviceClone.VerHard = "0.1";
        deviceClone.VerSoft = "0.1";
        dispatch(updateDeviceDynamoDB(deviceClone, deviceClone.FarmID, props.user.ClientID, props.user.LocalUserID, null, () => {
            props.history.push(`/${props.farm}/settings/devices/manage`)
        }));
    }
}

export function validate(values, props) {

    const errors = {};

    if (!values.name) {
        errors.name = props.t("required");
    }
    if (!(FORMAT.DEVICE_MANY_ADDR_DI.test(`${values.address}`) && (!values.edit || FORMAT.NUMBER_DEC_OR_HEX.test(`${values.address}`)))) {
        errors.address = i18next.t("errors.addressError", {
            amount1: `${1} (0x1)`,
            amount2: `127 (0x${(15).toString(16).toUpperCase()})`,
            amount3: '14'
        });
    } else {
        const {user: {LicPackages = []}, farm} = props;
        let licPackage = LicPackages.find(item => item.FarmID === farm);
        if (licPackage) {
            let addressList = createDeviceAddressList(values.address, DevType.DISPENSER);
            if (values.dispensers) {
                let activeDispensers = values.dispensers.reduce((a, b) => a + +b.Connected, 0);
                let maxNumberOfDevices = Math.floor(licPackage.DevDispensersWSTLeft / activeDispensers);
                if (addressList.length > maxNumberOfDevices) errors.address = i18next.t("errors.maxDevices", {amount: maxNumberOfDevices});
            }
        }
    }
    if (!values.bridge) {
        errors.bridge = props.t("required");
    } else {
        if (values.address && !errors.address && values.interf) {
            let addressList = createDeviceAddressList(values.address, DevType.DISPENSER);
            let takenAddresses = [];
            for (let address of addressList) {
                let devicesWithAddress = devicesDB.getDevicesWithAddress(props.farm, address);
                devicesWithAddress = devicesWithAddress.filter(item => item.ParentID === values.bridge.DevID && item.Interface === values.interf);
                if (values.edit) {
                    devicesWithAddress = devicesWithAddress.filter(item => values.device.DevID !== item.DevID);
                }
                if (devicesWithAddress.length > 0) takenAddresses.push(address);
            }
            if (takenAddresses.length > 0) {
                errors.address = i18next.t("errors.takenAddress", {type: takenAddresses.join(", ")});
            }
        }
    }
    if (!values.interf) {
        errors.interf = props.t("required");
    }

    return errors;
}