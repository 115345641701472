import React from "react";
import {FieldArray, formValueSelector, reduxForm} from "redux-form";
import FormForDevices from "../FormForDevices";
import Card from "../../../../../../components/basics/card/Card";
import {connect} from "react-redux";
import {submit, validate} from "./DispenserWSTSubmit"
import {withRouter} from "react-router-dom";
import ReduxTableGrid from "../../../../../../components/basics/table-grid/redux-table-grid/ReduxTableGrid";
import ReduxCheckbox from "../../../../../../components/basics/checkbox/ReduxCheckbox";
import withRoles from "../../../../../../components/withRoles";
import {Roles} from "../../../../../../constans/roles";
import Checkbox from "../../../../../../components/basics/checkbox/Checkbox";
import ReduxInput from "../../../../../../components/basics/input/ReduxInput";
import InfoBox from "../../../../../../components/basics/info-box/InfoBox";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class DispenserWST extends React.Component {

    constructor(props) {
        super(props);

        const {user: {LicPackages}, farm} = props;
        let farmLicense = null;
        if (LicPackages) {
            farmLicense = LicPackages.find(item => item.FarmID === farm);
        }
        this.state = {
            farmLicense
        };
        if (!this.props.device) {
            this.props.initialize({
                dispensers: new Array(20).fill({}).map((val, index) => ({
                    Connected: farmLicense ? farmLicense.DevDispensersWSTLeft > index : true,
                    Adr: index
                }))
            })
        }
    }

    onAllSelectedChange = (value) => {
        const {farmLicense} = this.state;
        if (value) {
            let dispensers = this.props.dispensers.map((dispenser, index) => ({
                ...dispenser,
                Connected: farmLicense ? farmLicense.DevDispensersWSTLeft > index : true,
            }));
            this.props.change("dispensers", dispensers);
        } else {
            let dispensers = this.props.dispensers.map(dispenser => ({
                ...dispenser,
                Connected: false
            }));
            this.props.change("dispensers", dispensers);
        }
    };

    checkIfSelected = () => {
        const {dispensers} = this.props;
        const {farmLicense} = this.state;
        let maxDispensers = farmLicense ? farmLicense.DevDispensersWSTLeft > 20 ? 20 : farmLicense.DevDispensersWSTLeft : 20;
        return dispensers && dispensers.reduce((a, b) => a + +b.Connected, 0) === maxDispensers;
    };

    disableCheckbox = ({value}) => {
        if (value) return false;
        const {farmLicense} = this.state;
        const {dispensers} = this.props;
        if (farmLicense && dispensers) {
            return farmLicense.DevDispensersWSTLeft <= dispensers.reduce((a, b) => a + +b.Connected, 0);
        }
        return false;
    };

    render() {
        const {handleSubmit, address, device, t, bridge, edit} = this.props;
        const {farmLicense} = this.state;
        let isSelected = this.checkIfSelected();
        const headers = [
            {
                name: t("newSettings.devices.form.exit"),
                field: "Adr",
                fieldToGet: "Adr",
                valueFormatter: (value) => value + 1,
                dontShowInput: true
            },
            {
                name: t("newSettings.devices.form.alias"),
                component: ReduxInput,
                field: "Alias",
                type: "text"
            },
            {
                name: t("newSettings.devices.form.active"),
                component: ReduxCheckbox,
                field: 'Connected',
                type: "number",
                disabled: this.disableCheckbox,
                headerComponent: <span>{t("newSettings.devices.form.active")} <Checkbox label="" checked={isSelected}
                                                                                        onChange={this.onAllSelectedChange}/></span>
            }
        ];
        return (
            <Card>
                <FormForDevices handleSubmit={handleSubmit} showInterfacePicker address={address}
                                showAdditionalButton={!!device} device={device} bridge={bridge} showHelp={!edit}>
                    {
                        farmLicense && farmLicense.DevDispensersWSTLeft < 20 &&
                        <InfoBox boxColor={"warning"}>
                            {i18next.t("newSettings.devices.form.maxWST", {license: farmLicense.DevDispensersWSTLeft})}
                        </InfoBox>
                    }
                    <FieldArray name="dispensers" component={ReduxTableGrid} headers={headers}/>
                </FormForDevices>
            </Card>
        );
    }

}

DispenserWST = reduxForm({
    form: "dispenserWST",
    onSubmit: submit,
    validate
})(DispenserWST);

DispenserWST = withRoles(props => {
    return {roles: [Roles._DEVICE_CONFIG], showComponent: true}
})(DispenserWST);

const selector = formValueSelector("dispenserWST");
DispenserWST = connect(state => ({
    address: selector(state, "address"),
    device: selector(state, "device"),
    dispensers: selector(state, "dispensers"),
    bridge: selector(state, "bridge"),
    edit: selector(state, "edit"),
    user: state.user.user,
    farm: state.location.farm
}))(DispenserWST);

DispenserWST = withTranslation()(DispenserWST);
export default withRouter(DispenserWST);

