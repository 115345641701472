import React from "react";
import Card from "../../../../../components/basics/card/Card";
import Button from "../../../../../components/basics/button/Button";
import {setClearHistory} from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType} from "../../../../../constans/devices";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../../../../components/modals-new/confirm-modal/ConfirmModal";
import {stringBuilder} from "../../../../../utils/TextUtils";

export class ClearHistory extends React.Component {

    onConfirmed(props, duration) {
        const {dispensers} = this.props;
        const devices = groupDevicesByGatewayID(dispensers);
        if (devices) {
            for (let devList of devices.values()) {
                devList[DevType.BROADCAST].length && setClearHistory(devList[DevType.BROADCAST], duration);
                devList[DevType.DISPENSER_NRF_MULTI].length && setClearHistory(devList[DevType.DISPENSER_NRF_MULTI], duration);
                devList[DevType.DISPENSER_NRF].length && setClearHistory(devList[DevType.DISPENSER_NRF], duration);
            }
        }
        props.handleHide();
    }

    onButtonClick(duration) {
        this.props.show(ConfirmModalName, {
            text: stringBuilder(`Czy na pewno chcesz wyczyścić historię z {1}?`, duration === "DAY" ? "dnia" : "2 tygodni"),
            title: "Czyszczenie historii",
            confirmText: "Tak",
            onConfirmed: props => this.onConfirmed(props, duration)
        })
    }

    render() {
        return (
            <Card className="device-settings-card">
                <h5>Czyszczenie pamięci</h5>
                <ButtonGroup vertical className="w-100 m-0">
                    <Button onClick={() => this.onButtonClick("DAY")}>Wyczyść historię z dnia</Button>
                    <Button onClick={() => this.onButtonClick("ALL")}>Wyczyść historię z 2 tygodni</Button>
                </ButtonGroup>
            </Card>
        );
    }

}

ClearHistory = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(ClearHistory);

export default ClearHistory;