import React, {Component} from "react";
import Checkbox from "../../../../../../../components/basics/checkbox/Checkbox";
import {myID} from "../../../../../../../libs/generateID";
import "../../_subscriptionsView.scss"
import InputAlikeContainer from "../../../../../../../components/input-alike-container/InputAlikeContainer";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ButtonGroup from "../../../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../../../components/basics/button/Button";
import Tooltip from "../../../../../../../components/basics/tooltip/Tooltip";

class SecondStepView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            submitFail: false,
        }
    }

    onChange = value => {
        this.setState({
            checked: value
        });
    };

    onNext = () => {
        const {onNextStep} = this.props;
        const {checked} = this.state;
        if (checked) onNextStep();
        else this.setState({submitFail: true});
    };

    render() {
        const {checked, submitFail} = this.state;
        const {t, onCancel, onBackStep, isFirstStep} = this.props;
        return (
            <div className="step">
                <div>
                    <InputAlikeContainer error={submitFail}>
                        <div>
                            {t("braintree.licenseText")}
                        </div>
                    </InputAlikeContainer>
                    <Checkbox label={t("braintree.readAndAccept")} checked={checked} onChange={this.onChange}
                              id={myID()}/>
                </div>
                <ButtonGroup fixed>
                    {
                        submitFail &&
                        <Tooltip tooltipContent={t("braintree.acceptAgreementErr")} type={"error"}>
                            <i className="fas fa-exclamation-circle error"/>
                        </Tooltip>
                    }
                    <Button buttonColor={"error"} buttonStyle={"round"} icon={<i className="fas fa-times"/>}
                            onClick={onCancel}/>
                    {
                        !isFirstStep &&
                        <Button buttonColor={"success"} buttonStyle={"round"} onClick={onBackStep}
                                icon={<i className="fas fa-arrow-left"/>}/>
                    }
                    <Button buttonColor={"success"} onClick={this.onNext} buttonStyle={"round"}
                            icon={<i className="fas fa-arrow-right"/>}/>
                </ButtonGroup>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
)(SecondStepView);
