import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import {DevType, LoadedDataTypes} from "../../../../../constans/devices";
import _ from "lodash";
import {DispenserNRFCommandTypes} from "../../../../../constans/mqttMessages";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import LabeledSelect from "../../../../../components/basics/select/labeled-select/LabeledSelect";
import {connect} from "react-redux";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {setSwitchMotorOnInfo} from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import  i18next from "i18next";

export class CANNotifications extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.getState(props)
    }

    getState(props) {
        const {dispensers} = props;
        let device = dispensers[0];
        return {
            state: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_SWITCH_MOTOR_ON_INFO}.state`, 0),
            loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[DispenserNRFCommandTypes.SET_SWITCH_MOTOR_ON_INFO] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
            device
        };
    }

    setValue = (value) => {
        this.setState({
            state: value,
            changed: true
        })
    };

    onClick = () => {
        const {dispensers} = this.props;
        const devices = groupDevicesByGatewayID(dispensers);
        if (devices) {
            const {state} = this.state;
            for (let devList of devices.values()) {
                devList[DevType.BROADCAST].length && setSwitchMotorOnInfo(devList[DevType.BROADCAST], state);
                devList[DevType.DISPENSER_NRF_MULTI].length && setSwitchMotorOnInfo(devList[DevType.DISPENSER_NRF_MULTI], state);
                devList[DevType.DISPENSER_NRF].length && setSwitchMotorOnInfo(devList[DevType.DISPENSER_NRF], state);
            }
        }
    };

    render() {
        const {state, loadedDataFrom, SetTime, device} = this.state;
        return (
            <DeviceSettingsCard title={"Powiadomienia - status CAN"} onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[DispenserNRFCommandTypes.SET_SWITCH_MOTOR_ON_INFO].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date:moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{i18next.t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledSelect label="Status" options={[
                    {
                        name: "Włączone",
                        value: 1
                    },
                    {
                        name: "Wyłączone",
                        value: 0
                    }
                ]} clearButton={false} value={state} onChange={this.setValue}/>
            </DeviceSettingsCard>
        );
    }

}

CANNotifications = connect(state => ({
}))(CANNotifications);
