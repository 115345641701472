import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import PropTypes from "prop-types";
import {get} from "lodash";
import {connect} from "react-redux";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType, LoadedDataTypes} from "../../../../../constans/devices";
import {setFeedingEfficiency} from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import {DispenserNRFCommandTypes} from "../../../../../constans/mqttMessages";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {convertWeightUnitTo, getUnit} from "../../../../../utils/UnitUtils";
import {getFeedingUnit} from "../../../../../utils/SettingsUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";
import {checkIfUserIsService} from "../../../../../utils/NewRolesUtils";

export class ForageEfficiency extends React.Component {

    constructor(props) {
        super(props);
        this.state = ForageEfficiency.getSetup(this.props);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.changed) {
            return ForageEfficiency.getSetup(nextProps)
        }
    }

    static getSetup = (props) => {
        const {dispensers, shadows} = props;
        let device = dispensers[0];
        let values = {};
        let tmp = [];
        try {
            tmp = shadows.get(device.DevID).efficiency;
            values.loadedDataFrom = tmp ? LoadedDataTypes.SHADOW : LoadedDataTypes.NO_DATA;
            values.SetTime = get(shadows.get(device.DevID), 'metadata.efficiency', 0);
        } catch (e) {
            tmp = get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_FEEDING_EFFICIENCY}`);
            values.loadedDataFrom = tmp ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA;
            values.SetTime = get(tmp, 'SetTime', 0);
        }
        values.efficiencyTime = get(tmp, "feedingTimeEfficiency", 1000);
        values.efficiencyImpulse = get(tmp, "feedingImpulseEfficiency", 25);
        values.device = device;
        return values;
    };


    onClick = () => {
        const {dispensers} = this.props;
        const {efficiencyTime, efficiencyImpulse} = this.state;
        const devices = groupDevicesByGatewayID(dispensers);
        const devTypes = [DevType.BROADCAST, DevType.DISPENSER_NRF, DevType.DISPENSER_NRF_MULTI];
        if (devices) {
            const dataTime = {
                feedingTimeEfficiency: efficiencyTime,
                feedingDoseType: 0
            }
            const dataImpulse = {
                feedingImpulseEfficiency: efficiencyImpulse,
                feedingDoseType: 1
            }
            for (let devList of devices.values()) {
                for (let devType of devTypes) {
                    if (devList[devType].length) {
                        setFeedingEfficiency(devList[devType], dataTime)
                        setFeedingEfficiency(devList[devType], dataImpulse)
                    }
                }
            }
        }
    };

    handleChangeEfficiencyTime = (value) => {
        this.setState({
            efficiencyTime: value,
            changed: true
        })
    };

    handleChangeEfficiencyImpulse = (value) => {
        this.setState({
            efficiencyImpulse: value,
            changed: true
        })
    };

    impulseFormatter = (value) => {
        const {t} = this.props;
        return t("newSettings.dispenserNRF.efficiency.impulseEfficiencyFormatter", {
            value: value,
            unit: getUnit("weight", UnitTypes.SMALL, {overrideUnitSystem: "metric"})
        })
    }

    timeFormatter = (value) => {
        const {t} = this.props;
        const unit = getFeedingUnit();
        return t("newSettings.dispenserNRF.efficiency.timeEfficiencyFormatter", {
            value: convertWeightUnitTo(value, {
                unit: unit,
                fixed: unit * 2,
                showUnit: true
            })
        })
    }

    render() {
        const {t} = this.props;
        const {loadedDataFrom, SetTime, device, efficiencyTime, efficiencyImpulse} = this.state;
        return (
            <DeviceSettingsCard title={t("newSettings.dispenserNRF.efficiency.title")}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[DispenserNRFCommandTypes.SET_MOTOR_SETUP].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledSlider valueFormatter={this.timeFormatter}
                               label={t("newSettings.dispenserNRF.efficiency.efficiencyTime")} value={efficiencyTime}
                               onChange={this.handleChangeEfficiencyTime} min={0} max={2550} step={10}/>
                {
                    checkIfUserIsService() &&
                    <LabeledSlider valueFormatter={this.impulseFormatter}
                                   label={t("newSettings.dispenserNRF.efficiency.efficiencyImpulse")}
                                   value={efficiencyImpulse} onChange={this.handleChangeEfficiencyImpulse} min={0}
                                   max={25}/>
                }
            </DeviceSettingsCard>
        );
    }

}

ForageEfficiency.propTypes = {
    dispensers: PropTypes.array.isRequired
};

ForageEfficiency = connect(state => ({
    shadows: state.shadows.shadows,
}))(ForageEfficiency);

export default withTranslation()(ForageEfficiency);
