import React from "react";
import Card from "../../../../../../components/basics/card/Card";
import FormForDevices from "../FormForDevices";
import {FieldArray, formValueSelector, reduxForm} from "redux-form";
import {submit, validate} from "./ScaleWSTSubmit"
import {connect} from "react-redux";
import ReduxCheckbox from "../../../../../../components/basics/checkbox/ReduxCheckbox";
import ReduxTableGrid from "../../../../../../components/basics/table-grid/redux-table-grid/ReduxTableGrid";
import {withRouter} from "react-router-dom";
import {getUnit} from "../../../../../../utils/UnitUtils";
import ReduxInput from "../../../../../../components/basics/input/ReduxInput";
import withRoles from "../../../../../../components/withRoles";
import {Roles} from "../../../../../../constans/roles";
import {getScaleUnit} from "../../../../../../utils/SettingsUtils";
import Checkbox from "../../../../../../components/basics/checkbox/Checkbox";
import ReduxSelect from "../../../../../../components/basics/select/ReduxSelect";
import InfoBox from "../../../../../../components/basics/info-box/InfoBox";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class ScaleWST extends React.Component {

    constructor(props) {
        super(props);

        const {user: {LicPackages}, farm} = props;
        let farmLicense = null;
        if (LicPackages) {
            farmLicense = LicPackages.find(item => item.FarmID === farm);
        }
        this.state = {
            farmLicense
        };

        if (!this.props.device) {
            this.props.initialize({
                siloses: new Array(8).fill({}).map((val, index) => ({
                    Active: farmLicense ? farmLicense.DevSilosesLeft > index : true,
                    Adr: index,
                    Capacity: 0
                }))
            })
        }
    }


    onAllSelectedChange = (value) => {
        const {farmLicense} = this.state;
        if (value) {
            let siloses = this.props.siloses.map((silo, index) => ({
                ...silo,
                Active: farmLicense ? farmLicense.DevSilosesLeft > index : true,
            }));
            this.props.change("siloses", siloses);
        } else {
            let siloses = this.props.siloses.map(silo => ({
                ...silo,
                Active: false
            }));
            this.props.change("siloses", siloses);
        }
    }

    checkIfSelected = () => {
        const {farmLicense} = this.state;
        const {siloses} = this.props;
        let maxSiloses = farmLicense ? farmLicense.DevSilosesLeft > 8 ? 8 : farmLicense.DevSilosesLeft : 8;
        return siloses && siloses.reduce((a, b) => a + +b.Active, 0) === maxSiloses;
    };

    disableCheckbox = ({value}) => {
        if (value) return false;
        const {farmLicense} = this.state;
        const {siloses} = this.props;
        if (farmLicense && siloses) {
            return farmLicense.DevSilosesLeft <= siloses.reduce((a, b) => a + +b.Active, 0);
        }
        return false;
    };

    render() {
        const {handleSubmit, address, device, t, forages, bridge, edit} = this.props;
        const {farmLicense} = this.state;
        let unit = getScaleUnit();
        let isSelected = this.checkIfSelected();
        const headers = [
            {
                name: t("newSettings.devices.form.exit"),
                field: "Adr",
                fieldToGet: "Adr",
                valueFormatter: (value) => value + 1,
                dontShowInput: true
            },
            {
                name: t("capacity"),
                component: ReduxInput,
                field: 'Capacity',
                unit: getUnit("weight", unit),
                type: "number"
            },
            {
                name: t("newSettings.devices.form.alias"),
                component: ReduxInput,
                field: "Alias",
                type: "text"
            },
            {
                name: t("forage"),
                component: ReduxSelect,
                field: "Forage",
                options: forages.map(forage => ({
                    name: forage.SetData.Name,
                    value: forage.SetID
                }))
            },
            {
                name: t("newSettings.devices.form.number"),
                component: ReduxInput,
                field: "Number",
                type: "text",
                normalize: value => value ? value.slice(0, 3).toUpperCase() : null
            },
            {
                name: t("newSettings.devices.form.active"),
                component: ReduxCheckbox,
                field: 'Active',
                type: "number",
                disabled: this.disableCheckbox,
                headerComponent: <span>{t("newSettings.devices.form.active")} <Checkbox label="" checked={isSelected}
                                                                                        onChange={this.onAllSelectedChange}/></span>
            }
        ];
        return (
            <Card>
                <FormForDevices handleSubmit={handleSubmit} showInterfacePicker device={device}
                                showAdditionalButton={!!device} address={address} bridge={bridge} showHelp={!edit}>
                    {
                        farmLicense && farmLicense.DevSilosesLeft < 8 &&
                        <InfoBox boxColor={"warning"}>
                            {i18next.t("newSettings.devices.form.maxSilo", {license: farmLicense.DevSilosesLeft})}
                        </InfoBox>
                    }
                    <FieldArray name="siloses" component={ReduxTableGrid} headers={headers}/>
                </FormForDevices>

            </Card>
        );
    }

}

ScaleWST = reduxForm({
    form: "weightWST",
    onSubmit: submit,
    validate
})(ScaleWST);

ScaleWST = withRoles(props => {
    return {roles: [Roles._DEVICE_CONFIG], showComponent: true}
})(ScaleWST);

const selector = formValueSelector("weightWST");
ScaleWST = connect(state => ({
    address: selector(state, "address"),
    device: selector(state, "device"),
    siloses: selector(state, "siloses"),
    bridge: selector(state, "bridge"),
    edit: selector(state, "edit"),
    user: state.user.user,
    forages: state.settings.forage,
    farm: state.location.farm
}))(ScaleWST);

ScaleWST = withTranslation()(ScaleWST);
export default withRouter(ScaleWST);
