import React from "react";
import Card from "../../../../../components/basics/card/Card";
import Button from "../../../../../components/basics/button/Button";
import {setClearFRAM} from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType} from "../../../../../constans/devices";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../../../../components/modals-new/confirm-modal/ConfirmModal";
import {stringBuilder} from "../../../../../utils/TextUtils";

export class ClearFRAM extends React.Component {

    onConfirmed(props, section) {
        const {dispensers} = this.props;
        const devices = groupDevicesByGatewayID(dispensers);
        if (devices) {
            for (let devList of devices.values()) {
                devList[DevType.BROADCAST].length && setClearFRAM(devList[DevType.BROADCAST], section);
                devList[DevType.DISPENSER_NRF_MULTI].length && setClearFRAM(devList[DevType.DISPENSER_NRF_MULTI], section);
                devList[DevType.DISPENSER_NRF].length && setClearFRAM(devList[DevType.DISPENSER_NRF], section);
            }
        }
        props.handleHide();
    };

    getName(section) {
        switch (section) {
            case 0: return "logi";
            case 1: return "czas pracy silnika";
            case 2: return "NRF/SET FLAG";
            case 3: return "krzywe";
            case 4: return "harmonogramy";
            case 5: return "konfiguracje standardową";
            case 6: return "RFID";
            case 11: return "konfiguracje CAN";
            default: return "";
        }
    }

    onButtonClick(section) {
        this.props.show(ConfirmModalName, {
            text: stringBuilder("Czy na pewno chcesz wyczyścić {1}?", this.getName(section)),
            title: "Czyszczenie pamięci",
            confirmText: "Tak",
            onConfirmed: props => this.onConfirmed(props, section)
        })
    }

    render() {
        return (
            <Card className="device-settings-card">
                <h5>Czyszczenie pamięci</h5>
                <ButtonGroup vertical className="w-100 m-0">
                    <Button onClick={() => this.onButtonClick(0)}>Wyczyść {this.getName(0)}</Button>
                    <Button onClick={() => this.onButtonClick(1)}>Wyczyść {this.getName(1)}</Button>
                    <Button onClick={() => this.onButtonClick(2)}>Wyczyść {this.getName(2)}</Button>
                    <Button onClick={() => this.onButtonClick(3)}>Wyczyść {this.getName(3)}</Button>
                    <Button onClick={() => this.onButtonClick(4)}>Wyczyść {this.getName(4)}</Button>
                    <Button onClick={() => this.onButtonClick(5)}>Wyczyść {this.getName(5)}</Button>
                    <Button onClick={() => this.onButtonClick(6)}>Wyczyść {this.getName(6)}</Button>
                    <Button onClick={() => this.onButtonClick(11)}>Wyczyść {this.getName(11)}</Button>
                </ButtonGroup>
            </Card>
        );
    }

}

ClearFRAM = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(ClearFRAM);

export default ClearFRAM;