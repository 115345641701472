import {updateDeviceDynamoDB} from "../../../../../../actions/devicesActions";
import i18next from "i18next";

export function submit(values, dispatch, props) {
    console.log(values);
    const {device, name, Number} = values;
    const {user} = props;
    let clone = device.clone();
    clone.Name = name;
    clone.Number = Number;
    dispatch(updateDeviceDynamoDB(clone, clone.FarmID, user.ClientID, user.LocalUserID, null, () => {
        props.history.push(`/${props.farm}/settings/devices/manage`)
    }));
}

export function validate(values, props) {
    const errors = {};

    if (!values.name) {
        errors.name = props.t("required");
    }

    if (values.Number && !values.Number.match(new RegExp("^[0-9]{1,2}[A-Z]?$", "g"))) {
        errors.Number = i18next.t("errors.shouldBeInFormat", {type: "1, 11, 11A"});
    }

    return errors;
}