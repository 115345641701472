import React from "react";
import Card from "../../../../../../components/basics/card/Card";
import FormForDevices from "../FormForDevices";
import {Field, FieldArray, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {submit, validate} from "./ChainFeedingSubmit";
import {withRouter} from "react-router-dom";
import withRoles from "../../../../../../components/withRoles";
import {Roles} from "../../../../../../constans/roles";
import {Col, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import ReduxTableGrid from './../../../../../../components/basics/table-grid/redux-table-grid/ReduxTableGrid';
import ReduxInput from './../../../../../../components/basics/input/ReduxInput';
import ReduxSwitch from './../../../../../../components/basics/switch/ReduxSwitch';

const FormName = "chainFeeding";

export class ChainFeeding extends React.Component {

    constructor(props) {
        super(props)
        if (!this.props.device) {
            this.props.initialize({
                aliases: [{name: ""}, {name: ""}, {name: ""}, {name: ""}]
            })
        }
    }


    render() {
        const {handleSubmit, address, device, t, bridge, edit} = this.props;
        const headers = [{
            name: t("newSettings.devices.form.alias"),
            component: ReduxInput,
            field: "name",
            type: "text"
        }]
        return (
            <Card>
                <FormForDevices handleSubmit={handleSubmit} showInterfacePicker address={+address} device={device}
                                showAdditionalButton={!!device} bridge={bridge} showHelp={!edit}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <hr/>
                            <Field
                                name={"useVoltage"}
                                id={"useVoltage"}
                                component={ReduxSwitch}
                                label={t("newSettings.devices.form.useVoltage")}
                            />
                            <h5>{t("sensors")}</h5>
                            <FieldArray shouldIndex component={ReduxTableGrid} name="aliases" headers={headers}/>
                        </Col>
                    </Row>
                </FormForDevices>
            </Card>
        );
    }

}

ChainFeeding = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(ChainFeeding);

ChainFeeding = withRoles(props => {
    return {roles: [Roles._DEVICE_CONFIG], showComponent: true}
})(ChainFeeding);

const selector = formValueSelector(FormName);
ChainFeeding = connect(state => ({
    address: selector(state, "address"),
    device: selector(state, "device"),
    bridge: selector(state, "bridge"),
    edit: selector(state, "edit"),
    devices: state.farmDevices.devices,
    user: state.user.user,
    farm: state.location.farm
}))(ChainFeeding);

ChainFeeding = withTranslation()(ChainFeeding);
export default withRouter(ChainFeeding);
