import {openSSH} from "../../../../IOT/device-functions/GatewayFunctions";

export function submit(values, dispatch, props) {
    openSSH(values.gateway, values.port);
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (!values.gateway) {
        errors.gateway = t("required");
    }
    if (!values.port) {
        errors.port = t('required');
    }

    return errors;
}