import React from "react";
import Card from "../../../../../../components/basics/card/Card";
import FormForDevices from "../FormForDevices";
import {Field, reduxForm} from "redux-form";
import {submit, validate} from "./BridgeSubmit"
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import withRoles from "../../../../../../components/withRoles";
import {Roles} from "../../../../../../constans/roles";
import ReduxTextSwitch from "../../../../../../components/basics/text-switch/ReduxTextSwitch";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

export class Bridge extends React.Component {

    constructor(props) {
        super(props);

        this.props.initialize({
            Rs0Protocol: "WST",
            Rs1Protocol: "WST",
            BridgePeriphery: "None"
        }, true, {
            keepValues: true
        })
    }

    render() {
        const {handleSubmit, t} = this.props;
        return (
            <Card>
                <FormForDevices handleSubmit={handleSubmit} showGatewayInsteadOfBridge>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <label>RS485 0</label>
                            <Field
                                name={"Rs0Protocol"}
                                component={ReduxTextSwitch}
                                options={[
                                    {name: "MODBUS", value: "MBUS"},
                                    {name: "RFID", value: "RFID"},
                                    {name: "WST", value: "WST"}
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <label>RS485 1</label>
                            <Field
                                name={"Rs1Protocol"}
                                component={ReduxTextSwitch}
                                options={[
                                    {name: "MODBUS", value: "MBUS"},
                                    {name: "RFID", value: "RFID"},
                                    {name: "WST", value: "WST"}
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <label>{t("periphery")}</label>
                            <Field
                                name={"BridgePeriphery"}
                                component={ReduxTextSwitch}
                                options={[
                                    {name: t("grid.none"), value: "None"},
                                    {name: "LCD", value: "LCD"},
                                    {name: t("meters"), value: "Meters"}
                                ]}
                            />
                        </Col>
                    </Row>
                </FormForDevices>
            </Card>
        );
    }

}

export default compose(
    withRouter,
    withTranslation(),
    connect(state => ({
        user: state.user.user,
        farm: state.location.farm
    })),
    withRoles(props => {
        return {roles: [Roles._DEVICE_CONFIG], showComponent: true}
    }),
    reduxForm({
        form: "bridge",
        onSubmit: submit,
        validate
    })
)(Bridge);
