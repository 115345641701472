import React from "react";
import Card from "../../../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {compose} from "redux";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import ReduxLabeledSelect from "../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {withTranslation} from "react-i18next";
import {deleteFarmData} from "../../../../actions/serviceActions";

function mapStateToProps(state) {
    return {
        farms: state.farms.farms,
    }
}

function submit(values, dispatch, props) {
    const {FarmID} = values;
    const params = {
        "AnimalKind": 0,
        "DeleteEvents": false,
        "DeleteAnimals": false,
        "DeleteNotifications": false,
        "DeleteTechnologyGroups": false,
        "DeleteAnimalGroups": false,
        "DeleteAll": true
    };
    dispatch(deleteFarmData(FarmID, params, () => props.history.goBack()));
}

function validate(values, props) {
    const errors = {};
    if (!values.FarmID) {
        errors.FarmID = props.t("required");
    }
    return errors;
}

class DeleteFarmDataView extends React.Component {

    static getFarmsOptions = (props) => {
        const {farms} = props;
        return farms.map((f) => ({
            name: `Nazwa: ${f.FarmName} | ID: ${f.FarmID}`,
            value: f.FarmID
        }));
    };

    render() {
        const {handleSubmit} = this.props;
        const options = DeleteFarmDataView.getFarmsOptions(this.props);
        return (
            <Card>
                <form onSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <Field
                                name={"FarmID"}
                                label={"Ferma z której będą usunięte dane:"}
                                options={options}
                                component={ReduxLabeledSelect}
                                init={true}
                            />
                        </Col>
                    </Row>
                    <ButtonGroup fixed renderInPortal={false}>
                        <Button icon={<i className={"fas fa-trash"}/>} buttonStyle={"round"} buttonColor={"error"}/>
                    </ButtonGroup>
                </form>
            </Card>
        );
    }

}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    reduxForm({
        form: "delete-farm-data",
        onSubmit: submit,
        validate
    }),
)(DeleteFarmDataView)
