import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, FieldArray, formValueSelector, initialize, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import Card from "../../../../../components/basics/card/Card";
import FormForSettings from "../../../FormForSettings";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import Button from "../../../../../components/basics/button/Button";
import _ from "lodash";
import moment from "moment";
import {checkIfTimeRangeOverlaps, getTimeAsTimestamp} from "../../../../../utils/DateTimeUtils";
import {SettingTypes} from "../../../../../constans/settingTypes";
import {setSchedule} from "../../../../../IOT/device-functions/GatewayFunctions";
import {withRouter} from "react-router-dom";
import {DevType} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import Setting from "../../../../../beans/Setting";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

const FormName = 'settings-feeding-plans-edit-doses';


export class EditDoses extends React.Component {

    constructor(props) {
        super(props);
        const {dispatch, defaultValue} = this.props;//defaultValue
        dispatch(initialize(FormName, {
            doses: _.cloneDeep(_.get(defaultValue, "SetData.Doses", [{
                Start: "00:15",
                Stop: "23:45",
                Percent: 100
            }])).map(dose => {
                return {
                    percent: +dose.Percent,
                    start: _.isString(dose.Start) ? dose.Start : moment(dose.Start).format("HH:mm"),
                    stop: _.isString(dose.Stop) ? dose.Stop : moment(dose.Stop).format("HH:mm")
                }
            }),
            planName: _.get(defaultValue, "SetData.Name", "")
        }))
    }


    renderDoses = ({fields, meta: {touched, error, submitFailed}}) => {
        const {t} = this.props;
        let doseUsed = 0;
        fields.forEach((dose, index) => {
            doseUsed += fields.get(index).percent || 0
        });
        let doseLeft = 100 - doseUsed;
        doseLeft = doseLeft < 0 ? 0 : doseLeft;
        return (
            <div>
                <InfoBox boxColor={doseUsed !== 100 ? "warning" : "success"}>
                    {i18next.t("newSettings.feeding.plans.editDoses.usedXpercentOfDose", {amount: doseUsed})}
                </InfoBox>
                {
                    fields.map((member, index) => {
                        let field = fields.get(index);
                        return (
                            <Card>
                                <h5 className="flex-lg-row justify-content-between align-content-center">
                                    {i18next.t("dosage", {count: index + 1})}
                                    <Button
                                        onClick={() => (fields.length > 1) && fields.remove(index)} type={"button"}
                                        buttonStyle={"round"} className={"text"}
                                        icon={<i className={"fas fa-trash"}/>}/>
                                </h5>
                                <Row>
                                    <Col xs={4}>
                                        <Field
                                            name={`${member}.start`}
                                            id={`${member}.start`}
                                            label={t("newSettings.feeding.plans.editDoses.startTime")}
                                            type={"time"}
                                            component={ReduxLabeledInput}
                                        />
                                    </Col>
                                    <Col xs={4}>
                                        <Field
                                            name={`${member}.stop`}
                                            id={`${member}.stop`}
                                            label={t("newSettings.feeding.plans.editDoses.stopTime")}
                                            type={"time"}
                                            component={ReduxLabeledInput}
                                        />
                                    </Col>
                                    <Col xs={4}>
                                        <Field
                                            name={`${member}.percent`}
                                            id={`${member}.percent`}
                                            min={1}
                                            max={doseLeft + field.percent}
                                            valueFormatter={(value) => value ? `${value} %` : ''}
                                            label={t("dosage")}
                                            component={ReduxLabeledSlider}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })
                }
                {
                    (!!doseLeft && (fields.length < 6)) &&
                    <Card>
                        <h5 className="flex-lg-row justify-content-between align-content-center"
                            onClick={() => fields.push({start: "", stop: "", percent: 5})}>
                            {t("newSettings.feeding.plans.editDoses.addNextDose")} <i className={"fas fa-plus"}/>
                        </h5>
                    </Card>
                }
            </div>
        )
    };

    render() {
        const {handleSubmit, t} = this.props;
        return (
            <div className={`${FormName} overflow-hidden`}>
                <FormForSettings {...this.props} handleSubmit={handleSubmit} icon={<i className="fas fa-arrow-right"/>}
                >
                    <Row className="justify-content-center">
                        <Col md={12}>
                            <Card type={"col"}>
                                <Field
                                    name={"planName"}
                                    id={"planName"}
                                    label={t("planName")}
                                    type={"text"}
                                    component={ReduxLabeledInput}
                                />
                            </Card>
                        </Col>
                        <Col md={12}>
                            <FieldArray name="doses" component={this.renderDoses} rerenderOnEveryChange/>
                        </Col>
                    </Row>
                </FormForSettings>
            </div>
        );
    }
}


function submit(values, dispatch, props) {
    console.log(values, props);
    let {doses, planName} = _.cloneDeep(values);
    const {defaultValue, gateways, index} = props;
    doses.sort((d1, d2) => getTimeAsTimestamp(d1.start) - getTimeAsTimestamp(d2.start));
    doses = doses.map(dose => {
        return {
            Percent: +dose.percent,
            Start: getTimeAsTimestamp(dose.start),
            Stop: getTimeAsTimestamp(dose.stop)
        }
    });
    let dailyPlan = Setting.createSetting(SettingTypes.FEEDING_SCHEDULE, {
        Name: planName,
        Doses: doses,
        Index: index
    });
    console.log(dailyPlan);
    if (defaultValue) {
        dailyPlan.SetID = defaultValue.SetID;
        dailyPlan.DtaModTime = +new Date();
    }
    for (let i = 0; i < gateways.length; i++) {
        setSchedule(gateways[i], dailyPlan, i === 0);
    }
    props.history.push(`/${props.farm}/settings/feeding/plans`);
}

function validate(values, props) {
    const errors = {};
    const {doses, planName} = values;
    const {t} = props;
    let dosesErrors = [];
    if (!planName) {
        errors.planName = t("required");
    }
    if (!doses || !doses.length) {
        errors.doses = t("required");
    } else {
        dosesErrors = doses.map((dose, index) => {
            const doseError = {};
            if (_.isNil(dose.percent)) {
                doseError.percent = t("required");
            }
            if (!dose.start) {
                doseError.start = t("required");
            }
            if (!dose.stop) {
                doseError.stop = t("required");
            }
            if (dose.start && dose.stop) {
                let overlaps = false;
                doses.filter((d, i) => d.start && d.stop && i !== index).forEach(d => {
                    if (checkIfTimeRangeOverlaps(getTimeAsTimestamp(dose.start), getTimeAsTimestamp(dose.stop), getTimeAsTimestamp(d.start), getTimeAsTimestamp(d.stop))) {
                        overlaps = true;
                    }
                });
                if (overlaps) {
                    doseError.start = t("errors.duplicate");
                    doseError.stop = t("errors.duplicate");
                }
                if (getTimeAsTimestamp(dose.start) > getTimeAsTimestamp(dose.stop)) {
                    doseError.start = t('errors.mustBeBelow') + t("newSettings.feeding.plans.editDoses.stopTime");
                    doseError.stop = t("errors.mustBeAbove") + t("newSettings.feeding.plans.editDoses.startTime");
                }
            }
            return doseError
        });
    }

    errors.doses = dosesErrors;
    return errors
}


EditDoses = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(EditDoses);
const selector = formValueSelector(FormName);
EditDoses = connect(state => ({
    stages: selector(state, "stages"),
    gateways: state.farmDevices.devices.filter(dev => dev.DevType === DevType.GATEWAY),
    farm: state.location.farm
}))(EditDoses);
EditDoses = withTranslation()(EditDoses);
export default withRouter(EditDoses);

