import React, {Component} from 'react';
import {connect} from 'react-redux';
import FormForDevices from "../FormForDevices";
import {compose} from "redux";
import {Field, reduxForm} from "redux-form";
import {withTranslation} from "react-i18next";
import Card from "../../../../../../components/basics/card/Card";
import {submit, validate} from "./PigletScaleEditSubmit"
import ReduxLabeledInput from "../../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {Col, Row} from "react-bootstrap";

function mapStateToProps(state) {
    return {
        user: state.user.user
    };
}

class PigletScaleEdit extends Component {
    render() {
        const {handleSubmit, t} = this.props;
        return (
            <Card>
                <FormForDevices handleSubmit={handleSubmit} showBridge={false} showAddress={false}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <Field
                                component={ReduxLabeledInput}
                                name="Number"
                                label={t("newSettings.devices.form.number")}
                                type={"text"}
                                normalize={value => value ? value.slice(0, 3).toUpperCase() : null}
                            />
                        </Col>
                    </Row>
                </FormForDevices>
            </Card>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation(),
    reduxForm({
        form: "pigletScaleEdit",
        onSubmit: submit,
        validate
    })
)(PigletScaleEdit);