import React from "react";
import Card from "../../../../../../components/basics/card/Card";
import FormForDevices from "../FormForDevices";
import {formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {submit, validate} from "./AntennaRFIDSubmit";
import {withRouter} from "react-router-dom";
import withRoles from "../../../../../../components/withRoles";
import {Roles} from "../../../../../../constans/roles";

export class AntennaRFID extends React.Component {

    render() {
        const {handleSubmit, address, device, bridge, edit} = this.props;
        return (
            <Card>
                <FormForDevices handleSubmit={handleSubmit} showInterfacePicker address={+address} device={device}
                                showAdditionalButton={!!device} bridge={bridge} showHelp={!edit}/>
            </Card>
        );
    }

}

AntennaRFID = reduxForm({
    form: "antennaRFID",
    onSubmit: submit,
    validate
})(AntennaRFID);

AntennaRFID = withRoles(props => {
    return {roles: [Roles._DEVICE_CONFIG], showComponent: true}
})(AntennaRFID);

const selector = formValueSelector("antennaRFID");

export let _AntennaRFID = connect(state => ({
    address: selector(state, "address"),
    device: selector(state, "device"),
    edit: selector(state, "edit"),
    devices: state.farmDevices.devices,
    user: state.user.user,
    farm: state.location.farm,
    bridge: selector(state, "bridge"),
}))(AntennaRFID);

export default withRouter(AntennaRFID);
