import React, {Component} from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import _ from "lodash";
import PropTypes from "prop-types";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import {Link} from "react-router-dom";
import {DevType} from "../../../../../constans/devices";
import ListItem from "../../../../../components/basics/list/list-item/ListItem";
import Highlight from "../../../../../components/basics/highlight/Highlight";
import moment from "moment";
import {setSchedule} from "../../../../../IOT/device-functions/GatewayFunctions";
import {updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import Schedule from "../../../../../beans/settings/Schedule";
import {Col, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

class FeedingPlanItem extends Component {

    constructor(props) {
        super(props);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onUpdateOnDevicesClick = this.onUpdateOnDevicesClick.bind(this);
        this.state = {
            deleting: false
        }
    }

    onUpdateOnDevicesClick(e) {
        e.stopPropagation();
        const {gateways, plan} = this.props;
        if (plan instanceof Schedule) {
            gateways.forEach(gateway => {
                setSchedule(gateway, plan, false);
            })

        }
    }

    onRemoveClick(e) {
        e.stopPropagation();
        const {dispatch, plan, show, t} = this.props;
        let planCopy = _.cloneDeep(plan);
        if (planCopy instanceof Schedule) {
            planCopy.deleteSetting();
            show("confirm-modal", {
                title: t("newSettings.feeding.plans.feedingPlanItem.confirmDelete"),
                text: t("newSettings.feeding.plans.feedingPlanItem.confirmDeleteLong"),
                confirmText: t("yes"),
                onConfirmed: (props) => {
                    this.setState({
                        deleting: true
                    }, () => {
                        dispatch(updateSettingDynamoDB(planCopy, () => {
                            props.handleHide();
                            this.setState({
                                deleting: false
                            })
                        }, () => {
                            this.setState({
                                deleting: false
                            })
                        }));
                    })
                }
            })
        }
    }


    render() {
        const {plan, index, t} = this.props;
        const {deleting} = this.state;
        let doses = _.get(plan, "SetData.Doses", []);
        let name = _.get(plan, "SetData.Name", `Plan ${index + 1}`);
        return (
            <ListItem showNumber lp={index + 1} index={index} key={1}
                      className={"d-flex align-items-center mh-5rem overflow-hidden position-relative pt-3 pb-3"}>
                <Col xs={12}>
                    <Row>
                        {
                            !!plan && <>
                                <Col xs={12} sm={4} xl={4}>
                                    <div className={"font-weight-bold"}>{name}</div>
                                    <div
                                        className={"opacity-75"}>{i18next.t("newSettings.feeding.plans.feedingPlanItem.planXdays", {type: doses.length})}</div>
                                </Col>
                                <Col xs={12} sm={4} xl={6}>
                                    <div>
                                        {
                                            doses.map(dose => (
                                                <span className={"d-inline-block small mr-1"}><Highlight
                                                    highlightColor={"secondary"}>
                                                    {_.isString(dose.Start) ? dose.Start : moment(dose.Start).format("HH:mm")}
                                                    <bdi
                                                        className={"hide-on-small-device sm"}>-{_.isString(dose.Stop) ? dose.Stop : moment(dose.Stop).format("HH:mm")}</bdi>
                                                    <Highlight
                                                        highlightColor={"info"}>{dose.Percent}%</Highlight>
                                                </Highlight></span>))
                                        }
                                    </div>
                                    <div
                                        className={"opacity-75"}>{t("newSettings.feeding.plans.feedingPlanItem.doses")}</div>
                                </Col>
                            </>
                        }
                        {
                            !plan &&
                            <Col xs={12} className={"opacity-50"}>
                                <h5 className="justify-content-center">{t("newSettings.feeding.plans.feedingPlanItem.planNotConfigured")}</h5>
                            </Col>
                        }
                        <Col xs={12} sm={4} xl={2} className={"justify-content-end align-items-center d-flex"}>
                            <ButtonGroup className={"m-0"}>
                                <Link to={`/${this.props.farm}/settings/feeding/schedules/edit/${index}`}>
                                    <Button type={"button"} icon={<i className="fas fa-pen"/>} buttonStyle={"round"}/>
                                </Link>
                                <Button type={"button"} disabled={!plan} icon={<i className="fas fa-share"/>}
                                        buttonStyle={"round"}
                                        onClick={this.onUpdateOnDevicesClick}/>
                                <Button type={"button"} disabled={!plan} isLoading={deleting}
                                        icon={<i className="fas fa-trash"/>}
                                        buttonStyle={"round"}
                                        buttonColor={plan ? "error" : null} onClick={this.onRemoveClick}/>

                            </ButtonGroup>
                        </Col>
                    </Row>
                </Col>
            </ListItem>


        )
    }
}

FeedingPlanItem.propTypes = {
    plan: PropTypes.object.isRequired
};
FeedingPlanItem = connect(state => ({
    user: state.user.user,
    gateways: state.farmDevices.devices.filter(dev => dev.DevType === DevType.GATEWAY),
    farm: state.location.farm
}))(FeedingPlanItem);
FeedingPlanItem = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(FeedingPlanItem);

export default withTranslation()(FeedingPlanItem)
