import React from "react";
import PropTypes from "prop-types"
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import {Field} from "redux-form";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {Col, Collapse, Row} from "react-bootstrap";
import ReduxLabeledSelect from "../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {connect} from "react-redux";
import {DevType} from "../../../../../constans/devices";
import ReduxBridgeInterfaceSelector
    from "../../../../../components/bridge-interface-selector/ReduxBridgeInterfaceSelector";
import Menu from "../../../../../components/basics/menu/Menu";
import MenuItem from "../../../../../components/basics/menu/MenuItem";
import "./_form-for-devices.scss"
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import {getSensorsAndValves} from "../../../../../IOT/device-functions/SeparationCageFunctions";
import {getConfiguration} from "../../../../../IOT/device-functions/ClimateDriverFunctions";
import {getConfiguration as getConfigurationDispenser} from "../../../../../IOT/device-functions/DispenserDriverFunctions";
import {getConfiguration as getConfigurationScale} from "../../../../../IOT/device-functions/ScaleHubFunctions";
import {getLogs} from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import {createDeviceAddressList} from "../../../../../utils/DevicesUtils";
import {FORMAT} from "../../../../../constans/formatTypes";
import TextSwitch from "../../../../../components/basics/text-switch/TextSwitch";
import QrScanner from "../../../../../components/basics/qr-scanner/QRScanner";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import Tooltip from "../../../../../components/basics/tooltip/Tooltip";

export class FormForDevices extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            gateways: props.devices.filter(item => item.DevType === DevType.GATEWAY).map(gw => ({
                name: gw.Name,
                value: gw
            })),
            bridges: props.devices.filter(item => item.DevType === DevType.BRIDGE).map(bridge => ({
                name: bridge.Name,
                value: bridge
            })),
            event: null,
            didCheck: false,
            loading: false,
            error: false,
            time: 0,
            sendTime: 0,
            switchValue: "form"
        }
    }

    onSuccess = () => {
        console.log("ON SUCCESS");
        this.setState(state => ({
            loading: false,
            time: new Date().getTime() - state.sendTime
        }))
    };

    onError = (error, msg) => {
        console.log("ON ERROR", error, msg);
        this.setState({
            loading: false,
            error: true
        })
    };

    onSend = () => {
        this.setState({
            sendTime: new Date().getTime()
        })
    };

    onButtonClick = e => {
        this.setState({
            event: e
        })
    };

    onCheckConnectionClick = () => {
        const {device} = this.props;
        this.setState({
            didCheck: true,
            loading: true,
            error: false
        });
        switch (device.DevType) {
            case DevType.CAGE:
                getSensorsAndValves(device, this.onSuccess, this.onError, this.onSend);
                break;
            case DevType.CLIMATE:
                getConfiguration(device, this.onSuccess, this.onError, this.onSend);
                break;
            case DevType.DISPENSER:
                getConfigurationDispenser(device, this.onSuccess, this.onError, this.onSend);
                break;
            case DevType.SCALE:
                getConfigurationScale(device, this.onSuccess, this.onError, this.onSend);
                break;
            case DevType.DISPENSER_NRF:
                getLogs(device, this.onSuccess, this.onError, this.onSend);
                break;
            default:
                console.warn("Nie obsługiwane");
        }
    };

    getBoxColor() {
        const {loading, error} = this.state;
        if (loading) return "info";
        if (error) return "error";
        return "success";
    }

    onSwitchChange = value => {
        this.setState({
            switchValue: value
        })
    }

    onQrDecoded = value => {
        if (this.props.onQrScan) {
            this.props.onQrScan(value);
        }
    }

    render() {
        const {gateways, bridges, didCheck, loading, error, time, switchValue} = this.state;
        const {
            handleSubmit, showGatewayInsteadOfBridge, showInterfacePicker, fetching, address, device,
            showAdditionalButton, t, disableBridge, showAddress, qrScanner, showBridge, renderChildrenInQrScanner,
            bridge, showHelp
        } = this.props;
        return (
            <form onSubmit={handleSubmit} className="form-for-devices">
                <Collapse in={didCheck} unmountOnExit>
                    <div>
                        <InfoBox boxColor={this.getBoxColor()}>
                            {
                                loading &&
                                <>
                                    <i className="fas fa-circle-notch fa-spin fa-fw"/>
                                    {t("newSettings.devices.form.checking")}
                                </>
                            }
                            {
                                !loading &&
                                <>
                                    {
                                        error &&
                                        <>
                                            <i className="fas fa-exclamation-circle fa-fw"/>
                                            {t("newSettings.devices.form.error")}
                                        </>
                                    }
                                    {
                                        !error && time &&
                                        <>
                                            <i className="fas fa-check fa-fw"/>
                                            {i18next.t("newSettings.devices.form.received", {time: time})}
                                        </>
                                    }
                                </>
                            }
                        </InfoBox>
                    </div>
                </Collapse>
                {
                    qrScanner &&
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <TextSwitch onChange={this.onSwitchChange} value={switchValue} options={[
                                {
                                    name: t("newSettings.devices.form.form"),
                                    value: "form"
                                },
                                {
                                    name: t("newSettings.devices.form.qrScanner"),
                                    value: "qr"
                                }
                            ]}/>
                        </Col>
                    </Row>
                }
                {
                    switchValue === "qr" &&
                    <>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <QrScanner onFound={this.onQrDecoded}/>
                            </Col>
                        </Row>
                        {
                            renderChildrenInQrScanner && this.props.children
                        }
                    </>
                }
                {
                    switchValue === "form" &&
                    <>
                        <Row className="justify-content-center">
                            <Col lg={6} className={showHelp ? "with-help" : null}>
                                <Field
                                    name="name"
                                    component={ReduxLabeledInput}
                                    type="text"
                                    label={t("designation")}
                                />
                                {
                                    showHelp &&
                                    <Tooltip tooltipContent={
                                        <div>
                                            <div>{t("newSettings.devices.form.availablePatterns")}</div>
                                            <div>{"{{#}}"} - {t("newSettings.devices.form.insertIndex")}</div>
                                            <div>{"{{#X}}"}, {t("newSettings.devices.form.insertIndexFrom")}</div>
                                            <div>{"{{address}}"} - {t("newSettings.devices.form.insertAddress")}</div>
                                        </div>
                                    } placement={"bottom"} type={"info"}>
                                        <i className="fas fa-question-circle"/>
                                    </Tooltip>
                                }
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <Field
                                    name="alias"
                                    component={ReduxLabeledInput}
                                    type="text"
                                    label={t("newSettings.devices.form.alias")}
                                />
                            </Col>
                        </Row>
                        {
                            showAddress &&
                            <Row className="justify-content-center">
                                <Col lg={6}>
                                    <Field
                                        name="address"
                                        component={ReduxLabeledInput}
                                        type="text"
                                        label={t("adres")}
                                    />
                                </Col>
                            </Row>
                        }
                        {
                            showGatewayInsteadOfBridge &&
                            <Row className="justify-content-center">
                                <Col lg={6}>
                                    <Field
                                        name="gateway"
                                        component={ReduxLabeledSelect}
                                        label={t("newSettings.devices.form.gateway")}
                                        options={gateways}
                                    />
                                </Col>
                            </Row>
                        }
                        {
                            !showGatewayInsteadOfBridge && showBridge &&
                            <Row className="justify-content-center">
                                <Col lg={6}>
                                    <Field
                                        name="bridge"
                                        component={ReduxLabeledSelect}
                                        label={t("newSettings.devices.form.bridge")}
                                        options={bridges}
                                        disabled={disableBridge}
                                    />
                                </Col>
                            </Row>
                        }
                        {this.props.children}
                        {
                            showInterfacePicker &&
                            <Row className="justify-content-center">
                                <Col lg={6}>
                                    <Field
                                        name="interf"
                                        component={ReduxBridgeInterfaceSelector}
                                        addressToCheck={createDeviceAddressList(FORMAT.DEVICE_MANY_ADDR.test(`${address}`) ? `${address}` : '')}
                                        device={device}
                                        bridge={bridge}
                                    />
                                </Col>
                            </Row>
                        }
                    </>
                }
                <ButtonGroup renderInPortal={false} fixed>
                    {
                        showAdditionalButton &&
                        <>
                            <Button buttonStyle="round" buttonColor="info" icon={<i className="fas fa-ellipsis-v"/>}
                                    type="button" onClick={this.onButtonClick}/>
                            <Menu event={this.state.event}>
                                <MenuItem
                                    onClick={this.onCheckConnectionClick}>{t("newSettings.devices.form.check")}</MenuItem>
                            </Menu>
                        </>
                    }
                    <Button buttonStyle="round" buttonColor="success" icon={<i className="fas fa-plus"/>}
                            isLoading={fetching}/>
                </ButtonGroup>
            </form>
        );
    }

}

FormForDevices.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    showGatewayInsteadOfBridge: PropTypes.bool,
    showInterfacePicker: PropTypes.bool,
    address: PropTypes.number,
    device: PropTypes.object,
    showAdditionalButton: PropTypes.bool,
    disableBridge: PropTypes.bool,
    showAddress: PropTypes.bool,
    qrScanner: PropTypes.bool,
    showBridge: PropTypes.bool,
    onQrScan: PropTypes.func,
    renderChildrenInQrScanner: PropTypes.bool,
    bridge: PropTypes.object,
    showHelp: PropTypes.bool,
};

FormForDevices.defaultProps = {
    showGatewayInsteadOfBridge: false,
    showInterfacePicker: false,
    showAddress: true,
    showBridge: true
};

FormForDevices = connect(state => ({
    devices: state.farmDevices.devices,
    fetching: state.farmDevices.fetching,
}))(FormForDevices);

export default withTranslation()(FormForDevices);
