import React, {Component} from "react";
import "./_fifthStepView.scss"
import {bindActionCreators, compose} from "redux";
import {withTranslation} from "react-i18next";
import {show} from "redux-modal";
import {connect} from "react-redux";
import {ModalName as LogoutModal} from "../../../../../../../components/modals-new/delayed-action/DelayedActionModal";
import {withRouter} from "react-router-dom";

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

export class FifthStepView extends Component {

    componentDidMount() {
        const {dispatch, logout, history} = this.props;
        if (logout) {
            setTimeout(() => {
                dispatch(show(LogoutModal, {counter: 10}));
            }, 2000)
        } else {
            setTimeout(() => {
                history.replace({
                    pathname: "/farmSettings/billing",
                    state: {
                        forceFetch: true
                    }
                })
            }, 2000)
        }
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <div className="f-modal-alert">
                    <div className="f-modal-icon f-modal-success animate">
                        <span className="f-modal-line f-modal-tip animateSuccessTip"/>
                        <span className="f-modal-line f-modal-long animateSuccessLong"/>
                        <div className="f-modal-placeholder"/>
                        <div className="f-modal-fix"/>
                    </div>
                    <p className="successMsg">{t("success")}</p>
                    <p className="successRest">{t("subscriptions.summary.message")}</p>
                </div>
            </>
        )
    }
}

export default compose(
    withTranslation(),
    withRouter,
    connect(mapDispatchToProps)
)(FifthStepView);
