import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import PropTypes from "prop-types";
import {get, isNil} from "lodash";
import {connect} from "react-redux";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType, LoadedDataTypes} from "../../../../../constans/devices";
import {DispenserDriverCommandTypes} from "../../../../../constans/mqttMessages";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {convertWeightUnitTo} from "../../../../../utils/UnitUtils";
import {getFeedingUnit} from "../../../../../utils/SettingsUtils";
import {setParamOuts} from "../../../../../IOT/device-functions/DispenserDriverFunctions";
import {getBuildingsMap} from "../../../../../selectors/buildingsSelector";

export class ForageEfficiency extends React.Component {

    constructor(props) {
        super(props);
        this.state = ForageEfficiency.getSetup(this.props);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.changed) {
            return ForageEfficiency.getSetup(nextProps)
        }
    }

    static getSetup = (props) => {
        const {dispensers, shadows} = props;
        let device = dispensers[0];
        let values = {efficiency: []};
        let tmp = [];
        try {
            tmp = shadows.get(device.DevID).dispensers;
            values.loadedDataFrom = tmp ? LoadedDataTypes.SHADOW : LoadedDataTypes.NO_DATA;
            values.SetTime = get(shadows.get(device.DevID), 'metadata.dispensers', 0);
        } catch (e) {
            tmp = get(device, `Settings.Configuration.${DispenserDriverCommandTypes.SET_PARAM_OUTS}`);
            values.loadedDataFrom = tmp ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA;
            values.SetTime = get(tmp, 'SetTime', 0);
        }
        for (let i = 0; i < 20; i++) {
            const value = get(tmp, `[${i}].foodType1Yield`);
            if (isNil(value)) {
                values.loadedDataFrom = LoadedDataTypes.NO_DATA;
            }
            values.efficiency[i] = isNil(value) ? 750 : value;
        }
        values.device = device;
        return values;
    };


    onClick = () => {
        const {dispensers} = this.props;
        const {efficiency} = this.state;
        const devices = groupDevicesByGatewayID(dispensers);
        if (devices) {
            const data = [...efficiency.map((eff, i) => ({
                number: i + 1,
                foodType1Yield: eff,
                foodType2Yield: eff
            }))]
            for (let devList of devices.values()) {
                if (devList[DevType.DISPENSER].length) {
                    //tutaj nie martwimy sie ze depracated bo inna funkcja nie ustawimy :-)
                    setParamOuts(devList[DevType.DISPENSER], data)
                }
            }
        }
    };

    handleChangeEfficiencyTime = (value, index) => {
        const newEfficiency = this.state.efficiency.slice(0);
        newEfficiency[index] = value;
        this.setState({
            efficiency: newEfficiency,
            changed: true
        })
    };

    timeFormatter = (value) => {
        const {t} = this.props;
        const unit = getFeedingUnit();
        return t("newSettings.dispenserWST.efficiency.timeEfficiencyFormatter", {
            value: convertWeightUnitTo(value, {
                unit: unit,
                fixed: unit * 2,
                showUnit: true
            })
        })
    }

    labelFormatter = (idx) => {
        const {t, dispensers} = this.props;
        //jesli jest jeden dozo to wyswietlamy nazwy stanowisk jesli sa
        if (dispensers.length === 1) {
            const {buildingMap} = this.props;
            const placement = dispensers[0].PlcmntID.find(o => o.Adr === idx);
            if (placement) {
                const location = buildingMap.get(placement.PlcmntID);
                if (location) {
                    return t("newSettings.dispenserWST.efficiency.efficiencyForOut", {number: `${idx + 1} (${location.name.slice(-2).join(" - ")})`})
                }
            }
            return t("newSettings.dispenserWST.efficiency.efficiencyForOut", {number: idx + 1})
        }
        return t("newSettings.dispenserWST.efficiency.efficiencyForOut", {number: idx + 1})

    }

    render() {
        const {t} = this.props;
        const {loadedDataFrom, SetTime, device, efficiency} = this.state;
        return (
            <DeviceSettingsCard title={t("newSettings.dispenserWST.efficiency.title")}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[DispenserDriverCommandTypes.SET_PARAM_OUTS].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                {
                    efficiency.map((eff, idx) => (
                        <LabeledSlider key={idx} valueFormatter={this.timeFormatter}
                                       label={this.labelFormatter(idx)}
                                       value={efficiency[idx]}
                                       onChange={(value) => this.handleChangeEfficiencyTime(value, idx)} min={30}
                                       max={2550} step={10}/>
                    ))
                }
            </DeviceSettingsCard>
        );
    }

}

ForageEfficiency.propTypes = {
    dispensers: PropTypes.array.isRequired
};

ForageEfficiency = connect(state => ({
    shadows: state.shadows.shadows,
    buildingMap: getBuildingsMap(state)
}))(ForageEfficiency);

export default withTranslation()(ForageEfficiency);
