import React from "react";
import {withRouter} from "react-router-dom";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import Card from "../../../../components/basics/card/Card";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import {Col, Row} from "react-bootstrap";
import ReduxLabeledInput from "../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {Field, formValueSelector, reduxForm} from "redux-form";
import moment from "moment";
import {setMaintenanceDate} from "../../../../actions/serviceActions";
import ReduxCheckbox from "../../../../components/basics/checkbox/ReduxCheckbox";

function validate(values, props) {
    const errors = {};
    const {t} = props;
    if (!values.startTime) {
        errors.startTime = t("required");
    }
    if (!values.endTime) {
        errors.endTime = t("required");
    }
    return errors;
}

function submit(values, dispatch, props) {
    const {farm} = props;
    const {startTime, endTime, sendMail} = values;
    const parameters = {
        startTime: +new Date(startTime),
        endTime: +new Date(endTime),
    };
    dispatch(setMaintenanceDate(parameters, sendMail, () => props.history.push(`/${farm}/settings`)));
}

const _DateFormat = "YYYY-MM-DDTHH:mm";

class MaintenanceSettingView extends React.Component {

    constructor(props) {
        super(props);
        this.props.initialize({
            startTime: moment().format(_DateFormat),
            endTime: moment().add(1, "hour").format(_DateFormat)
        });
    }

    render() {
        const {handleSubmit, t} = this.props;
        return (
            <Card>
                <form onSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <Field
                                label={t("maintenance.maintenanceStartDate")}
                                type="datetime-local"
                                component={ReduxLabeledInput}
                                name="startTime"
                            />
                            <Field
                                label={t("maintenance.maintenanceEndDate")}
                                type="datetime-local"
                                component={ReduxLabeledInput}
                                name="endTime"
                            />
                            <Field
                                label={t("maintenance.sendMails")}
                                component={ReduxCheckbox}
                                name="sendMail"
                                id="sendMail"
                            />
                        </Col>
                    </Row>
                    <ButtonGroup fixed renderInPortal={false}>
                        <Button icon={<i className={"fad fa-paper-plane"}/>} buttonStyle={"round"} buttonColor={"success"}/>
                    </ButtonGroup>
                </form>
            </Card>
        );
    }

}

MaintenanceSettingView = reduxForm({
    form: "maintenanceSettingView",
    onSubmit: submit,
    validate,
})(MaintenanceSettingView);

const selector = formValueSelector("maintenanceSettingView");
MaintenanceSettingView = connect(state => ({
    startTime: selector(state, "startTime"),
    endTime: selector(state, "endTime"),
}))(MaintenanceSettingView);

MaintenanceSettingView = withRouter(MaintenanceSettingView);
MaintenanceSettingView = withRoles({roles: [Roles._SERVICE], showComponent: true})(MaintenanceSettingView);
export default withTranslation()(MaintenanceSettingView);
