import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import PropTypes from "prop-types";
import _ from "lodash";
import {connect} from "react-redux";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType, LoadedDataTypes} from "../../../../../constans/devices";
import {setMotorSetup} from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import {DispenserNRFCommandTypes} from "../../../../../constans/mqttMessages";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {checkIfUserIsService} from "../../../../../utils/NewRolesUtils";
import {removeTrash} from "../../../../../utils/IOTUtils";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class MotorSetup extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getMotorSetup();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState(this.getMotorSetup(nextProps))
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    getMotorSetup = (props = this.props) => {
        const {dispensers, shadows} = props;
        let device = dispensers[0];
        try {
            let shadow = shadows.get(device.DevID);
            return {
                timeOn: shadow.motorSetup.timeOn || 100,
                timeBetweenImpulses: shadow.motorSetup.timeBetweenImpulses || 50,
                timeBreak: shadow.motorSetup.timeBreak || 100,
                currentOverloadTime: shadow.motorSetup.currentOverloadTime || 20,
                timeAbsent: shadow.motorSetup.timeAbsent || 60000,
                loadedDataFrom: LoadedDataTypes.SHADOW,
                // SetTime: shadow.metadata.motorSetup,
                device
            }
        } catch (e) {
            return {
                timeOn: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_MOTOR_SETUP}.timeOn`, 100),
                timeBetweenImpulses: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_MOTOR_SETUP}.timeBetweenImpulses`, 50),
                timeBreak: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_MOTOR_SETUP}.timeBreak`, 100),
                currentOverloadTime: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_MOTOR_SETUP}.currentOverloadTime`, 20),
                timeAbsent: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_MOTOR_SETUP}.timeAbsent`, 60000),
                SetTime: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_MOTOR_SETUP}.SetTime`, 0),
                loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[DispenserNRFCommandTypes.SET_MOTOR_SETUP] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
                device
            };
        }
    };


    onClick = () => {
        const {dispensers} = this.props;
        const devices = groupDevicesByGatewayID(dispensers);
        if (devices) {
            const data = removeTrash(this.state);
            for (let devList of devices.values()) {
                devList[DevType.BROADCAST].length && setMotorSetup(devList[DevType.BROADCAST], data);
                devList[DevType.DISPENSER_NRF_MULTI].length && setMotorSetup(devList[DevType.DISPENSER_NRF_MULTI], data);
                devList[DevType.DISPENSER_NRF].length && setMotorSetup(devList[DevType.DISPENSER_NRF], data);
            }
        }
    };

    setValue = (key, value) => {
        this.setState({
            [key]: value,
            changed: true
        })
    };

    render() {
        const {t} = this.props;
        const {timeOn, timeBetweenImpulses, timeBreak, currentOverloadTime, timeAbsent, loadedDataFrom, SetTime, device} = this.state;
        return (
            <DeviceSettingsCard title={t("newSettings.dispenserNRF.motorSetup.title")} onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[DispenserNRFCommandTypes.SET_MOTOR_SETUP].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledSlider value={timeBreak} label={t("newSettings.dispenserNRF.motorSetup.timeBreak")} min={0}
                               max={300 * 1000} step={5000}
                               valueFormatter={(value) => `${Math.floor(value / 1000)}s`}
                               onChange={(value) => this.setValue("timeBreak", value)}
                />
                {
                    checkIfUserIsService() &&
                    <>
                        <LabeledSlider value={timeOn} label={t("newSettings.dispenserNRF.motorSetup.timeOn")} min={100}
                                       max={5 * 1000} step={100}
                                       valueFormatter={(value) => `${value}ms`}
                                       onChange={(value) => this.setValue("timeOn", value)}
                        />
                        <LabeledSlider value={timeBetweenImpulses}
                                       label={t("newSettings.dispenserNRF.motorSetup.timeBetweenImpulses")} min={50}
                                       max={5000}
                                       step={5}
                                       valueFormatter={(value) => `${value}ms`}
                                       onChange={(value) => this.setValue("timeBetweenImpulses", value)}
                        />
                        <LabeledSlider value={currentOverloadTime}
                                       label={t("newSettings.dispenserNRF.motorSetup.currentOverloadTime")} min={20}
                                       max={5000}
                                       step={5}
                                       valueFormatter={(value) => `${value}ms`}
                                       onChange={(value) => this.setValue("currentOverloadTime", value)}
                        />
                        <LabeledSlider value={timeAbsent} label={t("newSettings.dispenserNRF.motorSetup.timeAbsent")}
                                       min={0} max={5 * 1000 * 60}
                                       step={1000 * 30}
                                       valueFormatter={(value) => `${(value / (1000 * 60)).toFixed(1)}min`}
                                       onChange={(value) => this.setValue("timeAbsent", value)}
                        />
                    </>
                }
            </DeviceSettingsCard>
        );
    }

}

MotorSetup.propTypes = {
    dispensers: PropTypes.array.isRequired
};

MotorSetup = connect(state => ({
    shadows: state.shadows.shadows,
}))(MotorSetup);

export default withTranslation()(MotorSetup);