import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Field, reduxForm} from "redux-form";
import {Col, Row} from "react-bootstrap";
import ReduxLabeledInput from "../../../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import RequiredText from "../../../../../../../components/basics/required-text/RequiredText";
import ButtonGroup from "../../../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../../../components/basics/button/Button";

function submit(values, dispatch, props) {
    props.onNextStepData({invoice: values});
}

function validate(values, props) {
    const errors = {};
    const {t} = props;
    if (!values.companyName) {
        errors.companyName = t("required");
    }
    if (!values.nip) {
        errors.nip = t("required");
    }
    if (!values.streetAndHouseNumber) {
        errors.streetAndHouseNumber = t("required");
    }
    if (!values.city) {
        errors.city = t("required");
    }
    if (!values.country) {
        errors.country = t("required");
    }
    if (!values.postalCode) {
        errors.postalCode = t("required");
    }
    if (!values.phone) {
        errors.phone = t("required");
    }
    if (!values.email) {
        errors.email = t("required");
    }
    return errors;
}

class InvoiceStepView extends React.Component {

    render() {
        const {handleSubmit, onCancel, onBackStep, t} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <Field
                            id="companyName"
                            name="companyName"
                            component={ReduxLabeledInput}
                            label={t("nameAndSurrname")}
                            required
                        />
                        <Field
                            id="nip"
                            name="nip"
                            component={ReduxLabeledInput}
                            label={"NIP"}
                            required
                        />
                        <Field
                            id="streetAndHouseNumber"
                            name="streetAndHouseNumber"
                            component={ReduxLabeledInput}
                            label={t("streetAndHouseNumber")}
                            required
                        />
                        <Field
                            id="city"
                            name="city"
                            component={ReduxLabeledInput}
                            label={t('city')}
                            required
                        />
                        <Field
                            id="region"
                            name="region"
                            component={ReduxLabeledInput}
                            label={t("region")}
                        />
                        <Field
                            id="country"
                            name="country"
                            component={ReduxLabeledInput}
                            label={t("country")}
                            required
                        />
                        <Field
                            id="postalCode"
                            name="postalCode"
                            component={ReduxLabeledInput}
                            label={t("postalCode")}
                            required
                        />
                        <Field
                            id="phone"
                            name="phone"
                            component={ReduxLabeledInput}
                            label={t("phone")}
                            required
                        />
                        <Field
                            id="email"
                            name="email"
                            component={ReduxLabeledInput}
                            label={t("email")}
                            required
                        />
                        <Field
                            id="krsNumber"
                            name="krsNumber"
                            component={ReduxLabeledInput}
                            label={t("krs")}
                        />
                        <Field
                            id="www"
                            name="www"
                            component={ReduxLabeledInput}
                            label={"WWW"}
                        />
                        <RequiredText/>
                    </Col>
                    <ButtonGroup fixed renderInPortal={false}>
                        <Button buttonColor={"error"} buttonStyle={"round"} icon={<i className="fas fa-times"/>}
                                onClick={onCancel}/>
                        <Button buttonColor={"success"} buttonStyle={"round"} onClick={onBackStep}
                                icon={<i className="fas fa-arrow-left"/>}/>
                        <Button buttonColor={"success"} buttonStyle={"round"}
                                icon={<i className="fas fa-arrow-right"/>}/>
                    </ButtonGroup>
                </Row>
            </form>
        );
    }
}

export default compose(
    withTranslation(),
    reduxForm({
        form: "invoice-step-view",
        onSubmit: submit,
        validate
    })
)(InvoiceStepView);
