import React from "react";
import {connect} from "react-redux";
import Card from "../../../../components/basics/card/Card";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import Button from "../../../../components/basics/button/Button";
import {cloneDeep, isEqual} from "lodash"
import {ModalName as DictionaryModalName} from "../../../../components/modals-new/dictionary-modal/DictionaryModal";
import {ModalName as EditModalName} from "../../../../components/modals-new/edit-dictionary/EditDictionaryModal";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {updateDictionaryDynamoDB} from "../../../../actions/dictionaryActions";
import PropTypes from "prop-types"
import {ModalName as ConfirmModalName} from "../../../../components/modals-new/confirm-modal/ConfirmModal";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {getTranslationPath} from "../../../../utils/EventUtils";
import DefaultMobileRow from "../../../../components/basics/table-grid/default-mobile-row/DefaultMobileRow";

export class DictionaryItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: (isEqual("cimode", this.props.lang) ? "pl" : this.props.lang),
            dictionary: DictionaryItem.getDictionary(props)
        }
    }

    static getDictionary = (props) => {
        const {dictionary, type} = props;
        for (let dict in dictionary) {
            if (dictionary[dict].Type === type) return dictionary[dict];
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.lang, prevProps.lang) || !isEqual(this.props.dictionary, prevProps.dictionary)) {
            this.setState({
                lang: (isEqual("cimode", this.props.lang) ? "pl" : this.props.lang),
                dictionary: DictionaryItem.getDictionary(this.props)
            })
        }
    }

    onDeleteConfirm = (props) => {
        const {dictionary} = this.state;
        let clone = cloneDeep(dictionary);
        for (let lang in clone.WData) {
            clone.WData[lang] = clone.WData[lang].filter(item => item.ID !== props.value.ID && !item.isDefault);
            for (let item of clone.WData[lang]) {
                delete item.isDefault;
                delete item.isShowing;
            }
        }
        delete clone.$loki;
        this.props.dispatch(updateDictionaryDynamoDB(clone, this.props.user.ClientID, this.props.user.LocalUserID));
        props.handleHide();
    };

    onDeleteClick(props) {
        this.props.show(ConfirmModalName, {
            text: i18next.t("newSettings.dictionary.manage.item.confirmDeleteText", {type: props.value.Value}),
            title: this.props.t("newSettings.dictionary.manage.item.confirmDeleteTitle"),
            confirmText: this.props.t("yes"),
            onConfirmed: (modalProps) => this.onDeleteConfirm({...props, ...modalProps})
        })
    }

    onAddClick() {
        const {dictionary} = this.state;
        this.props.show(DictionaryModalName, {title: this.getName(), type: dictionary.Type});
    }

    onEditClick(props) {
        const {dictionary} = this.state;
        this.props.show(EditModalName, {title: props.value.Value, type: dictionary.Type, dictionaryItem: props.value});
    }

    getName() {
        const {type} = this.props;
        const translationPath = getTranslationPath(type);
        return translationPath ? this.props.t(translationPath) : type;
    }

    render() {
        const {dictionary} = this.state;
        const {lang} = this.state;
        const headers = [
            {
                name: this.props.t("designation"),
                field: "Value",
                _mobileHeader: true
            }
        ];

        headers.push({
            name: "",
            component: props => !props.value.isDefault &&
                <i className="fas fa-pencil-alt" onClick={() => this.onEditClick(props)}/>,
            itemClassName: "index",
            headerClassName: "index",
            _floating: true
        });

        headers.push({
            name: "",
            component: props => !props.value.isDefault &&
                <i className="fas fa-trash" onClick={() => this.onDeleteClick(props)}/>,
            itemClassName: "index",
            headerClassName: "index",
            _floating: true
        });

        return (
            <Card>
                <h5 className="flex-lg-row justify-content-between align-content-center">
                    {this.getName()}
                    <Button buttonColor={"primary"} className={"text"} buttonStyle={"round"}
                            icon={<i className="fas fa-plus"/>}
                            onClick={() => this.onAddClick()}/>
                </h5>
                <TableGrid data={dictionary.WData[lang].sort((a, b) => a.Value > b.Value ? 1 : -1)}
                           headers={headers} shouldIndex mobileRow={<DefaultMobileRow/>}/>
            </Card>
        );
    }

}

DictionaryItem = connect(state => ({
    dictionary: state.dictionary,
    lang: state.language.lang.lang,
    user: state.user.user,
}))(DictionaryItem);

DictionaryItem = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(DictionaryItem);

DictionaryItem.propTypes = {
    type: PropTypes.string.isRequired
};

export default withTranslation()(DictionaryItem);
