import React from "react";
import Card from "../../../../../../components/basics/card/Card";
import FormForDevices from "../FormForDevices";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {submit, validate} from "./CageSubmit";
import {withRouter} from "react-router-dom";
import withRoles from "../../../../../../components/withRoles";
import {Roles} from "../../../../../../constans/roles";
import ReduxCheckbox from "../../../../../../components/basics/checkbox/ReduxCheckbox";
import {Col, Row} from "react-bootstrap";
import ReduxLabeledSelect from "../../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import ThermoEye from "../../../../../../beans/devices/ThermoEye";
import _ from "lodash";
import {withTranslation} from "react-i18next";

export class Cage extends React.Component {

    render() {
        const {handleSubmit, address, device, devices, t, bridge, edit} = this.props;
        const options = _.cloneDeep(devices || []).filter(dev=>dev instanceof ThermoEye).map(o=>({
            name: o.Name,
            value: o.DevID
        }));
        return (
            <Card>
                <FormForDevices handleSubmit={handleSubmit} showInterfacePicker address={+address} device={device}
                                showAdditionalButton={!!device} bridge={bridge} showHelp={!edit}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <hr/>
                            <h5>{t("newSettings.devices.form.addons")}</h5>
                            <Field
                                name={"marker1"}
                                id={"marker1"}
                                component={ReduxCheckbox}
                                label={t("newSettings.devices.form.marker1")}
                            />
                            <Field
                                name={"marker2"}
                                id={"marker2"}
                                component={ReduxCheckbox}
                                label={t("newSettings.devices.form.marker2")}
                            />
                            <Field
                                name={"thermoeye"}
                                id={"thermoeye"}
                                component={ReduxLabeledSelect}
                                options={options || []}
                                label={t("newSettings.devices.form.thermoEye")}
                            />
                        </Col>
                    </Row>
                </FormForDevices>
            </Card>
        );
    }

}

Cage = reduxForm({
    form: "cage",
    onSubmit: submit,
    validate
})(Cage);

Cage = withRoles(props => {
    return {roles: [Roles._DEVICE_CONFIG], showComponent: true}
})(Cage);

const selector = formValueSelector("cage");
Cage = connect(state => ({
    address: selector(state, "address"),
    device: selector(state, "device"),
    bridge: selector(state, "bridge"),
    edit: selector(state, "edit"),
    devices: state.farmDevices.devices,
    user: state.user.user,
    farm: state.location.farm
}))(Cage);

Cage = withTranslation()(Cage);
export default withRouter(Cage);
