import Auth from "@aws-amplify/auth";
import {SubmissionError} from "redux-form";
import i18next from "i18next";
import {addNotification} from "reapop";
import {myID} from "../../../../libs/generateID";
import {ModalName as DelayActionModal} from "../../../../components/modals-new/delayed-action/DelayedActionModal";

export function submit(values, dispatch, props) {
    const {password, passwordOld} = values;
    const {show} = props;
    return Auth.currentAuthenticatedUser()
        .then(user => {
            return Auth.changePassword(user, passwordOld, password);
        })
        .then(data => {
            dispatch(addNotification({
                id: myID(),
                title: i18next.t("popNotifications.changePassword"),
                message: i18next.t("popNotifications.changePasswordSuccess"),
                status: "success",
                dismissible: true,
                dismissAfter: 5000
            }));
            show(DelayActionModal);
            return data;
        })
        .catch(err => {
            console.error(err);
            dispatch(addNotification({
                id: myID(),
                title: i18next.t("popNotifications.changePassword"),
                message: i18next.t("popNotifications.changePasswordFailure"),
                status: "error",
                dismissible: true,
                dismissAfter: 15000
            }));
            throw new SubmissionError({_error: err.message})
        })
}

export function validate(values, props) {
    const errors = {};
    const {password, passwordConfirmation, passwordOld} = values;
    const {t} = props;
    if (!passwordOld) {
        errors.passwordOld = t("required");
    }
    if (password !== passwordConfirmation) {
        errors.passwordConfirmation = t("required");
    }
    if (!password) {
        errors.password = t("required");
    } else {
        if (password.length < 6) {
            errors.password = i18next.t("errors.shouldBeAtleastXLong", {amount: 6});
        }
    }
    return errors;
}
