import React from "react";
import FormForSettings from "../../FormForSettings";
import PropTypes from "prop-types"
import "./_notification-settings.scss"

export default class FormForNotifications extends React.Component{

    render() {
        const {handleSubmit, children} = this.props;
        return (
            <FormForSettings handleSubmit={handleSubmit}>
                <div className="notification-settings">
                    {children}
                </div>
            </FormForSettings>
        );
    }

}

FormForNotifications.propTypes = {
    handleSubmit: PropTypes.func.isRequired
};