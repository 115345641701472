import React, {Component} from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import _ from "lodash";
import PropTypes from "prop-types";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import {Link} from "react-router-dom";
import {DevType} from "../../../../../constans/devices";
import ListItem from "../../../../../components/basics/list/list-item/ListItem";
import {setFeedEFCTime} from "../../../../../IOT/device-functions/GatewayFunctions";
import {updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import {convertWeightUnitTo} from "../../../../../utils/UnitUtils";
import Forage from "../../../../../beans/settings/Forage";
import {updateLocation} from "../../../../../actions/farmsActions";
import {UnitTypes} from "../../../../../constans/unitTypes";
import moment from "moment";
import {ModalName as ForageReactivateModalName} from "../../../../../components/modals-new/forage-reactivate/ForageReactivate";
import {Col, Collapse, Row} from "react-bootstrap";
import TableGrid from "../../../../../components/basics/table-grid/TableGrid";
import {swapForageIDonChambers} from "../../../../../utils/BuildingUtils";
import {withTranslation} from "react-i18next";

class FeedingForageItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
            show: false
        }
    }

    onUpdateOnDevicesClick = (e) => {
        e.stopPropagation();
        const {gateways, forage} = this.props;
        if (forage instanceof Forage) {
            gateways.forEach(gateway => {
                setFeedEFCTime(gateway, forage);
            })

        }
    };

    onReactivateClick = (e) => {
        const {show, forage} = this.props;
        let forageCopy = _.cloneDeep(forage);
        show(ForageReactivateModalName, {
            forage: forageCopy
        })
    };

    onRemoveClick = (e) => {
        e.stopPropagation();
        const {forage, t, show, dispatch, buildings} = this.props;
        let forageCopy = _.cloneDeep(forage);
        if (forageCopy instanceof Forage) {
            forageCopy.deleteSetting();
            show("confirm-modal", {
                title: t("newSettings.feeding.forages.feedingForageItem.confirmInactive"),
                text: t("newSettings.feeding.forages.feedingForageItem.confirmInactiveLong"),
                confirmText: t("yes"),
                onConfirmed: (props) => {
                    this.setState({
                        deleting: true
                    }, () => {
                        const buildingsToUpdate = swapForageIDonChambers(buildings, null, forageCopy.SetID);
                        for (let building of buildingsToUpdate) {
                            dispatch(updateLocation(building));
                        }
                        dispatch(updateSettingDynamoDB(forageCopy, () => {
                            props.handleHide();
                            this.setState({
                                deleting: false
                            })
                        }, () => {
                            this.setState({
                                deleting: false
                            })
                        }));
                    })
                }
            })
        }
    }

    handleToggleShow = () => {
        this.setState((state) => ({show: !state.show}));
    };

    render() {
        const {forage, index, t, isHistorical, forageIngredients} = this.props;
        const {deleting, show} = this.state;
        let name = _.get(forage, "SetData.Name", `Pasza ${index + 1}`);
        let DtaDelTime = _.get(forage, "DtaDelTime", 0);
        let color = _.get(forage, "SetData.Color", `#FFFFFF`);

        const recipeHeaders = [
            {
                name: t("newSettings.feeding.forages.feedingForageItem.ingredient"),
                field: "",
                valueFormatter: value => _.get((forageIngredients.find(s => s.SetID && (s.SetID === value.IngredientID))), "WData.Name", value.Name)
            },
            {
                name: t("newSettings.feeding.forages.feedingForageItem.content"),
                field: "Percentage",
                valueFormatter: value => `${value}%`
            },
            {
                name: t("newSettings.feeding.forages.feedingForageItem.cost"),
                field: "Cost",
                valueFormatter: value => `${value}PLN/${convertWeightUnitTo(1000 * 1000, {
                    unit: UnitTypes.BIG,
                    fixed: 1,
                    showUnit: true
                })}`
            }
        ];

        return (
            <>
                <ListItem lp={index + 1} index={index} color={forage ? color : null} onClick={this.handleToggleShow}
                          className={"overflow-hidden position-relative pt-2 pb-2 mh-5rem align-items-center"}>
                    <Col xs={12}>
                        <Row>
                            <Col xs={2}>
                                {
                                    !isHistorical &&
                                    <h3>
                                        {index + 1}
                                    </h3>
                                }
                                {
                                    !!isHistorical &&
                                    <>
                                        <div
                                            className={"font-weight-bold"}>{moment(DtaDelTime).format("DD.MM.YY")}</div>
                                        <div
                                            className={"opacity-75"}>{t("newSettings.feeding.forages.feedingForageItem.deactivateDate")}</div>
                                    </>
                                }
                            </Col>
                            {
                                !!forage &&
                                <>
                                    <Col xs={3}>
                                        <div className={"font-weight-bold"}>{name}</div>
                                        <div
                                            className={"opacity-75"}>{t("newSettings.feeding.forages.feedingForageItem.forageName")}</div>
                                    </Col>
                                    <Col xs={2}>
                                        <div
                                            className={"font-weight-bold"}>{forage.getCost()}PLN/{convertWeightUnitTo(1000 * 1000, {
                                            unit: UnitTypes.BIG,
                                            fixed: 1,
                                            showUnit: true
                                        })}</div>
                                        <div
                                            className={"opacity-75"}>{t("newSettings.feeding.forages.feedingForageItem.cost")}</div>
                                    </Col>
                                </>
                            }
                            {
                                !forage &&
                                <Col xs={7} className="opacity-50">
                                    <h5 className="justify-content-center">{t("newSettings.feeding.forages.feedingForageItem.forageNotConfigured")}</h5>
                                </Col>
                            }
                            <Col xs={3} className={"justify-content-end align-items-center d-flex"}>
                                <ButtonGroup className={"m-0"}>
                                    {
                                        !!isHistorical &&
                                        <Button
                                            title={t("newSettings.feeding.forages.feedingForageItem.activate")}
                                            type={"button"} isLoading={deleting}
                                            icon={<i className="fas fa-power-off"/>}
                                            buttonStyle={"round"}
                                            buttonColor={"success"} onClick={this.onReactivateClick}/>
                                    }
                                    {
                                        !isHistorical &&
                                        <>
                                            <Link to={`/${this.props.farm}/settings/feeding/forages/edit/${index}`}>
                                                <Button
                                                    title={forage ? t("edit") : t("addNew")}
                                                    type={"button"}
                                                    icon={forage ? <i className="fas fa-pen"/> :
                                                        <i className={"fas fa-plus"}/>}
                                                    buttonStyle={"round"}/>
                                            </Link>
                                            {
                                                !!forage &&
                                                <>
                                                    <Button
                                                        title={t("newSettings.feeding.forages.feedingForageItem.update")}
                                                        type={"button"}
                                                        icon={<i className="fas fa-share"/>}
                                                        buttonStyle={"round"}
                                                        onClick={this.onUpdateOnDevicesClick}/>
                                                    <Button
                                                        title={t("newSettings.feeding.forages.feedingForageItem.deactivate")}
                                                        type={"button"}
                                                        isLoading={deleting}
                                                        icon={<i className="fas fa-power-off"/>}
                                                        buttonStyle={"round"}
                                                        buttonColor={"error"} onClick={this.onRemoveClick}/>
                                                </>
                                            }
                                        </>
                                    }
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Col>
                </ListItem>
                <Collapse in={show && !!forage} unmountOnExit mountOnEnter>
                    <div className="d-flex w-100">
                        <ListItem className={"w-100 enable-overflow"} style={{minHeight: "10rem"}}
                                  disableHover={true}
                                  index={2}>
                            <Col xs={12}>
                                <TableGrid className={"w-100"} data={(forage && forage.getRecipe()) || []}
                                           headers={recipeHeaders}/>
                            </Col>
                        </ListItem>
                    </div>
                </Collapse>
            </>


        )
    }
}

FeedingForageItem.propTypes = {
    forage: PropTypes.object.isRequired
};
FeedingForageItem = connect(state => ({
    forageIngredients: state.dictionary.forageIngredients,
    user: state.user.user,
    buildings: state.farms.buildings,
    gateways: state.farmDevices.devices.filter(dev => dev.DevType === DevType.GATEWAY),
    farm: state.location.farm
}))(FeedingForageItem);
FeedingForageItem = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(FeedingForageItem);

export default withTranslation()(FeedingForageItem);
