import {DevType, FrameTypes, Interfaces} from "../../../../../../constans/devices";
import {updateDeviceDynamoDB} from "../../../../../../actions/devicesActions";
import {FORMAT} from "../../../../../../constans/formatTypes";
import {createDeviceAddressList} from "../../../../../../utils/DevicesUtils";
import _ from "lodash";
import i18next from "i18next";
import {
    createDevices,
    createDevicesNotificationFailure,
    createDevicesNotificationSuccess
} from "../../../../../../api/devices/createDevices";

export function submit(values, dispatch, props) {
    const {address, serial, name, number, bridge, edit, device, alias} = values;

    if (!edit) {
        const additionalData = {};
        _.isFinite(serial) && (additionalData.Serial = serial);
        number && (additionalData.Number = number);
        const devices = createDeviceAddressList(address, DevType.WATER_FLOW_METER).map(adr => ({
            DevType: DevType.WATER_FLOW_METER,
            Name: name,
            Address: adr,
            ParentID: bridge.DevID,
            Interface: Interfaces.BRIDGE_MBUS,
            Alias: alias,
            AdditionalData: additionalData
        }));
        return createDevices(devices).then(res => {
            props.reset();
            createDevicesNotificationSuccess(res);
        }).catch(e => {
            createDevicesNotificationFailure(e);
        });
    } else {
        let deviceClone = device.clone();
        deviceClone.Address = Number(address);
        deviceClone.Name = name;
        deviceClone.ParentID = bridge.DevID;
        deviceClone.Serial = +serial;
        alias ? (deviceClone.Alias = alias) : (delete deviceClone.Alias);
        number ? (deviceClone.Number = number) : (delete deviceClone.Number);
        deviceClone.Protocol = FrameTypes.MODBUS;
        deviceClone.VerHard = "0.1";
        deviceClone.VerSoft = "0.1";
        dispatch(updateDeviceDynamoDB(deviceClone, deviceClone.FarmID, props.user.ClientID, props.user.LocalUserID, null, () => {
            props.history.push(`/${props.farm}/settings/devices/manage`)
        }));
    }
}

export function validate(values, props) {
    const errors = {};

    if (!values.name) {
        errors.name = props.t("required");
    }
    if (!(FORMAT.DEVICE_MANY_ADDR_TE.test(`${values.address}`) && (!values.edit || FORMAT.NUMBER_DEC_OR_HEX.test(`${values.address}`)))) {
        errors.address = i18next.t("errors.addressError", {
            amount1: `${1} (0x1)`,
            amount2: `127 (0x${(15).toString(16).toUpperCase()})`,
            amount3: '14'
        });
    }
    if (!values.bridge) {
        errors.bridge = props.t("required");
    }
    if (!_.isFinite(values.serial)) {
        errors.serial = props.t("required");
    }

    return errors;
}