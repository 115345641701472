import {DevType, FrameTypes} from "../../../../../../constans/devices";
import {updateDeviceDynamoDB} from "../../../../../../actions/devicesActions";
import {FORMAT} from "../../../../../../constans/formatTypes";
import {
    createDevices,
    createDevicesNotificationFailure,
    createDevicesNotificationSuccess
} from "../../../../../../api/devices/createDevices";

export function submit(values, dispatch, props) {
    const {address, name, gateway, edit, device, alias, Rs0Protocol, Rs1Protocol, BridgePeriphery} = values;
    if (!edit) {
        return createDevices([{
            DevType: DevType.BRIDGE,
            Name: name,
            Address: 1,
            ParentID: gateway.DevID,
            Rs0Protocol,
            Rs1Protocol,
            BridgePeriphery
        }]).then(res => {
            props.reset();
            createDevicesNotificationSuccess(res);
        }).catch(e => {
            createDevicesNotificationFailure(e);
        });
    } else {
        let deviceClone = device.clone();
        deviceClone.Address = Number(address);
        deviceClone.Name = name;
        deviceClone.ParentID = gateway.DevID;
        deviceClone.Interfaces[3] = Rs0Protocol;
        deviceClone.Interfaces[4] = Rs1Protocol;
        deviceClone.Alias = alias;
        deviceClone.Protocol = FrameTypes.SLIP;
        deviceClone.VerHard = "0.1";
        deviceClone.VerSoft = "0.1";
        deviceClone.Settings.BridgePeriphery = BridgePeriphery;
        deviceClone.setDtaModTime();
        dispatch(updateDeviceDynamoDB(deviceClone, deviceClone.FarmID, props.user.ClientID, props.user.LocalUserID, null, () => {
            props.history.push(`/${props.farm}/settings/devices/manage`)
        }));
    }
}

export function validate(values, props) {
    const {t} = props;
    const errors = {};

    if (!values.name) {
        errors.name = t("required");
    }
    if (!(FORMAT.DEVICE_MANY_ADDR_BR.test(`${values.address}`) && (!values.edit || FORMAT.NUMBER_DEC_OR_HEX.test(`${values.address}`)))) {
        errors.address = t("errors.addressError", {
            amount1: '1 (0x1)',
            amount2: `15 (0x${(15).toString(16).toUpperCase()})`,
            amount3: '14'
        });
    }
    if (!values.gateway) {
        errors.gateway = t("required");
    }

    return errors;
}