import React from "react";
import {Field, FormSection, reduxForm} from "redux-form";
import {connect} from "react-redux";
import Card from "../../../../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import ReduxCheckbox from "../../../../../components/basics/checkbox/ReduxCheckbox";
import FormForNotifications from "../FormForNotifications";
import {submit} from "./ClimateNotificationsSubmit";
import {withTranslation} from "react-i18next";

export class ClimateNotifications extends React.Component {

    constructor(props) {
        super(props);

        const {notifications} = this.props;

        if (notifications && notifications.SetData.Climate) {
            this.props.initialize(notifications.SetData.Climate);
        } else {
            this.props.initialize({
                Info: {},
                Warning: {
                    NoCommunication: true
                },
                Error: {
                    EEPROM: true,
                    ResetTime: true,
                    TemperatureTooLow: true,
                    TemperatureTooHigh: true,
                    AbsoluteAlarm: true,
                    Humidity: true,
                    CO2: true,
                    NH3: true,
                    H2S: true,
                    Slurry: true
                }
            })
        }
    }

    render() {
        const {handleSubmit, t} = this.props;
        return (
            <FormForNotifications handleSubmit={handleSubmit}>
                {/*<Card>*/}
                {/*    <FormSection name="Warning">*/}
                {/*        <h4>*/}
                {/*            Ostrzeżenia*/}
                {/*            <i className="fas fa-exclamation-triangle"/>*/}
                {/*        </h4>*/}
                {/*        <Row>*/}
                {/*            <Col lg={4}>Urządzenie nie odpowiedziało przez 30 minut</Col>*/}
                {/*            <Col lg={8} className="notification-settings-right">*/}
                {/*                <Field*/}
                {/*                    name="NoCommunication"*/}
                {/*                    component={ReduxCheckbox}*/}
                {/*                    label=""*/}
                {/*                    id="NoCommunication"*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </FormSection>*/}
                {/*</Card>*/}
                <Card>
                    <FormSection name="Error">
                        <h4>
                            {t("newSettings.notifications.climate.errors")}
                            <i className="fas fa-exclamation-circle"/>
                        </h4>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.climate.eeprom")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <Field
                                    name="EEPROM"
                                    component={ReduxCheckbox}
                                    label=""
                                    id="EEPROM"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.climate.reset")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <Field
                                    name="ResetTime"
                                    component={ReduxCheckbox}
                                    label=""
                                    id="ResetTime"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.climate.temperature")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <Field
                                    name="TemperatureTooLow"
                                    component={ReduxCheckbox}
                                    label={t("newSettings.notifications.climate.tooLow")}
                                    id="TemperatureTooLow"
                                />
                                <Field
                                    name="TemperatureTooHigh"
                                    component={ReduxCheckbox}
                                    label={t("newSettings.notifications.climate.tooHigh")}
                                    id="TemperatureTooHigh"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.climate.absoluteAlarm")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <Field
                                    name="AbsoluteAlarm"
                                    component={ReduxCheckbox}
                                    label=""
                                    id="AbsoluteAlarm"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.climate.sensors")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <Field
                                    name="Humidity"
                                    component={ReduxCheckbox}
                                    label={t("newSettings.notifications.climate.humidity")}
                                    id="Humidity"
                                />
                                <Field
                                    name="CO2"
                                    component={ReduxCheckbox}
                                    label={t("newSettings.notifications.climate.co2")}
                                    id="CO2"
                                />
                                <Field
                                    name="NH3"
                                    component={ReduxCheckbox}
                                    label="NH3"
                                    id="NH3"
                                />
                                <Field
                                    name="H2S"
                                    component={ReduxCheckbox}
                                    label={t("newSettings.notifications.climate.h2s")}
                                    id="H2S"
                                />
                                <Field
                                    name="Slurry"
                                    component={ReduxCheckbox}
                                    label={t("newSettings.notifications.climate.slurry")}
                                    id="Slurry"
                                />
                            </Col>
                        </Row>
                    </FormSection>
                </Card>
            </FormForNotifications>
        );
    }

}

ClimateNotifications = reduxForm({
    form: "climate-notifications",
    onSubmit: submit
})(ClimateNotifications);

ClimateNotifications = connect(state => ({
    notifications: state.settings.notifications,
    user: state.user.user,
}))(ClimateNotifications);

export default withTranslation()(ClimateNotifications);