import React from "react";
import FormForSettings from "../../../FormForSettings";
import {Field, formValueSelector, reduxForm} from "redux-form";
import Card from "../../../../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import {connect} from "react-redux";
import ReduxInput from "../../../../../components/basics/input/ReduxInput";
import "./_.general-notifications.scss";
import {submit} from "./GeneralNotificationsSubmit"
import {withTranslation} from "react-i18next";

export class GeneralNotifications extends React.Component {

    constructor(props) {
        super(props);
        const {notifications} = props;

        if (notifications && notifications.SetData.General) {
            this.props.initialize(notifications.SetData.General);
        } else {
            this.props.initialize({
                Weekdays: [true, true, true, true, true, true, true],
                Time: ["00:00", "23:59"]
            })
        }
    }

    changeWeekDays(weekday) {
        let weekdays = this.props.weekdays.slice(0);
        weekdays[weekday] = !weekdays[weekday];
        this.props.change("Weekdays", weekdays);
    }

    weekdaysContainsWeekDay(weekday) {
        return this.props.weekdays && this.props.weekdays[weekday];
    }

    render() {
        const {handleSubmit, t} = this.props;
        return (
            <FormForSettings handleSubmit={handleSubmit}>
                <Card className="general-notifications">
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <span>{t("newSettings.notifications.general.weekdays")}</span>
                            <div className="d-flex justify-content-center">
                                <ButtonGroup>
                                    <Button type="button" onClick={() => this.changeWeekDays(0)}
                                            buttonColor={this.weekdaysContainsWeekDay(0) ? "success" : ""}>{t("newSettings.notifications.general.monday")}</Button>
                                    <Button type="button" onClick={() => this.changeWeekDays(1)}
                                            buttonColor={this.weekdaysContainsWeekDay(1) ? "success" : ""}>{t("newSettings.notifications.general.tuesday")}</Button>
                                    <Button type="button" onClick={() => this.changeWeekDays(2)}
                                            buttonColor={this.weekdaysContainsWeekDay(2) ? "success" : ""}>{t("newSettings.notifications.general.wednesday")}</Button>
                                    <Button type="button" onClick={() => this.changeWeekDays(3)}
                                            buttonColor={this.weekdaysContainsWeekDay(3) ? "success" : ""}>{t("newSettings.notifications.general.thursday")}</Button>
                                    <Button type="button" onClick={() => this.changeWeekDays(4)}
                                            buttonColor={this.weekdaysContainsWeekDay(4) ? "success" : ""}>{t("newSettings.notifications.general.friday")}</Button>
                                    <Button type="button" onClick={() => this.changeWeekDays(5)}
                                            buttonColor={this.weekdaysContainsWeekDay(5) ? "success" : ""}>{t("newSettings.notifications.general.saturday")}</Button>
                                    <Button type="button" onClick={() => this.changeWeekDays(6)}
                                            buttonColor={this.weekdaysContainsWeekDay(6) ? "success" : ""}>{t("newSettings.notifications.general.sunday")}</Button>
                                </ButtonGroup>
                            </div>
                            <span>{t("newSettings.notifications.general.time")}</span>
                            <div className="general-notifications-time">
                                <div className="general-notifications-time-picker">
                                    <Field
                                        name="Time[0]"
                                        component={ReduxInput}
                                        type="time"
                                    />
                                </div>
                                <div className="general-notifications-time-separator">-</div>
                                <div className="general-notifications-time-picker">
                                    <Field
                                        name="Time[1]"
                                        component={ReduxInput}
                                        type="time"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </FormForSettings>
        );
    }

}

GeneralNotifications = reduxForm({
    form: "general-notifications",
    onSubmit: submit
})(GeneralNotifications);

const selector = formValueSelector("general-notifications");

GeneralNotifications = connect(state => ({
    notifications: state.settings.notifications,
    user: state.user.user,
    weekdays: selector(state, "Weekdays")
}))(GeneralNotifications);

export default withTranslation()(GeneralNotifications);