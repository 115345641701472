import React from "react";
import Card from "../../../../components/basics/card/Card";
import ItemPicker from "../../../../components/basics/item-picker/ItemPicker";
import bridge from "../../../../resources/images/devices/bridge.svg"
import dispenserWST from "../../../../resources/images/devices/dispenserWST.svg"
import dispenserNRF from "../../../../resources/images/devices/dispenserNRF.svg"
import weightWST from "../../../../resources/images/devices/weightWST.svg"
import climateWST from "../../../../resources/images/devices/climateWST.svg"
import cage from "../../../../resources/images/devices/cage.svg"
import thermoeye from "../../../../resources/images/devices/thermoeye.svg"
import electricityMeter from "../../../../resources/images/devices/electricityMeter.svg"
import chainFeeding from "../../../../resources/images/devices/chainFeeding.svg"
import rfid from "../../../../resources/images/devices/rfid.svg";
import waterFlowMeter from "../../../../resources/images/devices/waterFlowMeter.svg";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";
import {withTranslation} from "react-i18next";

export class AddDevicesView extends React.Component {

    constructor(props) {
        super(props);

        const {user: {LicPackages}, farm} = props;
        let farmLicense = null;
        if (LicPackages) {
            farmLicense = LicPackages.find(item => item.FarmID === farm);
        }
        this.state = {
            picker: undefined,
            farmLicense
        }
    }

    onPickerChange = value => {
        this.props.history.push(`/${this.props.farm}/settings/devices/add/${value}`);
    };

    disableAddDevice(license, field) {
        return license ? license[field] === 0 : false;
    }

    render() {
        const {picker, farmLicense} = this.state;
        const {t} = this.props;
        const options = [
            {
                value: "bridge",
                label: t("newSettings.devices.addDevicesView.bridge"),
                svgPath: bridge
            },
            {
                value: "dispenserWST",
                label: t("newSettings.devices.addDevicesView.dispenserWST"),
                svgPath: dispenserWST,
                disabled: this.disableAddDevice(farmLicense, "DevDispensersWSTLeft")
            },
            {
                value: "dispenserNRF",
                label: t("newSettings.devices.addDevicesView.dispenserNRF"),
                svgPath: dispenserNRF,
                disabled: this.disableAddDevice(farmLicense, "DevDispensersNRFLeft")
            },
            {
                value: "weightWST",
                label: t("newSettings.devices.addDevicesView.weightWST"),
                svgPath: weightWST,
                disabled: this.disableAddDevice(farmLicense, "DevSilosesLeft")
            },
            {
                value: "climateWST",
                label: t("newSettings.devices.addDevicesView.climateWST"),
                svgPath: climateWST,
                disabled: this.disableAddDevice(farmLicense, "DevClimatesLeft")
            },
            {
                value: "climateSK3",
                label: `${t("newSettings.devices.addDevicesView.climateWST")} SK3`,
                svgPath: climateWST,
                disabled: this.disableAddDevice(farmLicense, "DevClimatesLeft")
            },
            {
                value: "cage",
                label: t("newSettings.devices.addDevicesView.cage"),
                svgPath: cage,
                disabled: this.disableAddDevice(farmLicense, "DevCagesLeft")
            },
            {
                value: "thermoEye",
                label: 'ThermoEye',
                svgPath: thermoeye
            },
            {
                value: "antennaRFID",
                label: t("newSettings.devices.addDevicesView.antennaRFID"),
                svgPath: rfid
            },
            {
                value: "waterMeter",
                label: t("newSettings.devices.addDevicesView.waterFlowMeter"),
                svgPath: waterFlowMeter
            },
            {
                value: "electricityMeter",
                label: t("newSettings.devices.addDevicesView.electricityMeter"),
                svgPath: electricityMeter
            },
            {
                value: "chainFeeding",
                label: t("chainFeeding"),
                svgPath: chainFeeding
            },
            {
                value: "pigletScale",
                label: t("newSettings.devices.addDevicesView.smallCage"),
                svgPath: electricityMeter
            }
        ];
        return (
            <Card>
                <ItemPicker value={picker} options={options} onChange={this.onPickerChange} showLabels itemsInRow={4}/>
            </Card>
        );
    }

}

export let _AddDevicesView = connect(state => ({
    farm: state.location.farm,
    devices: state.farmDevices.devices,
    user: state.user.user
}))(AddDevicesView);
AddDevicesView = withRouter(_AddDevicesView);
AddDevicesView = withRoles({roles: [Roles._DEVICE_CONFIG], showComponent: true})(AddDevicesView);
export default withTranslation()(AddDevicesView);
