import React from "react";
import Card from "../../../../../../components/basics/card/Card";
import FormForDevices from "../FormForDevices";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {submit, validate} from "./WaterFlowMeterSubmit";
import {withRouter} from "react-router-dom";
import withRoles from "../../../../../../components/withRoles";
import {Roles} from "../../../../../../constans/roles";
import ReduxLabeledInput from "../../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import _ from "lodash";
import {Col, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";

const FormName = "waterMeter";

class WaterFlowMeter extends React.Component {

    render() {
        const {handleSubmit, address, device, t, bridge, edit} = this.props;
        return (
            <Card>
                <FormForDevices handleSubmit={handleSubmit} showInterfacePicker={false}
                                device={device} address={address} showHelp={!edit}
                                showAdditionalButton={device} bridge={bridge}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <Field
                                name={"number"}
                                id={"number"}
                                component={ReduxLabeledInput}
                                label={t("newSettings.devices.form.number")}
                                type={"text"}
                                normalize={value => value ? value.slice(0, 3).toUpperCase() : null}
                            />
                            <Field
                                name={"serial"}
                                id={"serial"}
                                component={ReduxLabeledInput}
                                label={t("newSettings.devices.form.serial")}
                                type={"number"}
                                format={value => _.isFinite(value) ? `${value}` : value || ""}
                                parse={value => _.isFinite(parseInt(value)) ? parseInt(value) : value}
                            />
                        </Col>
                    </Row>

                </FormForDevices>
            </Card>
        );
    }

}

WaterFlowMeter = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(WaterFlowMeter);

WaterFlowMeter = withRoles(props => {
    return {roles: [Roles._DEVICE_CONFIG], showComponent: true}
})(WaterFlowMeter);

const selector = formValueSelector(FormName);
WaterFlowMeter = connect(state => ({
    address: selector(state, "address"),
    device: selector(state, "device"),
    bridge: selector(state, "bridge"),
    edit: selector(state, "edit"),
    user: state.user.user,
    farm: state.location.farm
}))(WaterFlowMeter);

WaterFlowMeter = withTranslation()(WaterFlowMeter);
export default withRouter(WaterFlowMeter);
