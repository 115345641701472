import {
    addressNRF,
    addressWST,
    stopAddressNRF,
    stopAddressWST
} from "../../../../IOT/device-functions/GatewayFunctions";
import {FrameTypes} from "../../../../constans/devices";

export function submit(values, dispatch, props) {
    const {startedAddressing} = props;
    const {gateway, protocol, address, amount, interf, mode} = values;
    if (startedAddressing) {
        switch (protocol) {
            default:
            case FrameTypes.WST:
                stopAddressWST(gateway, () => dispatch({
                    type: "CHANGE_ADDRESSING",
                    payload: {
                        state: false
                    }
                }));
                break;
            case FrameTypes.NRF:
                stopAddressNRF(gateway, () => dispatch({
                    type: "CHANGE_ADDRESSING",
                    payload: {
                        state: false
                    }
                }))
                break;
        }
    } else {
        let data = {
            startAddress: address,
            howMany: amount,
            mode
        };
        switch (protocol) {
            default:
            case FrameTypes.WST:
                data.interface = interf;
                addressWST(gateway, data, () => dispatch({
                    type: "CHANGE_ADDRESSING",
                    payload: {
                        state: true,
                        toAddress: amount,
                        protocol: FrameTypes.WST
                    }
                }));
                break;
            case FrameTypes.NRF:
                data.nrf = interf;
                addressNRF(gateway, data, () => dispatch({
                    type: "CHANGE_ADDRESSING",
                    payload: {
                        state: true,
                        toAddress: amount,
                        protocol: FrameTypes.NRF
                    }
                }));
                break;
        }
    }
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (!values.gateway) {
        errors.gateway = t("required");
    }
    if (!values.address) {
        errors.address = t("required");
    }
    if (!values.protocol) {
        errors.protocol = t("required");
    }

    return errors;
}