import {FORMAT} from "../../../../../../constans/formatTypes";
import {createDeviceAddressList} from "../../../../../../utils/DevicesUtils";
import {DevType, FrameTypes} from "../../../../../../constans/devices";
import devicesDB from "../../../../../../database/devicesDB";
import {updateDeviceDynamoDB} from "../../../../../../actions/devicesActions";
import i18next from "i18next";

export function submit(values, dispatch, props) {
    const {address, interf, name, device, alias} = values;
    let deviceClone = device.clone();
    deviceClone.Address = Number(address);
    deviceClone.Name = name;
    deviceClone.Interface = +interf;
    deviceClone.Alias = alias;
    deviceClone.Protocol = FrameTypes.NRF;
    deviceClone.VerHard = "0.1";
    deviceClone.VerSoft = "0.1";
    dispatch(updateDeviceDynamoDB(deviceClone, deviceClone.FarmID, props.user.ClientID, props.user.LocalUserID, null, () => {
        props.history.push(`/${props.farm}/settings/devices/manage`)
    }));
}

export function validate(values, props) {
    const errors = {};

    if (!values.name) {
        errors.name = i18next.t("required");
    }
    if (!(FORMAT.DEVICE_MANY_ADDR_DI_NRF_MULTI.test(`${values.address}`) && (!values.edit || FORMAT.NUMBER_DEC_OR_HEX.test(`${values.address}`)))) {
        errors.address = i18next.t("addressError", {amount1: `0x1300`, amount2: `0xFF00`, amount3: '0x1500'});
    }
    if (!values.bridge) {
        errors.bridge = i18next.t("required");
    } else {
        if (values.address && !errors.address && values.interf) {
            let addressList = createDeviceAddressList(values.address, DevType.DISPENSER_NRF_MULTI);
            let takenAddresses = [];
            for (let address of addressList) {
                let devicesWithAddress = devicesDB.getDevicesWithAddress(props.farm, address);
                devicesWithAddress = devicesWithAddress.filter(item => item.ParentID === values.bridge.DevID && item.Interface === values.interf);
                if (values.edit) {
                    devicesWithAddress = devicesWithAddress.filter(item => values.device.DevID !== item.DevID);
                }
                if (devicesWithAddress.length > 0) takenAddresses.push(address);
            }
            console.log(takenAddresses);
            if (takenAddresses.length > 0) {
                errors.address = i18next.t("errors.takenAddress", {type: takenAddresses.join(", ")});
            }
        }
    }
    if (!values.interf) {
        errors.interf = i18next.t("required");
    }

    return errors;
}