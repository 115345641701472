import React, {Component} from "react";
import {connect} from "react-redux";
import DropIn from "braintree-web-drop-in-react";
import {
    initializeDropin,
    setPaymentMethodRequestable,
    storeDropinInstance
} from "../../../../../../../actions/braintreeActions";
import "../../_subscriptionsView.scss"
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {compose} from "redux";
import ButtonGroup from "../../../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../../../components/basics/button/Button";

function mapStateToProps(state) {
    return {
        clientToken: state.braintree.clientToken,
        fetched: state.braintree.fetched,
        dropinLoaded: state.braintree.dropinLoaded,
        paymentMethodRequestable: state.braintree.paymentMethodRequestable
    }
}

export class ThirdStepView extends Component {
    instance;

    constructor(props) {
        super(props);
        props.dispatch(initializeDropin())
    }

    render() {
        const {t, onCancel, onNextStep, dropinLoaded, paymentMethodRequestable, onBackStep} = this.props;
        return (
            <div className={"step"}>
                <div className={"dropin"}><h4>{i18next.t("subscriptions.steps.paymentSelection")}</h4>
                    <DropIn
                        options={{
                            authorization: this.props.clientToken,
                            locale: t("subscriptions.locale"),
                            card: {
                                cardholderName: true
                            }
                        }}
                        onInstance={instance => {
                            this.instance = instance;
                            this.props.dispatch(storeDropinInstance(this.instance))
                        }}
                        onPaymentMethodRequestable={() => {
                            this.instance.requestPaymentMethod();
                            this.props.dispatch(setPaymentMethodRequestable(true));
                        }
                        }
                        onNoPaymentMethodRequestable={() => {
                            this.props.dispatch(setPaymentMethodRequestable(false));
                        }
                        }
                        onPaymentOptionSelected={() => {
                            this.props.dispatch(setPaymentMethodRequestable(true));
                        }
                        }
                    />
                </div>
                <ButtonGroup fixed renderInPortal={false}>
                    <Button buttonColor={"error"} buttonStyle={"round"} icon={<i className="fas fa-times"/>}
                            onClick={onCancel}/>
                    <Button buttonColor={"success"} buttonStyle={"round"} onClick={onBackStep}
                            icon={<i className="fas fa-arrow-left"/>}/>
                    <Button buttonColor={"success"} buttonStyle={"round"} onClick={onNextStep}
                            icon={<i className="fas fa-arrow-right"/>}
                            disabled={!dropinLoaded || !paymentMethodRequestable}
                    />
                </ButtonGroup>
            </div>
        )
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(ThirdStepView);
