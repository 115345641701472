import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as MedicineModalName} from "../../../../../components/modals-new/medicine-modal/MedicineModal";

export class EditMedicineDictionaryComponent extends React.Component {

    onEditClick = () => {
        this.props.show(MedicineModalName, {medicine: this.props.value, edit: true});
    };

    render() {
        return (
            <div onClick={this.onEditClick}>
                <i className="fas fa-edit pointer"/>
            </div>
        );
    }

}

EditMedicineDictionaryComponent = connect(
    state => ({}),
    dispatch => bindActionCreators({show}, dispatch)
)(EditMedicineDictionaryComponent);

export default EditMedicineDictionaryComponent;