import React from "react";
import Loading from "../../../../components/loading/LoadingComponent";
import {Col, Row} from "react-bootstrap";
import Feeding from "./settings/Feeding";
import {connect} from "react-redux";
import NewIOT from "../../../../IOT/NewIOT";
import ForageEfficiency from "./settings/ForageEfficiency";

export class ManageDispenserWSTConfiguration extends React.Component {

    state = {
        selectedDispensers: this.props.location.state ? this.props.location.state.selectedDispensers : [],
        loading: true
    };

    constructor(props) {
        super(props);

        if (this.state.selectedDispensers.length === 0) {
            this.props.history.push(`/${this.props.farm}/settings/feeding/f1/manage`)
        }
        NewIOT.startSendingDeviceState(this.state.selectedDispensers, this.setLoading, this.setLoading);
    }

    setLoading = () => {
        this.setState({
            loading: false
        })
    };

    render() {
        const {selectedDispensers, loading} = this.state;
        if (selectedDispensers.length === 0) return null;
        return (
            <div className="position-relative overflow-hidden">
                <Loading isLoading={loading}/>
                <Row>
                    <Col xl={4}>
                        <Feeding dispensers={selectedDispensers}/>
                    </Col>
                    <Col xl={4}>
                        <ForageEfficiency dispensers={selectedDispensers}/>
                    </Col>
                </Row>
            </div>
        );
    }

}

ManageDispenserWSTConfiguration = connect(state => ({
    farm: state.location.farm
}))(ManageDispenserWSTConfiguration);

export default ManageDispenserWSTConfiguration;
