import React from "react";
import {Col, Row} from "react-bootstrap";
import {connect} from 'react-redux'
import Card from "../../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";

class GeneralView extends React.Component {

    render() {
        const {attributes: {address, email, login, name, phone}, t} = this.props;
        return (
            <Card className={"settings-profile-general"}>
                <Row className="justify-content-center">
                    <Col md={12}>
                        <Row>
                            <Col md className="align-right">
                                {t("newSettings.profile.generalView.login")}
                            </Col>
                            <Col md>
                                {login}
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col md className="align-right">
                                {t("nameAndSurrname")}:
                            </Col>
                            <Col md>
                                {name}
                            </Col>
                        </Row>
                        <Row>
                            <Col md className="align-right">
                                {t("newSettings.profile.generalView.address")}
                            </Col>
                            <Col md>
                                {address}
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col md className="align-right">
                                {t("email2")}:
                            </Col>
                            <Col md>
                                {email}
                            </Col>
                        </Row>

                        <Row>
                            <Col md className="align-right">
                                {t("phoneNumber")}:
                            </Col>
                            <Col md>
                                {phone}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        );
    }

}

GeneralView = connect(state => ({
    farm: state.location.farm,
    attributes: state.user.attributes,
}))(GeneralView);
export default withTranslation()(GeneralView);