import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Col} from "react-bootstrap";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        selectedPlans: state.braintree.selectedPlans
    }
}

class DevicesPlan extends React.Component {

    checkIfClassIsActive = (className) => {
        const {selectedPlans} = this.props;
        return selectedPlans.find((c) => c === className);
    };

    render() {
        const {onSelect, t} = this.props;
        const devicesAddons = [
            {
                title: t("siloses"),
                class: "siloses"
            },
            {
                title: t("climates"),
                class: "climates"
            },
            {
                title: t("cages"),
                class: "cages"
            },
            {
                title: t("dispensers"),
                class: "dispensers"
            },
            {
                title: t("dispensers5G"),
                class: "dispensers5G"
            },
            {
                title: t("meters"),
                class: "meters"
            },
            {
                title: t("weights"),
                class: "smallCages"
            },
            {
                title: t("chains"),
                class: "chains"
            },
            {
                title: t("alarms"),
                class: "alarms"
            }
        ];
        return (
            <Col md={4} className={"plan-container"}>
                <div className="plan-title">
                    <h4>{t("devices")}</h4>
                </div>
                {
                    devicesAddons.map((item, index) => {
                        return (
                            <div key={index} className="addon-type">
                                <div className="addon-title">
                                    <h5>{item.title}</h5>
                                </div>
                                <div className={`plans ${index + 1 === devicesAddons.length ? "last" : ""}`}>
                                    {
                                        item.class !== "dispensers5G" && <div
                                            className={this.checkIfClassIsActive(`${item.class}-basic`) ? `${item.class}-basic active` : `${item.class}-basic`}
                                            onClick={onSelect}>
                                            <div className="plan-type">
                                                <h6>{t("subscriptions.plans.basic")}</h6>
                                            </div>
                                            <div className="plan-details">
                                                <p>- Lorem Ipsum</p>
                                            </div>
                                        </div>
                                    }
                                    <div
                                        className={this.checkIfClassIsActive(`${item.class}-extended`) ? `${item.class}-extended active` : `${item.class}-extended`}
                                        onClick={onSelect}>
                                        <div className="plan-type">
                                            <h6>{t("subscriptions.plans.extended")}</h6>
                                        </div>
                                        <div className="plan-details">
                                            <p>- Lorem Ipsum</p>
                                            <p>- Lorem Ipsum extra</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Col>
        );
    }

}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DevicesPlan);
