import React from "react";
import {connect} from 'react-redux'
import NotFound from "../../../../../components/NotFound";
import SettingsDB from "../../../../../database/settingsDB"
import {SettingTypes} from "../../../../../constans/settingTypes";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import EditEfficiency from "./EditEfficiency";
import withRoles from "../../../../../components/withRoles";
import {Roles} from "../../../../../constans/roles";
import AddChamberModal from "../../../../../components/modals-new/add-chamber-modal/AddChamberModal";

@connect(state => ({
    farm: state.location.farm
}))
class EditForage extends React.Component {
    render() {
        const {match: {params: {index}}} = this.props;
        let idx = _.isFinite(+index) ? +index : null;
        const forage = SettingsDB.getSettingByIndexAndType(idx, SettingTypes.FEEDING_FORAGE);
        if ((_.isNil(idx) || idx < 0 || idx > 8)) {
            return (
                <NotFound/>
            )
        }
        return (
            <React.Fragment>
                <EditEfficiency defaultValue={forage} index={idx}/>
                <AddChamberModal/>
            </React.Fragment>
        );
    }
}

export const _EditForage = EditForage;

EditForage = withRouter(EditForage);
export default withRoles({roles: [Roles._DEVICE_CONFIG], showComponent: true})(EditForage);

