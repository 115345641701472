import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import PropTypes from "prop-types";
import _ from "lodash";
import {connect} from "react-redux";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import {convertWeightUnitTo} from "../../../../../utils/UnitUtils";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import LabeledSelect from "../../../../../components/basics/select/labeled-select/LabeledSelect";
import {setWorkType} from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import {DevType, LoadedDataTypes} from "../../../../../constans/devices";
import {getFeedingUnit} from "../../../../../utils/SettingsUtils";
import {checkIfUserIsService} from "../../../../../utils/NewRolesUtils";
import {DispenserNRFCommandTypes} from "../../../../../constans/mqttMessages";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {removeTrash} from "../../../../../utils/IOTUtils";
import {NRFWorkType} from "../../../../../constans/feedingTypes";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class WorkType extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getWorkType(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState(this.getWorkType(nextProps))
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    getWorkType = (props = this.props) => {
        const {dispensers, shadows} = props;
        let device = dispensers[0];
        try {
            let shadow = shadows.get(device.DevID);
            return {
                maxOneDose: shadow.workType.maxOneDose || 50,
                initDose: shadow.workType.initDose || 50,
                timeOrImpulse: shadow.workType.timeOrImpulse || "T",
                workType: shadow.workType.workType || "H",
                optButt: shadow.workType.optButt || 0,
                loadedDataFrom: LoadedDataTypes.SHADOW,
                // SetTime: shadow.metadata.workType,
                device
            }
        } catch (e) {
            return {
                maxOneDose: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_WORK_TYPE}.maxOneDose`, 50),
                initDose: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_WORK_TYPE}.initDose`, 50),
                timeOrImpulse: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_WORK_TYPE}.timeOrImpulse`, "T"),
                workType: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_WORK_TYPE}.workType`, "H"),
                SetTime: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_WORK_TYPE}.SetTime`, 0),
                optButt: _.get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_WORK_TYPE}.optButt`, 0),
                loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[DispenserNRFCommandTypes.SET_WORK_TYPE] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
                device
            };
        }
    };


    onClick = () => {
        const {dispensers} = this.props;
        const devices = groupDevicesByGatewayID(dispensers);
        if (devices) {
            const data = removeTrash(this.state);
            for (let devList of devices.values()) {
                devList[DevType.BROADCAST].length && setWorkType(devList[DevType.BROADCAST], data);
                devList[DevType.DISPENSER_NRF_MULTI].length && setWorkType(devList[DevType.DISPENSER_NRF_MULTI], data);
                devList[DevType.DISPENSER_NRF].length && setWorkType(devList[DevType.DISPENSER_NRF], data);
            }
        }
    }
    ;

    setValue = (key, value) => {
        this.setState({
            [key]: value,
            changed: true
        })
    };

    render() {
        const {t} = this.props;
        const {maxOneDose, initDose, timeOrImpulse, workType, loadedDataFrom, SetTime, device, optButt} = this.state;
        let unit = getFeedingUnit();
        const workTypeOptions = [
            {
                name: t("schedule"),
                value: NRFWorkType.SCHEDULE
            },
            {
                name: t("newSettings.dispenserNRF.workType.toy"),
                value: NRFWorkType.TRIGGER
            },
            {
                name: t("rfid"),
                value: NRFWorkType.RFID
            }
        ];

        const timeOrImpulseOptions = [
            {
                name: t("time"),
                value: "T"
            },
            {
                name: t("newSettings.dispenserNRF.workType.impulse"),
                value: "I"
            }
        ];

        const buttonWorkTypeOptions = [
            {
                name: t("newSettings.dispenserNRF.workType.punishment"),
                value: 0
            },
            {
                name: t("newSettings.dispenserNRF.workType.extraFeeding"),
                value: 1
            }
        ];

        if (optButt === 0 && workType !== 'Z') {
            this.setState({
                optButt: 1
            })
        }

        return (
            <DeviceSettingsCard title={t("worktype")} onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[DispenserNRFCommandTypes.SET_WORK_TYPE].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledSlider value={maxOneDose} label={t("newSettings.dispenserNRF.workType.maxOneDose")} min={50}
                               max={2550} step={50}
                               valueFormatter={(value) => convertWeightUnitTo(value, {
                                   showUnit: true,
                                   unit: unit,
                                   fixed: unit ? 2 : 0
                               })}
                               onChange={(value) => this.setValue("maxOneDose", value)}
                />
                <LabeledSlider value={initDose} label={t("newSettings.dispenserNRF.workType.initDose")} min={50}
                               max={2550} step={50}
                               valueFormatter={(value) => convertWeightUnitTo(value, {
                                   showUnit: true,
                                   unit: unit,
                                   fixed: unit ? 2 : 0
                               })}
                               onChange={(value) => this.setValue("initDose", value)}
                />
                <LabeledSelect label={t("newSettings.dispenserNRF.workType.workType")} options={workTypeOptions}
                               value={workType}
                               onChange={(value) => this.setValue("workType", value)} clearButton={false}/>
                <LabeledSelect label={t("newSettings.dispenserNRF.workType.buttonWorkType")}
                               options={buttonWorkTypeOptions.filter(o => o.value || (workType === 'Z'))}
                               value={optButt}
                               onChange={(value) => this.setValue("optButt", value)} clearButton={false}/>
                {
                    checkIfUserIsService() &&
                    <LabeledSelect label={t("newSettings.dispenserNRF.workType.timeOrImpulse")}
                                   options={timeOrImpulseOptions} value={timeOrImpulse}
                                   onChange={(value) => this.setValue("timeOrImpulse", value)} clearButton={false}/>
                }
            </DeviceSettingsCard>
        );
    }

}

WorkType.propTypes = {
    dispensers: PropTypes.array.isRequired
};

WorkType = connect(state => ({
    shadows: state.shadows.shadows,
}))(WorkType);

export default withTranslation()(WorkType);