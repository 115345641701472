import React from "react";
import {DevType} from "../../../../../constans/devices";

export default class EditComponent extends React.Component {

    onClick = () => {
        this.props.onClick(this.props.value);
    };

    render() {
        if ([DevType.GATEWAY, DevType.DI_TIME, DevType.BRIDGE_CONF, DevType.BRIDGE_WORK, DevType.BROADCAST].includes(this.props.value.DevType)) return null;
        return (
            <i className="fas fa-pencil-alt" onClick={this.onClick}/>
        );
    }
}