import React, {Component} from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import FeedingPlanItem from "./FeedingPlanItem";
import List from "../../../../../components/basics/list/List";
import Card from "../../../../../components/basics/card/Card";
import withRoles from "../../../../../components/withRoles";
import {LicPackageKeys, LicPackageLevel, Roles} from "../../../../../constans/roles";
import ListItemFullWidthRow from "../../../../../components/list-components/ListItemFullWidthRow";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {getFeedingSchedules} from "../../../../../selectors/feedingSelector";
import {getLicenseByName} from "../../../../../selectors/roleSelector";
import {LicenseItemCounts} from "../../../../../constans/licenseCounts";

@connect((state) => {
    return {
        feedingPlans: getFeedingSchedules(state),
        license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER})

    };
})
class FeedingPlansView extends Component {

    render() {
        const {feedingPlans, license} = this.props;
        let plansWithEmptySpaces = [];
        for (let i = 0; i < LicenseItemCounts.FEEDING_SCHEDULE_EXTENDED; i++) {
            plansWithEmptySpaces[i] = {plan: feedingPlans.find(cur => cur.SetData.Index === i), index: i};
        }
        return (
            <Card type={"container"} className={"feeding-view-container"}>
                <List striped useFlexLayout={false} showNumber>
                    <ListItemFullWidthRow text={i18next.t("newSettings.feeding.forages.feedingForageItem.compatibility", {type: "F1, F3, F5"})}
                                          key={"comp_WST"}/>
                    {
                        plansWithEmptySpaces.slice(0, LicenseItemCounts.FEEDING_SCHEDULE_BASIC).map((plan, key) => (
                            <FeedingPlanItem key={`plan_${plan.index}`}{...plan}/>
                        ))
                    }
                    {
                        (license === LicPackageLevel.EXTENDED) &&
                        <>
                            <ListItemFullWidthRow text={i18next.t("newSettings.feeding.forages.feedingForageItem.compatibility", {type: "F3, F5"})}
                                                  key={"comp_NRF"}/>
                            {
                                plansWithEmptySpaces.slice(LicenseItemCounts.FEEDING_SCHEDULE_BASIC, LicenseItemCounts.FEEDING_SCHEDULE_EXTENDED).map((plan, key) => (
                                    <FeedingPlanItem key={`plan_${plan.index}`}{...plan}/>
                                ))
                            }
                        </>
                    }
                </List>
            </Card>
        )
    }
}

FeedingPlansView = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(FeedingPlansView);

FeedingPlansView = withTranslation()(FeedingPlansView);

export const __FeedingPlansView = FeedingPlansView;

export default withRoles({roles: [Roles._DEVICE_CONFIG], showComponent: true})(FeedingPlansView);
