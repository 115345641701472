import {DevType, FrameTypes} from "../../../../../../constans/devices";
import _ from "lodash";
import {convertWeightToBaseUnit} from "../../../../../../utils/UnitUtils";
import {getScaleUnit} from "../../../../../../utils/SettingsUtils";
import {updateDeviceDynamoDB} from "../../../../../../actions/devicesActions";
import {FORMAT} from "../../../../../../constans/formatTypes";
import {createDeviceAddressList} from "../../../../../../utils/DevicesUtils";
import devicesDB from "../../../../../../database/devicesDB";
import i18next from "i18next";
import {
    createDevices,
    createDevicesNotificationFailure,
    createDevicesNotificationSuccess
} from "../../../../../../api/devices/createDevices";

export function submit(values, dispatch, props) {
    const {address, interf, name, bridge, edit, device, siloses, alias} = values;
    let unit = getScaleUnit();
    if (!edit) {
        let Siloses = [];
        for (let i = 0; i < 8; i++) {
            Siloses[i] = {
                Adr: i,
                Active: !!_.get(siloses, `[${i}].Active`, false),
                Capacity: convertWeightToBaseUnit(_.get(siloses, +`[${i}].Capacity`, 0), {fromUnit: unit}),
                Alias: _.get(siloses, `[${i}].Alias`, null),
                Forage: _.get(siloses, `[${i}].Forage`, null),
                Number: _.get(siloses, `[${i}].Number`, null),
            };
        }
        const devices = createDeviceAddressList(address, DevType.SCALE).map(adr => ({
            DevType: DevType.SCALE,
            Name: name,
            Address: adr,
            ParentID: bridge.DevID,
            Interface: +interf,
            Alias: alias,
            AdditionalData: {
                Siloses
            }
        }))
        return createDevices(devices).then(res => {
            props.reset();
            createDevicesNotificationSuccess(res);
        }).catch(e => {
            createDevicesNotificationFailure(e);
        });
    } else {
        let deviceClone = device.clone();
        deviceClone.Address = Number(address);
        deviceClone.Name = name;
        deviceClone.ParentID = bridge.DevID;
        deviceClone.Interface = +interf;
        deviceClone.Alias = alias;
        deviceClone.Siloses = _.cloneDeep(siloses || new Array(8).fill(1)).map((silo, i) => {
            let tmp = {
                Adr: i,
                Active: !!silo.Active,
                Alias: silo.Alias,
                Forage: silo.Forage,
                Number: silo.Number
            };
            if (silo.Capacity && _.isFinite(+silo.Capacity)) {
                tmp.Capacity = convertWeightToBaseUnit(+silo.Capacity, {fromUnit: unit});
            }
            return tmp;
        });
        deviceClone.Protocol = FrameTypes.WST;
        deviceClone.VerHard = "0.1";
        deviceClone.VerSoft = "0.1";
        dispatch(updateDeviceDynamoDB(deviceClone, deviceClone.FarmID, props.user.ClientID, props.user.LocalUserID, null, () => {
            props.history.push(`/${props.farm}/settings/devices/manage`)
        }));
    }
}

export function validate(values, props) {
    const errors = {};

    if (!values.name) {
        errors.name = props.t("required");
    }
    if (!(FORMAT.DEVICE_MANY_ADDR_SC.test(`${values.address}`) && (!values.edit || FORMAT.NUMBER_DEC_OR_HEX.test(`${values.address}`)))) {
        errors.address = i18next.t("errors.addressError", {
            amount1: `${1} (0x1)`,
            amount2: `127 (0x${(15).toString(16).toUpperCase()})`,
            amount3: '14'
        });
    } else {
        const {user: {LicPackages = []}, farm} = props;
        let licPackage = LicPackages.find(item => item.FarmID === farm);
        if (licPackage) {
            let addressList = createDeviceAddressList(values.address, DevType.SCALE);
            if (values.siloses) {
                let activeSiloses = values.siloses.reduce((a, b) => a + +b.Active, 0);
                let maxNumberOfDevices = Math.floor(licPackage.DevSilosesLeft / activeSiloses);
                if (addressList.length > maxNumberOfDevices) errors.address = i18next.t("errors.maxDevices", {amount: maxNumberOfDevices});
            }
        }
    }
    if (!values.bridge) {
        errors.bridge = props.t("required");
    } else {
        if (values.address && !errors.address && values.interf) {
            let addressList = createDeviceAddressList(values.address, DevType.SCALE);
            let takenAddresses = [];
            for (let address of addressList) {
                let devicesWithAddress = devicesDB.getDevicesWithAddress(props.farm, address);
                devicesWithAddress = devicesWithAddress.filter(item => item.ParentID === values.bridge.DevID && item.Interface === values.interf);
                if (values.edit) {
                    devicesWithAddress = devicesWithAddress.filter(item => values.device.DevID !== item.DevID);
                }
                if (devicesWithAddress.length > 0) takenAddresses.push(address);
            }
            if (takenAddresses.length > 0) {
                errors.address = i18next.t("errors.takenAddress", {type: takenAddresses.join(", ")});
            }
        }
    }
    if (!values.interf) {
        errors.interf = props.t("required");
    }

    if (values.siloses) {
        errors.siloses = values.siloses.map(silo => {
            let error = {};

            if (silo.Number && !silo.Number.match(new RegExp("^[0-9]{1,2}[A-Z]?$", "g"))) {
                error.Number = i18next.t("errors.shouldBeInFormat", {type: "1, 11, 11A"});
            }
            return error;
        })
    }

    return errors;
}