import React, {Component} from 'react'
import {DeviceSettingsCard} from '../../../../../components/device-settings-card/DeviceSettingsCard';
import {Field, formValueSelector, isDirty, reduxForm} from 'redux-form';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {DispenserNRFCommandTypes} from '../../../../../constans/mqttMessages';
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {get, isNil} from "lodash";
import {setWater} from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import {convertVolumeUnitTo} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import ReduxLabeledSelect from "../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";


class NRFWater extends Component {

    constructor(props) {
        super(props);
        props.initialize(this.getState());
        this.state = {
            dirty: !!props.dirty
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if ((!prevState.dirty) && prevState.dirty !== nextProps.dirty) {
            return {
                dirty: !!nextProps.dirty
            }
        }
        return null;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dispensers, shadows, waterDoseType, waterEfficiency, change, initialize} = this.props;
        const {dirty} = this.state;
        let changeEfficiency = true;
        if (dirty !== prevState.dirty) {
            const device = dispensers[0];
            const shadow = device ? shadows.get(device.DevID) : null;
            if (shadow && !dirty) {
                initialize(this.getState(this.props))
                changeEfficiency = false;
            }
        }
        if (changeEfficiency && (prevProps.waterDoseType !== waterDoseType)) {
            change("waterEfficiency", Math.min(2500, waterEfficiency - waterEfficiency % 10));
        }

    }

    getState = (props = this.props) => {
        const result = {};
        const {dispensers, shadows} = props;
        const device = dispensers[0];
        const key = `Settings.Configuration[${DispenserNRFCommandTypes.SET_WATER_EFFICIENCY}]`;
        const shadow = device ? shadows.get(device.DevID) : null;
        // result.waterEnabled = get(device, "Settings.Water.Enabled", false);
        result.waterDoseType = get(shadow, "efficiency.waterDoseType", get(device, `${key}.waterDoseType`, 0))
        result.waterEfficiency = get(shadow, "efficiency.waterEfficiency", get(device, `${key}.waterEfficiency`, 0))
        result.waterInitDose = get(shadow, "efficiency.waterInitDose", get(device, `${key}.waterInitDose`, 0))
        result.waterDose = get(shadow, "efficiency.waterDose", get(device, `${key}.waterDose`, 0))
        result.SetTime = get(shadow, "efficiency.workType", get(device, `Settings.Configuration[${DispenserNRFCommandTypes.SET_WATER_EFFICIENCY}].SetTime`, 0))
        result.loadedDataFrom =
            isNil(get(shadow, "efficiency.waterInitDose")) ?
                !!get(device, `Settings.Configuration[${DispenserNRFCommandTypes.SET_WATER_EFFICIENCY}]`) ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA : LoadedDataTypes.SHADOW;

        return result;
    };

    volumeFormatter = (value) => {
        return convertVolumeUnitTo(value, {showUnit: true, unit: UnitTypes.SMALL, fixed: 2});
    };

    impulseFormatter = (value) => {
        const {t} = this.props;
        return t("deviceRows.feeding.dispenserRow.efficiencyImpulsesLiter", {efficiency: value})
    };

    timeFormatter = (value) => {
        const {t} = this.props;
        return t("deviceRows.feeding.dispenserRow.efficiencyTime", {
            efficiency: convertVolumeUnitTo(value * 2, {
                showUnit: true,
                unit: UnitTypes.SMALL,
                fixed: 2
            })
        })
    };

    render() {
        const {t, submit: handleSubmit, loadedDataFrom, SetTime, dispensers, waterDoseType} = this.props;
        const device = dispensers[0];
        return (
            <DeviceSettingsCard title={t("water")} onButtonClick={handleSubmit}>

                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[DispenserNRFCommandTypes.SET_WATER_EFFICIENCY].isSet &&
                            <InfoBox
                                boxColor="warning">{t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <Field
                    id={"waterDoseType"}
                    name={"waterDoseType"}
                    label={t("newSettings.dispenserNRF.water.waterDoseType")}
                    component={ReduxLabeledSelect}
                    clearButton={false}
                    parse={value => [0, 1].includes(value) ? value : 0}
                    options={[
                        {
                            name: t("newSettings.dispenserNRF.water.time"),
                            value: 0
                        },
                        {
                            name: t("newSettings.dispenserNRF.water.impulse"),
                            value: 1
                        }
                    ]}
                />
                <Field
                    id={"waterEfficiency"}
                    name={"waterEfficiency"}
                    label={t("newSettings.dispenserNRF.water.waterEfficiency")}
                    component={ReduxLabeledSlider}
                    min={0}
                    max={waterDoseType ? 5000 : 2500}
                    step={waterDoseType ? 1 : 10}
                    valueFormatter={waterDoseType ? this.impulseFormatter : this.timeFormatter}
                />
                <Field
                    id={"waterDose"}
                    name={"waterDose"}
                    label={t("newSettings.dispenserNRF.water.waterDose")}
                    component={ReduxLabeledSlider}
                    min={0}
                    max={5000}
                    step={10}
                    valueFormatter={this.volumeFormatter}
                />
                <Field
                    id={"waterInitDose"}
                    name={"waterInitDose"}
                    label={t("newSettings.dispenserNRF.water.waterInitDose")}
                    component={ReduxLabeledSlider}
                    min={0}
                    max={5000}
                    step={10}
                    valueFormatter={this.volumeFormatter}
                />
            </DeviceSettingsCard>
        )
    }
}

const submit = (values, dispatch, props) => {
    const {dispensers} = props;
    const {waterDoseType, waterEfficiency, waterDose, waterInitDose} = values;
    const dataEnabled = {};
    const dataDisabled = {
        waterDoseType: 0,
        waterEfficiency: 0,
        waterInitDose: 0,
        waterDose: 0
    }
    dataEnabled.waterDoseType = waterDoseType;
    dataEnabled.waterEfficiency = waterEfficiency;
    dataEnabled.waterInitDose = waterInitDose;
    dataEnabled.waterDose = waterDose;
    dispensers.forEach(device => {
        setWater(device, device.hasWater() ? dataEnabled : dataDisabled);
    })
};

NRFWater = reduxForm({
    form: "nrfWaterEfficiency",
    onSubmit: submit,
    initialValues: {}
})(NRFWater);

const selector = formValueSelector("nrfWaterEfficiency");
NRFWater = connect(state => ({
    SetTime: selector(state, "SetTime"),
    loadedDataFrom: selector(state, "loadedDataFrom"),
    waterDoseType: selector(state, "waterDoseType"),
    waterEfficiency: selector(state, "waterEfficiency"),
    dirty: isDirty("nrfWaterEfficiency")(state),
    shadows: state.shadows.shadows
}))(NRFWater);
export default withTranslation()(NRFWater)
