import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {hide, show} from "redux-modal";
import ButtonGroup from "../../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../../components/basics/button/Button";
import Card from "../../../../../../components/basics/card/Card";
import Stepper from "../../../../../../components/basics/stepper/Stepper";
import Step from "../../../../../../components/basics/stepper/Step";
import ThirdStepView from "./pages/ThirdStepView";
import SecondStepView from "./pages/SecondStepView";
import SummaryStepView from "./pages/SummaryStepView";
import FifthStepView from "./pages/FifthStepView";
import {gatewayCustomerFind} from "../../../../../../actions/braintreeActions";
import withRoles from "../../../../../../components/withRoles";
import {logout} from "../../../../../../utils/UserUtils";
import DelayedActionModal from "../../../../../../components/modals-new/delayed-action/DelayedActionModal";
import {withTranslation} from "react-i18next";
import * as RoleTypes from "validators-schema/Api/constants/roleTypes";
import moment from "moment";
import {registerIOT} from "../../../../../../actions/mqttActions";
import {
    createSubscription,
    createSubscriptionNotificationFailure,
    createSubscriptionNotificationSuccess
} from "../../../../../../api/subscriptions/CreateSubscription";

function mapStateToProps(state) {
    return {
        userData: state.user.attributes,
        user: state.user.user,
        dropinInstance: state.braintree.dropinInstance,
        dropinLoaded: state.braintree.dropinLoaded,
        customer: state.braintree.customer,
        paymentMethodRequestable: state.braintree.paymentMethodRequestable,
        farm: state.location.farm,
        subList: state.braintree.subscriptionList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

class AdminSubProcess extends React.Component {
    constructor(props) {
        super(props);
        const {dispatch, userData: {email, name, phone, sub}, customer} = props;
        if (!customer) {
            dispatch(gatewayCustomerFind(email, name, phone, sub));
        }
        this.state = {
            activeStep: 1
        };
    }

    componentDidMount() {
        this.props.dispatch({
            type: "HIDE_SIDE_NAVIGATION"
        });
    }

    onCancel = () => {
        logout();
    };

    onSubscribe = async () => {
        const {subList, dispatch, dropinInstance, userData, user: {ClientID}} = this.props;
        const hasAnyAdminSubscription = subList.filter((s) => s.id.slice(0, -20) === ClientID) > 0;
        const trialInfo = {
            trialDurationUnit: "day",
            trialDuration: moment().add(1, "month").endOf("month").diff(moment(), "days") + 1,
            trialPeriod: !hasAnyAdminSubscription
        };
        //todo - wyliczyc trzeba balance dla subskrypcji administratora bo moze byc tak ze chce kupic nowa, a tamte ma canceled lub expired ale jakies pieniadze sa do pobrania
        await createSubscription("adminPlanYearlyPLN", dropinInstance, "peeleny",
            undefined, userData.sub, trialInfo, {}, "0.00")
            .then((res) => {
                createSubscriptionNotificationSuccess(res);
                this.onNextStep();
                dispatch(registerIOT());
            })
            .catch((e) => createSubscriptionNotificationFailure(e));
    };

    onBackStep = () => {
        this.setState((state) => ({
            activeStep: state.activeStep - 1
        }));
    };

    onNextStep = () => {
        const {activeStep} = this.state;
        if (activeStep) {

        }
        this.setState((state) => ({
            activeStep: state.activeStep + 1
        }));
    };

    render() {
        const {activeStep} = this.state;
        const {t} = this.props;
        return (
            <div>
                <Card>
                    {
                        <Stepper activeStep={activeStep}>
                            <>
                                <Step label={this.props.t("subscriptions.steps.agreementConditions")}/>
                                <Step label={this.props.t("subscriptions.steps.payment")}/>
                                <Step label={this.props.t("subscriptions.steps.confirmation")}/>
                                <Step label={this.props.t("summary")}/>
                            </>
                        </Stepper>
                    }
                    {
                        activeStep === 1 && <SecondStepView
                            onCancel={this.onCancel}
                            onNextStep={this.onNextStep}
                            isFirstStep={true}
                        />
                    }
                    {
                        activeStep === 2 && <ThirdStepView
                            onCancel={this.onCancel}
                            onBackStep={this.onBackStep}
                            onNextStep={this.onNextStep}
                        />
                    }
                    {
                        activeStep === 3 &&
                        <SummaryStepView
                            onCancel={this.onCancel}
                            onBackStep={this.onBackStep}
                            onSubscribe={this.onSubscribe}
                        />
                    }
                    {
                        activeStep === 4 &&
                        <>
                            <FifthStepView logout={true}/>
                            <ButtonGroup fixed>
                                <Button buttonColor={"error"} buttonStyle={"round"}
                                        icon={<i className="fas fa-times"/>} onClick={this.onCancel}/>
                            </ButtonGroup>
                        </>
                    }
                </Card>
                <DelayedActionModal handleHide={hide} counter={10} action={logout} title={t("modals.logoutModal.title")}
                                    text={"modals.logoutModal.text"}
                                    confirmButtonText={t("logout")}/>
            </div>
        )
    }
}

export default compose(
    withTranslation(),
    withRoles({roles: [RoleTypes.BILLING]}),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(AdminSubProcess);
