import React from "react";
import FormForNotifications from "../FormForNotifications";
import {Field, FormSection, reduxForm} from "redux-form";
import {connect} from "react-redux";
import Card from "../../../../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import ReduxCheckbox from "../../../../../components/basics/checkbox/ReduxCheckbox";
import {submit} from "./EventsNotificationsSubmit";
import {withTranslation} from "react-i18next";

export class EventsNotifications extends React.Component{

    constructor(props) {
        super(props);

        const {notifications} = this.props;

        if (notifications && notifications.SetData.Events) {
            this.props.initialize(notifications.SetData.Events);
        } else {
            this.props.initialize({
                Info: {
                    Treatment: true
                },
                Warning: {
                    InseminationTooFast: true,
                    USGNoInsemination: true,
                    NoPregnancyNoInsemination: true,
                    ParturitionNoInsemination: true,
                    ParturitionTooFast: true,
                    ParturitionNoPregnancy: true,
                    ParturitionNegativeUSG: true,
                    SeparationWrongAmount: true,
                    FallPigletWrongAmount: true,
                    MommyWrongAmount: true
                },
                Error: {}
            })
        }
    }


    render() {
        const {handleSubmit, t} = this.props;
        return (
            <FormForNotifications handleSubmit={handleSubmit}>
                <Card>
                    <FormSection name="Info">
                        <h4>
                            {t("newSettings.notifications.events.info")}
                            <i className="fas fa-info-circle"/>
                        </h4>
                        <Row>
                            <Col lg={4}>{t("scheduleOfTreatment")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="Treatment"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="Treatment"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </FormSection>
                </Card>
                <Card>
                    <FormSection name="Warning">
                        <h4>
                            {t("newSettings.notifications.events.warning")}
                            <i className="fas fa-exclamation-triangle"/>
                        </h4>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.events.inseminationTooEarly")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="InseminationTooFast"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="InseminationTooFast"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.events.usgNoInsemination")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="USGNoInsemination"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="USGNoInsemination"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.events.noPregnancyNoInsemination")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="NoPregnancyNoInsemination"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="NoPregnancyNoInsemination"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.events.parturitionNoInsemination")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="ParturitionNoInsemination"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="ParturitionNoInsemination"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.events.parturitionTooEarly")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="ParturitionTooFast"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="ParturitionTooFast"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.events.parturitionNoPregnancy")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="ParturitionNoPregnancy"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="ParturitionNoPregnancy"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.events.parturitionNegativeUSG")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="ParturitionNegativeUSG"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="ParturitionNegativeUSG"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.events.separationWrongAmount")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="SeparationWrongAmount"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="SeparationWrongAmount"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.events.fallPigletsWrongAmount")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="FallPigletWrongAmount"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="FallPigletWrongAmount"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>{t("newSettings.notifications.events.mommyWrongAmount")}</Col>
                            <Col lg={8} className="notification-settings-right">
                                <div className="notification-settings-input">
                                    <Field
                                        name="MommyWrongAmount"
                                        component={ReduxCheckbox}
                                        label=""
                                        id="MommyWrongAmount"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </FormSection>
                </Card>
            </FormForNotifications>
        );
    }

}

EventsNotifications = reduxForm({
    form: "events-notifications",
    onSubmit: submit
})(EventsNotifications);

EventsNotifications = connect(state => ({
    notifications: state.settings.notifications,
    user: state.user.user,
}))(EventsNotifications);

export default withTranslation()(EventsNotifications);