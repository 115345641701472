import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, FieldArray, formValueSelector, initialize, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import Card from "../../../../../components/basics/card/Card";
import _ from "lodash";
import PropTypes from "prop-types";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import Button from "../../../../../components/basics/button/Button";
import ReduxLabeledSelect from "../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {getStagesFromDays, isValidCurveForDevType} from "../../../../../utils/FeedingUtils";
import {convertWeightUnitTo} from "../../../../../utils/UnitUtils";
import {getFeedingUnit} from "../../../../../utils/SettingsUtils";
import {SettingTypes} from "../../../../../constans/settingTypes";
import {CurveDayShowingType, CurveType} from "../../../../../constans/feedingTypes";
import FormForSettings from "../../../FormForSettings";
import settingsDB from "../../../../../database/settingsDB";
import {hasDeviceType} from "../../../../../utils/DevicesUtils";
import {DevType} from "../../../../../constans/devices";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {LicPackageKeys, LicPackageLevel} from "../../../../../constans/roles";
import {LicenseItemCounts} from "../../../../../constans/licenseCounts";
import {getLicenseByName} from "../../../../../selectors/roleSelector";

const FormName = 'settings-feeding-curves-edit-days';

export class EditDays extends React.Component {

    constructor(props) {
        super(props);
        this.renderStages = this.renderStages.bind(this);
        this.createNextStage = this.createNextStage.bind(this);
        const {dispatch, defaultValue, keptValues, data: {repetitionType} = {}} = this.props;
        const repetitionDay = repetitionType === CurveDayShowingType.BOTH ? keptValues.repetitionDay || _.get(defaultValue, "SetData.InseminationJumpTo", 0) : undefined;
        let stages = keptValues.stages || getStagesFromDays(_.get(defaultValue, "SetData.Days", []), _.get(defaultValue, "SetData.Stages", []));
        dispatch(initialize(FormName, {
            stages: stages.length ? stages : [this.createNextStage(this.props)],
            repetitionDay
        }))
    }

    createNextStage(props) {
        const {stages} = props;
        let stageNo = _.get(stages, "length", 0);
        return {
            name: i18next.t("newSettings.feeding.curves.editDays.stageNameSB", {type: stageNo + 1 || 1}),
            startingAmount: stageNo ? stages[stageNo - 1].endingAmount : 1000,
            endingAmount: stageNo ? stages[stageNo - 1].endingAmount : 1000,
            days: 1,
            forage: stageNo ? stages[stageNo - 1].forage : 0,
            plan: stageNo ? stages[stageNo - 1].plan : 0,
        }
    }

    getName(func, fallbackVal) {
        try {
            return `${fallbackVal} - ${func()}`;
        } catch (e) {
            return fallbackVal;
        }
    }

    renderStages = ({fields, meta: {touched, error, submitFailed}}) => {
        const {index, t, farm, data: {curveType, repetitionType}, hasWST, defaultValue, license} = this.props;
        const compatibilityMode = hasWST && isValidCurveForDevType({
            devType: DevType.DISPENSER,
            showingType: repetitionType,
            index: index,
            curve: defaultValue
        });
        let forageOptions = [];
        let plansOptions = [];
        const maxForage = Math.min(compatibilityMode ? 2 : LicenseItemCounts.FEEDING_FORAGE_EXTENDED,  (license === LicPackageLevel.EXTENDED ? LicenseItemCounts.FEEDING_FORAGE_EXTENDED : LicenseItemCounts.FEEDING_FORAGE_BASIC));
        const maxPlan = Math.min(compatibilityMode ? 5 : LicenseItemCounts.FEEDING_SCHEDULE_EXTENDED, (license === LicPackageLevel.EXTENDED ? LicenseItemCounts.FEEDING_SCHEDULE_EXTENDED : LicenseItemCounts.FEEDING_SCHEDULE_BASIC));
        for (let i = 0; i < maxForage; i++) {
            forageOptions.push({
                name: this.getName(() => settingsDB.getSettingByIndexAndType(i, SettingTypes.FEEDING_FORAGE, farm).SetData.Name, i + 1),
                value: i
            })
        }
        for (let i = 0; i < maxPlan; i++) {
            plansOptions.push({
                name: this.getName(() => settingsDB.getSettingByIndexAndType(i, SettingTypes.FEEDING_SCHEDULE, farm).SetData.Name, i + 1),
                value: i
            })
        }
        let daysUsed = 0;
        fields.forEach((stage, i) => {
            daysUsed += fields.get(i).days || 0
        });
        let daysLeft = 150 - daysUsed;
        daysLeft = daysLeft < 0 ? 0 : daysLeft;
        let allDays = 0;
        fields.forEach((o, i) => {
            let field = fields.get(i);
            allDays += _.get(field, "days", 0) || 0;
        });
        let day = 1;
        let unit = getFeedingUnit();
        return (
            <div>
                {
                    fields.map((member, i) => {
                        let field = fields.get(i);
                        let name = field.name;
                        let dayFrom = day;
                        let dayTo = day + field.days - 1;
                        if (repetitionType === CurveDayShowingType.BEFORE) {
                            dayFrom = (dayFrom - 1) - allDays;
                            dayTo = (dayTo - 1) - allDays;
                        } else if (curveType === CurveDayShowingType.AFTER) {
                            dayFrom--;
                            dayTo--;
                        } else if (curveType === CurveDayShowingType.BOTH) {

                        }
                        day += field.days;
                        const show = field.days !== 1;
                        return (
                            <Card>
                                <h5 className="flex-lg-row justify-content-between align-content-center">
                                    {i18next.t("newSettings.feeding.curves.editDays.dayNameSB", {
                                        type1: name,
                                        type2: dayTo !== dayFrom ? i18next.t("newSettings.feeding.curves.editDays.dayFromTo", {
                                            type1: dayFrom,
                                            type2: dayTo
                                        }) : dayFrom
                                    })}
                                    <Button
                                        onClick={() => (fields.length > 1) && fields.remove(i)} type={"button"}
                                        buttonStyle={"round"} className={"text"}
                                        icon={<i className={"fas fa-trash"}/>}/>
                                </h5>
                                <Row>
                                    <Col md={4}>
                                        <Field
                                            name={`${member}.name`}
                                            id={`${member}.name`}
                                            label={t("newSettings.feeding.curves.editDays.stageName")}
                                            type={"text"}
                                            component={ReduxLabeledInput}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field
                                            name={`${member}.plan`}
                                            id={`${member}.plan`}
                                            options={plansOptions}
                                            label={t("newSettings.feeding.curves.editDays.plan")}
                                            component={ReduxLabeledSelect}
                                            clearButton={false}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field
                                            name={`${member}.forage`}
                                            id={`${member}.forage`}
                                            options={forageOptions}
                                            label={t("forage")}
                                            component={ReduxLabeledSelect}
                                            clearButton={false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Field
                                            name={`${member}.days`}
                                            id={`${member}.days`}
                                            min={1}
                                            max={daysLeft + field.days}
                                            valueFormatter={(value) => value ? i18next.t("newSettings.feeding.curves.editDays.days", {count: value}) : "-"}
                                            label={t("newSettings.feeding.curves.editDays.stageDays")}
                                            component={ReduxLabeledSlider}
                                        />
                                    </Col>
                                    <Col md={(show ? 4 : 8)}>
                                        <Field
                                            name={`${member}.startingAmount`}
                                            id={`${member}.startingAmount`}
                                            min={100}
                                            max={12000}
                                            step={100}
                                            valueFormatter={(value) => convertWeightUnitTo(value, {
                                                showUnit: true,
                                                unit: unit,
                                                fixed: unit ? 2 : 0
                                            })}
                                            label={(show ? t("newSettings.feeding.curves.editDays.startingDose") : t("dosage"))}
                                            component={ReduxLabeledSlider}
                                        />
                                    </Col>
                                    {show &&
                                    <Col md={4}>
                                        <Field
                                            name={`${member}.endingAmount`}
                                            id={`${member}.endingAmount`}
                                            min={100}
                                            max={12000}
                                            step={100}
                                            valueFormatter={(value) => convertWeightUnitTo(value, {
                                                showUnit: true,
                                                unit: unit,
                                                fixed: unit ? 2 : 0
                                            })}
                                            label={t("newSettings.feeding.curves.editDays.finishingDose")}
                                            component={ReduxLabeledSlider}
                                        />
                                    </Col>
                                    }
                                </Row>
                            </Card>
                        )
                    })
                }
                {
                    !!daysLeft &&
                    <Card>
                        <h5 className="flex-lg-row justify-content-between align-content-center"
                            onClick={() => fields.push(this.createNextStage(this.props))}>
                            {t("newSettings.feeding.curves.editDays.addNextStage")} <i className={"fas fa-plus"}/>
                        </h5>
                    </Card>
                }
            </div>
        )
    };

    render() {
        const {handleSubmit, onPrevPage, stages, t, data: {repetitionType, curveType} = {}} = this.props;
        const repetitionLabel = curveType === CurveType.PARTURITION ? t("parturitionDate") : (curveType === CurveType.MATING ? t("inseminationDay") : "?");
        return (
            <div className={`${FormName} overflow-hidden`}>
                <FormForSettings {...this.props} optionalButtons={<Button buttonStyle={"round"} type={"button"}
                                                                          onClick={() => onPrevPage(0, {stages})}
                                                                          icon={<i className="fas fa-arrow-left"/>}
                                                                          buttonColor={"secondary"}/>}
                                 handleSubmit={handleSubmit} icon={<i className="fas fa-arrow-right"/>}
                >
                    <Row className="justify-content-center">
                        <Col md={12}>
                            {
                                (repetitionType === CurveDayShowingType.BOTH) &&
                                <div>
                                    <Card>
                                        <h5 className="flex-lg-row justify-content-between align-content-center">
                                            {repetitionLabel}
                                        </h5>
                                        <Row>
                                            <Col md={4}>
                                                <Field
                                                    name={`repetitionDay`}
                                                    id={`repetitionDay`}
                                                    min={1}
                                                    max={(stages || []).reduce((a, b) => a + b.days, 0)}
                                                    label={repetitionLabel}
                                                    component={ReduxLabeledSlider}
                                                />
                                            </Col>
                                        </Row>

                                    </Card>
                                </div>
                            }
                            <FieldArray name="stages" component={this.renderStages} rerenderOnEveryChange/>
                        </Col>
                    </Row>
                </FormForSettings>
            </div>
        );
    }
}


function submit(values, dispatch, props) {
    const {data, onNextPage, defaultValue} = props;
    const {stages, repetitionDay} = values;
    console.log(values, props);
    let tmp = _.cloneDeep(data);
    let stageArray = [];
    let currentDay = 1;
    let daysGrams = _.cloneDeep(stages).map(stage => {
        stageArray.push({
            name: stage.name,
            start: currentDay
        });
        let tmpArr = [];
        for (let i = 0; i < stage.days; i++) {
            let amount = stage.days === 1 ? stage.startingAmount : stage.startingAmount + ((stage.endingAmount - stage.startingAmount) * i) / (stage.days - 1);
            tmpArr.push({
                forage: stage.forage,
                plan: stage.plan,
                notify: !!_.get(defaultValue, `SetData.Days[${currentDay + i - 1}].Notify`),
                amount: amount + (amount % 100 > 49 ? 100 - (amount % 100) : -(amount % 100))
            })
        }
        currentDay += stage.days;
        return tmpArr
    });
    tmp = {...tmp, days: _.flatten(daysGrams), stages: stageArray, repetitionDay};
    onNextPage(2, tmp, {stages, repetitionDay});

}

function validate(values, props) {
    const errors = {};
    const {t} = props;
    const {stages} = values;
    let stageErrors = [];
    if (!stages) {
        errors.stages = t("required")
    } else {
        stageErrors = stages.map((stage) => {
            const stageError = {};
            if (!+stage.startingAmount || +stage.startingAmount < 50) {
                stageError.startingAmount = t("required")
            }
            if (!+stage.endingAmount || +stage.endingAmount < 50) {
                stageError.endingAmount = t("required")
            }
            if (!_.isFinite(+stage.forage)) {
                stageError.forage = t("required")
            }
            if (!_.isFinite(+stage.plan)) {
                stageError.plan = t("required")
            }
            if (!stage.name) {
                stageError.name = t("required")
            }
            return stageError;
        })
    }
    errors.stages = stageErrors;
    return errors
}

function warn(values, props) {
    const warnings = {};
    console.log(values, props, "DKDKD");
    const {index, hasWST} = props;
    const compatibilityMode = hasWST && index < 5;
    let stageWarnings = [];
    if (compatibilityMode && values.stages) {
        stageWarnings = values.stages.map(stage => {
            const stageWarning = {};
            if (_.isFinite(+stage.forage) && (+stage.forage > 1)) {
                stageWarning.forage = i18next.t("newSettings.feeding.curves.editDays.selectedForageNotValid", {type: "F1"});
            }
            if (_.isFinite(+stage.plan) && (+stage.plan > 4)) {
                stageWarning.plan = i18next.t("newSettings.feeding.curves.editDays.selectedPlanNotValid", {type: "F1"});
            }
            return stageWarning;
        })
    }
    warnings.stages = stageWarnings;

    return warnings;
}


EditDays.propTypes = {
    data: PropTypes.shape({
        curveName: PropTypes.string.isRequired,
        curveType: PropTypes.string.isRequired,
        daysNumber: PropTypes.number.isRequired,
        repetitionType: PropTypes.number.isRequired

    }),
    defaultValue: PropTypes.object
};

EditDays = reduxForm({
    form: FormName,
    onSubmit: submit,
    warn,
    validate
})(EditDays);
const selector = formValueSelector(FormName);
EditDays = connect(state => ({
    stages: selector(state, "stages"),
    farm: state.location.farm,
    hasWST: hasDeviceType(state.farmDevices.devices, DevType.DISPENSER),
    license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER})
}))(EditDays);
export default withTranslation()(EditDays);

