import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Col} from "react-bootstrap";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        selectedPlans: state.braintree.selectedPlans
    }
}

class AdministrationPlan extends React.Component {

    checkIfClassIsActive = (className) => {
        const {selectedPlans} = this.props;
        return selectedPlans.find((c) => c === className);
    };

    render() {
        const {onSelect, t} = this.props;
        return (
            <Col md={4} className={"plan-container"}>
                <div className="plan-title">
                    <h4>{t("administration")}</h4>
                </div>
                <div className="plans">
                    <div
                        className={this.checkIfClassIsActive("administration-basic") ? "administration-basic active" : "administration-basic"}
                        onClick={onSelect}>
                        <div className="plan-details">
                            <p>- Lorem Ipsum</p>
                            <p>- Lorem Ipsum</p>
                            <p>- Lorem Ipsum</p>
                            <p>- Lorem Ipsum</p>
                        </div>
                    </div>
                </div>
            </Col>
        );
    }

}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(AdministrationPlan);
