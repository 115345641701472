import React from "react";
import {Col, Row} from "react-bootstrap";
import DictionaryItem from "./DictionaryItem";
import DictionaryModal from "../../../../components/modals-new/dictionary-modal/DictionaryModal";
import EditDictionaryModal from "../../../../components/modals-new/edit-dictionary/EditDictionaryModal";
import {DICTIONARY_TYPE} from "../../../../constans/general";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";

export class _ManageDictionary extends React.Component {

    render() {
        return (
            <div className="overflow-hidden">
                <Row>
                    <Col lg={6}>
                        <DictionaryItem type={DICTIONARY_TYPE.clients}/>
                    </Col>
                    <Col lg={6}>
                        <DictionaryItem type={DICTIONARY_TYPE.fallReasons}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <DictionaryItem type={DICTIONARY_TYPE.feedingCurves}/>
                    </Col>
                    <Col lg={6}>
                        <DictionaryItem type={DICTIONARY_TYPE.forageType}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <DictionaryItem type={DICTIONARY_TYPE.graftingReason}/>
                    </Col>
                    <Col lg={6}>
                        <DictionaryItem type={DICTIONARY_TYPE.weighting}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <DictionaryItem type={DICTIONARY_TYPE.noPreganancy}/>
                    </Col>
                    <Col lg={6}>
                        <DictionaryItem type={DICTIONARY_TYPE.race}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <DictionaryItem type={DICTIONARY_TYPE.selectionReason}/>
                    </Col>
                    <Col lg={6}>
                        <DictionaryItem type={DICTIONARY_TYPE.suppliers}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <DictionaryItem type={DICTIONARY_TYPE.taskCategory}/>
                    </Col>
                </Row>
                <DictionaryModal/>
                <EditDictionaryModal/>
            </div>
        );
    }

}

export default withRoles({
    roles: [Roles._BREEDING],
    showComponent: true
})(_ManageDictionary);